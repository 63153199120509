import { FieldMapper } from 'src/app/shared/field-mappers/field-mapper.model';
import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';

export class AlignmentJobParametersV2 implements JobConfigurationWithSelection {
  pipeline = {
    name: 'alignment',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: AlignmentJobOptionsV2;
    output?: OutputOptions;
  };

  constructor(parameters: {
    selection: SelectionOptionsV1;
    options: AlignmentJobOptionsV2;
    output?: OutputOptions;
  }) {
    this.parameters = parameters;
  }
}

export interface AlignmentJobOptionsV2 extends ExtractionAndPreprocessingSequenceOptions {
  resultName: string;
  alignmentAlgorithm: AlignmentAlgorithms;
  referenceID?: string;
  treeAlgorithm?: string;
  sequenceMetadataOrder?: string[];
  combineDuplicateSequences?: boolean;
  fieldMappers?: FieldMapper[];
  representDuplicatesBy?: RepresentDuplicatesBy;
}

/** @deprecated in favour of PreprocessingSequenceOptionsV2 **/
export interface PreprocessingSequenceOptionsV1 {
  alignCds?: boolean;
  cdsName?: string;
  translateFirst?: boolean;
  translation?: TranslationOptions;
  region?: RegionExtractionOptions;
}

export interface PreprocessingSequenceOptionsV2 {
  translation?: TranslationOptions;
  regions?: Region[];
}

export interface ExtractionAndPreprocessingSequenceOptions
  extends PreprocessingSequenceOptionsV1,
    PreprocessingSequenceOptionsV2 {
  // Only set when run inside a Result document.
  extraction?: any;
  clusterOptions?: AlignmentClusterOptions;
}

export interface AlignmentClusterOptions {
  representClusterBy: string;
  thresholdByValue?: number;
  regions?: Region[];
}

export enum RepresentDuplicatesBy {
  HIGHEST_LIABILITY_SCORE = 'HIGHEST_LIABILITY_SCORE',
  FIRST_IN_LIST = 'FIRST_IN_LIST',
}

export enum AlignmentClusterTypes {
  MAJORITY = 'MAJORITY',
  THRESHOLD_COUNT = 'THRESHOLD_COUNT',
  THRESHOLD_PERCENTAGE = 'THRESHOLD_PERCENTAGE',
  HIGHEST_LIABILITY_SCORE = 'HIGHEST_LIABILITY_SCORE',
  ALL = 'ALL',
}

export enum AlignmentAlgorithms {
  MAFFT = 'MAFFT',
  MUSCLE = 'MUSCLE',
}

export enum TreeBuilderAlgorithms {
  RAXML = 'RAXML',
  GENEIOUS = 'GENEIOUS',
}

export const ChainQualifierName = 'Chain';
export const ChainNameQualifierName = 'Chain Name';
// These keys correspond to valid qualifier values for RegionExtractionOptions when qualifierName is 'Chain'.
export const AntibodyChainOptionChoices = [
  { label: 'No filter', key: null },
  { label: 'Heavy Chain', key: 'Heavy' },
  { label: 'Light Chain', key: 'Light' },
];

/** @deprecated in favour of Region **/
export interface RegionExtractionOptions {
  qualifierName: string;
  qualifierValue: string;
}

export interface Region {
  name: string;
  type?: string;
  qualifierFilters?: {
    name: string;
    value: string;
  }[];
}

export interface TranslationOptions {
  geneticCode: string;
  frame?: number;
  useAlternativeStartCodon?: string;
}

export interface AlignmentSequenceOptions {
  hasSCFV: boolean;
  isGenericSequenceChainDocument: boolean;
}
