import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { NucleusDataResponseV2 } from '../nucleus.model';
import { ApiKeyV2Model } from './models/api-key.v2.model';
import { APP_CONFIG, AppConfig } from '../../app/app.config';

@Injectable({
  providedIn: 'root',
})
export class ApiKeysHttpService {
  private readonly endpoint: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
  ) {
    this.endpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/api-keys`;
  }

  getApiKeys(orgID?: string): Observable<ApiKeyV2Model[]> {
    const callUrl: string = this.endpoint + (orgID ? '?organizationID=' + orgID : '');
    return this.http
      .get<NucleusDataResponseV2<ApiKeyV2Model[]>>(callUrl)
      .pipe(map((response) => response.data));
  }

  deleteApiKey(apiKeyID: string): Observable<void> {
    const callUrl: string = this.endpoint + '/' + apiKeyID;
    return this.http.delete<void>(callUrl);
  }

  createApiKey(name: string): Observable<ApiKeyV2Model> {
    return this.http
      .post<NucleusDataResponseV2<ApiKeyV2Model>>(this.endpoint, { name })
      .pipe(map((response) => response.data));
  }
}
