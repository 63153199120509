<div class="modal-header">
  <h4 class="modal-title d-flex align-items-center">
    Enable Multi-Factor Authentication (MFA)
    <a
      href="https://help.geneiousbiologics.com/hc/en-us/articles/25275064966420"
      target="_blank"
      rel="noopener noreferrer"
      class="ms-2 d-flex align-items-center text-decoration-none"
    >
      <mat-icon>info</mat-icon>
    </a>
  </h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="close('canceled')"
  ></button>
</div>

<div class="modal-body">
  <bx-stepper-steps
    [steps]="stepper.steps"
    [selectedIndex]="stepper.selectedIndex"
    color="secondary"
  ></bx-stepper-steps>

  <bx-stepper [linear]="true" class="stepper">
    <bx-step label="Enable">
      <div class="mt-3 mx-3 mx-lg-5">
        <p><b>Keep your account secure by enabling multi-factor authentication</b></p>
        <p>
          When enabled, you will need to enter a 6 digit verification code to log in via email and
          password. The verification code will not be required if you login via your
          Google/Microsoft login.
        </p>
        <ngb-alert [dismissible]="false" type="warning" [animation]="false">
          <b
            >Please note that enabling MFA will prevent you from logging into your Geneious
            Biologics Workspace with Geneious Prime</b
          >
        </ngb-alert>
      </div>
    </bx-step>

    <bx-step
      label="Verify"
      [stepControl]="stepControls[0]"
      [disabled]="(newMfaKey$ | async) === null"
    >
      <bx-verify-mfa class="p-2" [form]="form" [newMfa$]="newMfaKey$"></bx-verify-mfa>
    </bx-step>
  </bx-stepper>
  @if (error$ | async; as error) {
    <div class="text-danger ms-4" [innerHtml]="error"></div>
  }
</div>

<div class="modal-footer">
  <ng-container>
    <button class="btn btn-secondary" (click)="close('canceled')">Cancel</button>
  </ng-container>
  <ng-container>
    @if ((stepper.isLastStep | async) === false) {
      <button class="btn btn-primary" (click)="createMFA()">Continue</button>
    } @else {
      @if ((stepper.isLastStep | async) === true) {
        <button
          [disabled]="!form.valid"
          type="button"
          class="btn btn-primary"
          (click)="verifyMfaKey()"
        >
          Verify
        </button>
      }
    }
  </ng-container>
</div>
