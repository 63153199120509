import { CdkStepper } from '@angular/cdk/stepper';
import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { BXStep } from './index';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'bx-stepper',
  templateUrl: './stepper.component.html',
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class StepperComponent extends CdkStepper implements AfterViewInit, OnDestroy {
  @Output() stepsChanges = new EventEmitter<BXStep[]>(true);

  private readonly destroy$ = new Subject<void>();
  private readonly selectionAndStepsChanges$ = combineLatest([
    this.selectionChange.pipe(startWith({ selectedIndex: this.selectedIndex })),
    this.stepsChanges.pipe(startWith(null)), // Steps changes should trigger a new emission
  ]).pipe(
    map(([selectionChange]) => selectionChange),
    takeUntil(this.destroy$),
    shareReplay(1),
  );

  @Output() isLastStep = this.selectionAndStepsChanges$.pipe(
    map((change) => change.selectedIndex === this.steps.length - 1),
  );
  @Output() isFirstStep = this.selectionAndStepsChanges$.pipe(
    map((change) => change.selectedIndex === 0),
  );
  /** Emits true when the current step is complete, or false if the step has an invalid form. */
  @Output() canProceed = new EventEmitter<boolean>();
  /** Emits the last step that the user can jump to with the navigation buttons. */
  @Output() maxNavigableIndex = new EventEmitter<number>();

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.steps.changes
      .pipe(
        map((steps) => steps.toArray()),
        startWith(this.steps.toArray()),
        takeUntil(this.destroy$),
      )
      .subscribe((steps) => this.stepsChanges.emit(steps));

    // selectionAndStepsChanges$ can't be used as the source because of the async EventEmitter
    const selectedStepState$ = combineLatest([
      this.selectionChange.pipe(
        startWith({ selectedIndex: this.selectedIndex, selectedStep: this.selected }),
      ),
      this.steps.changes.pipe(startWith(this.steps)),
    ]).pipe(
      map(([selection]) => selection),
      switchMap(({ selectedIndex, selectedStep }) => {
        if (selectedStep == null) {
          // Step query hasn't resolved yet
          return of({ selectedIndex, canProceed: false });
        }
        // Optional steps don't have to be valid. Some steps don't have a form.
        if (selectedStep.optional || selectedStep.stepControl == null) {
          return of({ selectedIndex, canProceed: true });
        }
        return selectedStep.stepControl.statusChanges.pipe(
          startWith(selectedStep.stepControl.status),
          distinctUntilChanged(),
          map((status) => ({
            selectedIndex,
            canProceed: status === 'VALID',
          })),
        );
      }),
      takeUntil(this.destroy$),
      shareReplay(1),
    );
    selectedStepState$.subscribe(({ canProceed }) => this.canProceed.next(canProceed));

    let maxNavigableIndex$: Observable<number>;
    if (this.linear) {
      maxNavigableIndex$ = selectedStepState$.pipe(
        map(({ selectedIndex, canProceed }) => {
          if (!canProceed) {
            return selectedIndex;
          }
          for (let i = selectedIndex + 1; i < this.steps.length; i++) {
            const nextStep = this.steps.get(i);
            if (
              nextStep != null &&
              // Next step can be skipped if it's optional or if it's got a valid form that's been filled
              (nextStep.optional || (nextStep.stepControl?.touched && nextStep.stepControl?.valid))
            ) {
              continue;
            }
            return i;
          }
          return this.steps.length - 1;
        }),
      );
    } else {
      maxNavigableIndex$ = this.steps.changes.pipe(map((steps) => steps.length - 1));
    }
    maxNavigableIndex$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lastStep) => this.maxNavigableIndex.next(lastStep));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
