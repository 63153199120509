import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColDef, GridOptions } from '@ag-grid-community/core';
import { filter, map, startWith } from 'rxjs/operators';
import {
  ClientGridComponent,
  ClientGridSelection,
} from '../../../features/grid/client-grid/client-grid.component';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { UsersService } from '../../users/users.service';
import { MfaKeySummary, MfaKeySummaryKeyType } from '@geneious/nucleus-api-client';
import User from '../../user-editor/user.model';
import { CreateMfaDialogComponent } from '../create-mfa-dialog/create-mfa-dialog.component';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { AsyncPipe } from '@angular/common';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';

@Component({
  selector: 'bx-mfa-keys-table',
  templateUrl: './mfa-keys-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToolstripComponent, ToolstripItemComponent, ClientGridComponent, AsyncPipe],
})
export class MfaKeysTableComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';

  @Input() showRevokeButton = false;
  @Input() noMfaKeysMessage?: string;
  @Input() showCreateButton = false;
  @Input() user: User;
  @ViewChild(ClientGridComponent, { static: true }) clientGridComponent: ClientGridComponent;
  revokeEnabled$: Observable<boolean>;
  selectedApiKey$: Observable<MfaKeyRow>;

  columnDefs: ColDef[];
  gridOptions: GridOptions;
  selection$ = new ReplaySubject<ClientGridSelection<MfaKeyRow>>(1);

  private subscriptions = new Subscription();
  private confirmationDialogRef: NgbModalRef;

  private createMFAModalRef: NgbModalRef;

  constructor(
    private usersService: UsersService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.gridOptions = {
      rowSelection: 'single',
      noRowsOverlayComponentParams: {
        noRowsMessage: this.noMfaKeysMessage,
      },
    };
    this.initializeColumns();
    this.reloadData();

    this.revokeEnabled$ = this.selection$.pipe(
      map((selection) => selection.rows.length === 1),
      startWith(false),
    );

    this.selectedApiKey$ = this.selection$.pipe(
      filter((selection) => selection.rows.length === 1),
      map((selection) => selection.rows[0]),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.selection$.complete();
    if (this.createMFAModalRef) {
      this.createMFAModalRef.close();
    }
    if (this.confirmationDialogRef) {
      this.confirmationDialogRef.close();
    }
  }

  reloadData() {
    this.clientGridComponent.gridOptions?.api.showLoadingOverlay();
    this.subscriptions.add(
      this.getApiKeysRows().subscribe((rows) => {
        this.clientGridComponent.setRowData(rows);
        this.clientGridComponent.gridOptions?.api.hideOverlay();
        if (rows.length === 0) {
          this.clientGridComponent.gridOptions?.api.showNoRowsOverlay();
        }
      }),
    );
  }

  revokeApiKey() {
    const revokeSubscription = this.selectedApiKey$.subscribe((selectedApiKey) => {
      this.confirmationDialogRef = this.dialogService.showConfirmationDialog({
        title: `Delete selected ${selectedApiKey.keyType} key?`,
        content: 'This action is irreversible. The key will be deleted forever.',
        confirmationButtonText: 'Delete',
        confirmationButtonColor: 'danger',
      });

      this.confirmationDialogRef.result
        .then((confirmed) => {
          if (confirmed) {
            if (!selectedApiKey) {
              console.warn('No API Key to revoke. This should never happen.');
              return;
            }
            this.subscriptions.add(
              this.usersService.deleteMfaKey(selectedApiKey.id).subscribe(() => this.reloadData()),
            );
          }
        })
        // Avoid ZoneJS complaining about an un-handled Promise rejection.
        .catch(() => {})
        .finally(() => revokeSubscription.unsubscribe());
    });
  }

  private getApiKeysRows(): Observable<MfaKeyRow[]> {
    return this.usersService.listMfaKeys(this.user.id).pipe(
      map((response) => response.data.filter((key) => key.verified)),
      map((keys) => keys.map(this.apiKeyToTableRow)),
    );
  }

  private apiKeyToTableRow(apiKey: MfaKeySummary): MfaKeyRow {
    function toHumanReadableType(keyType: MfaKeySummaryKeyType) {
      if (keyType === MfaKeySummaryKeyType.SoftwareTotp) {
        return 'Software TOTP';
      } else {
        return 'Invalid Type';
      }
    }

    return {
      id: apiKey.id,
      keyType: toHumanReadableType(apiKey.keyType),
      createdAt: new Date(apiKey.createdAt),
      lastUsedAt: apiKey.lastUsedAt ? new Date(apiKey.lastUsedAt) : '',
      verified: apiKey.verified,
    };
  }

  newMFAKey() {
    this.createMFAModalRef = this.dialogService.showDialogV2({
      component: CreateMfaDialogComponent,
      injectableData: { folder: '' },
      options: CreateMfaDialogComponent.MODAL_OPTIONS,
    });
    this.createMFAModalRef.result
      .then(() => {
        this.reloadData();
      })
      // Avoid ZoneJS complaining about an un-handled Promise rejection.
      .catch(() => {});
  }

  private initializeColumns() {
    this.columnDefs = [
      {
        field: 'id',
        headerName: '',
        width: 34,
        resizable: false,
        suppressMovable: true,
        checkboxSelection: true,
        headerCheckboxSelection: false,
        valueFormatter: () => '',
      },
      {
        headerName: 'Key Type',
        field: 'keyType',
      },
      {
        headerName: 'Created at',
        field: 'createdAt',
      },
      {
        headerName: 'Last used at',
        field: 'lastUsedAt',
        sort: 'asc',
      },
    ];
  }
}

type MfaKeyRow = Omit<MfaKeySummary, 'createdAt' | 'lastUsedAt' | 'keyType'> & {
  createdAt: Date;
  lastUsedAt: Date | string;
  keyType: string;
};
