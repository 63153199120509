import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { StepperComponent } from '../stepper.component';

@Component({
  selector: 'bx-step',
  templateUrl: './step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStep, useExisting: StepComponent }],
  standalone: true,
})
export class StepComponent extends CdkStep implements OnChanges {
  @Input() disabled = false;
  @Input() disabledTooltip: string;

  // @ts-ignore
  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled || changes.disabledTooltip) {
      this.optional = this.disabled;

      // @ts-ignore
      const steps = (this._stepper as StepperComponent).steps;
      if (steps) {
        steps.notifyOnChanges();
      }
    }
    super.ngOnChanges();
  }
}
