import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
  signal,
} from '@angular/core';
import { SettingsBreadcrumbComponent } from '../../shared/breadcrumb/settings-breadcrumb.component';
import { NgFormControlValidatorDirective } from '../../shared/form-helpers/ng-form-control-validator.directive';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectUser } from '../auth/auth.selectors';
import { map, switchMap, take } from 'rxjs';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';
import { ValidateEqualDirective } from '../../shared/equals/validate-equal.directive';
import { UsersService } from '../users/users.service';
import { ErrorFormatter } from '../error-formatter.service';
import { Crumb } from '../../shared/breadcrumb/breadcrumb.component';
import { toSignal } from '@angular/core/rxjs-interop';
import User from '../user-editor/user.model';

@Component({
  selector: 'bx-email-changer',
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    NgFormControlValidatorDirective,
    ReactiveFormsModule,
    RouterLink,
    SpinnerButtonComponent,
    ValidateEqualDirective,
  ],
  templateUrl: './email-changer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailChangerComponent {
  form = new FormGroup({
    newEmail: new FormControl('', [Validators.required, Validators.email]),
    confirmEmail: new FormControl('', [Validators.required, Validators.email]),
  });

  newEmail = this.form.controls.newEmail;
  confirmEmail = this.form.controls.confirmEmail;

  errorFormatter = inject(ErrorFormatter);
  store = inject(Store<AppState>);
  activatedRoute = inject(ActivatedRoute);

  user = signal<User>(null);

  isChangingEmail = signal(false);
  changeEmailResponse = signal<{ success: boolean; error?: string } | null>(null);

  userService = inject(UsersService);

  routeData = toSignal(this.activatedRoute.data);

  isUserProfileEmailChange = computed(() => {
    return this.routeData().parentPage === 'user-profile';
  });

  crumbs: Signal<Crumb[]> = computed(() => {
    const user = this.user();
    const data = this.routeData();

    if (!user || !data) {
      return [];
    }

    // Indicate where the email changer page was being used on.
    const parentPage = data.parentPage;

    switch (parentPage) {
      case 'user-profile':
        return [{ link: ['', 'settings'], label: 'Settings' }, { label: 'Change Email' }];
      case 'geneious-admin':
        return [
          { link: ['', 'geneious-admin'], label: 'Geneious Admin' },
          {
            link: ['', 'geneious-admin', 'organizations'],
            label: 'Organizations',
          },
          {
            link: ['', 'geneious-admin', 'organizations', user.organizationId, 'users'],
            label: `${user.organizationId}/users`,
          },
          {
            link: ['', 'geneious-admin', 'organizations', user.organizationId, 'users', user.id],
            label: `${user.givenName} ${user.familyName}`,
          },
          { label: 'Change Email' },
        ];
      case 'org-admin':
        return [
          { link: ['', 'users'], label: 'Users' },
          { link: ['', 'users', user.id], label: `${user.givenName} ${user.familyName}` },
          { label: 'Change Email' },
        ];
    }
  });

  constructor() {
    this.activatedRoute.params
      .pipe(
        take(1),
        switchMap((params) => {
          return params.userID
            ? this.userService.getUser(params.userID)
            : this.store.select(selectUser).pipe(map((userDetails) => User.fromJson(userDetails)));
        }),
      )
      .subscribe((user) => {
        this.user.set(user);
      });
  }

  changeEmail() {
    this.isChangingEmail.set(true);
    this.userService.changeEmail(this.user().id, this.newEmail.value).subscribe({
      next: () => {
        this.changeEmailResponse.set({ success: true });
      },
      error: (err) => {
        this.changeEmailResponse.set({
          success: false,
          error: this.errorFormatter.toUserReadableError(err, 'Error while changing email'),
        });
      },
      complete: () => {
        this.isChangingEmail.set(false);
      },
    });
  }
}
