import { JobDialogContent } from './jobDialogContent.model';
import { StepperComponent } from '../../shared/stepper/stepper.component';

/**
 * Used as extension for Job Dialog's that will contain a stepper form.
 * They should use the `StepperComponent` and make the component ready on the component class as a public field
 * via `@ViewChild(StepperComponent, {static: true}) stepper: StepperComponent`
 */
export abstract class JobStepperDialog extends JobDialogContent {
  abstract stepper: StepperComponent;
  readonly showProfileButtons = false;
}
