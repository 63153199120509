import { Injectable } from '@angular/core';
import { JobService } from '@geneious/nucleus-api-client';
import { Observable } from 'rxjs';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { OrganizationService } from '../../organisation/organization.service';
import { NucleusPipelineID } from '../pipeline-constants';
import { AppState } from '../../core.store';
import { select, Store } from '@ngrx/store';
import { selectOrganizationID } from '../../auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class PipelineAssociationsService {
  private profilePipelineAssociation$: Observable<AssociatedPipelineMap>;

  constructor(
    private store: Store<AppState>,
    private orgService: OrganizationService,
    private jobService: JobService,
  ) {
    this.profilePipelineAssociation$ = this.store.pipe(
      select(selectOrganizationID),
      // doesn't emit anything until the user is authenticated (orgID is present)
      filter((organizationID) => !!organizationID),
      switchMap((orgID) => this.orgService.get(orgID)),
      switchMap((org) =>
        this.jobService.getOrganizationProfilePipelineAssociations(org.organizationProfile.id),
      ),
      map((response) => response.data as unknown as AssociatedPipelineMap),
      shareReplay({ bufferSize: 1, refCount: false }),
    ); // multicasts to many subscribers without repeating the API calls
  }

  /**
   * get parameters associated with a given pipeline
   * @param pipelineID - pipeline ID
   */
  getProfilePipelineAssociationParameters(pipelineID: NucleusPipelineID): Observable<any> {
    return this.getProfilePipelineAssociationsOnce().pipe(
      map((pipelineMap) => pipelineMap[pipelineID].parameters),
    );
  }

  /**
   * Get associated pipelines with the current users organization profile
   */
  getProfilePipelineAssociations(): Observable<NucleusPipelineID[]> {
    return this.getProfilePipelineAssociationsOnce().pipe(
      map((pipelineMap) => Object.keys(pipelineMap) as NucleusPipelineID[]),
    );
  }

  private getProfilePipelineAssociationsOnce(): Observable<AssociatedPipelineMap> {
    return this.profilePipelineAssociation$.pipe(first());
  }
}

export type AssociatedPipelineMap = Record<NucleusPipelineID, Record<string, any>>;
