@for (step of steps; track step; let i = $index) {
  <div class="border-top flex-grow-1 align-self-center"></div>
  <span
    [ngbTooltip]="step.disabledTooltip"
    [disableTooltip]="!step.disabled || !step.disabledTooltip"
    placement="bottom"
  >
    <button
      class="btn btn-lg"
      type="button"
      [disabled]="step.disabled || i > maxNavigableIndex"
      [ngClass]="{
        'btn-outline-primary': selectedIndex < i && color === 'primary',
        'btn-primary': selectedIndex >= i && color === 'primary',
        'btn-outline-secondary': selectedIndex < i && color === 'secondary',
        'btn-secondary': selectedIndex >= i && color === 'secondary',
      }"
      (click)="step.select()"
    >
      <span class="badge bg-primary me-1">{{ i + 1 }}</span>
      {{ step.label }}
    </button>
  </span>
  <div class="border-top flex-grow-1 align-self-center"></div>
}
