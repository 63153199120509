import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GroupsHttpV2Service,
  NewUserGroup,
  UpdateUserGroup,
  UserGroup,
} from '../../../nucleus/v2/groups-http.v2.service';

@Injectable({
  providedIn: 'root',
})
export class UserGroupsFacade {
  constructor(private groupsHttpV2Service: GroupsHttpV2Service) {}

  getGroups(userID?: string): Observable<UserGroup[]> {
    return this.groupsHttpV2Service.getGroups(userID);
  }

  getGroup(groupID: string): Observable<UserGroup> {
    return this.groupsHttpV2Service.getGroup(groupID);
  }

  createGroup(group: NewUserGroup): Observable<any> {
    return this.groupsHttpV2Service.createGroup(group);
  }

  updateGroup(group: UpdateUserGroup): Observable<any> {
    return this.groupsHttpV2Service.updateGroup(group);
  }

  deleteGroup(id: string): Observable<any> {
    return this.groupsHttpV2Service.deleteGroup(id);
  }
}
