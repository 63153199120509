import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';

/**
 * Displays text in a monospace font in a box that looks a bit like a readonly
 * textarea. Uses content projection rather than an Input because that allows
 * you to format the contents with date pipes etc. If you need to, you can use
 * span elements in the ng-content and it won't mess with the styling.
 *
 * @example
 * ```html
 * <bx-monospace-box [loading]="loading">{{ content }}</bx-monospace-box>
 * ```
 */
@Component({
  selector: 'bx-monospace-box',
  templateUrl: './monospace-box.component.html',
  styleUrls: ['./monospace-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SpinnerComponent],
})
export class MonospaceBoxComponent {
  @Input() loading = false;
}
