<ngb-alert [dismissible]="false" [type]="alertType" class="pt-4 text-break">
  @for (item of insights; track trackInsight($index, item)) {
    <div class="d-flex mb-2 pe-3">
      <fa-icon [icon]="icon" class="ms-1 me-3" data-spec="insight-icon"></fa-icon>
      <div class="d-flex flex-column mb-1">
        <b data-spec="insight-title">{{ item.title }}</b>
        @if (!truncated && item.message) {
          <span class="pt-1" data-spec="insight-msg">{{ item.message }}</span>
        }
      </div>
    </div>
  }
</ngb-alert>
