import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CleanUp } from '../../shared/cleanup';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BxFormControl } from '../user-settings/form-state/bx-form-group/bx-form-group';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'bx-editable-json-area',
  templateUrl: './editable-json-area.component.html',
  styleUrls: ['./editable-json-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, FaIconComponent, AsyncPipe],
})
export class EditableJsonAreaComponent extends CleanUp implements OnInit {
  error$ = new BehaviorSubject<string>(null);
  success$ = new BehaviorSubject<boolean>(null);

  @Input()
  bxFormControl = new BxFormControl<string>(undefined);
  currentJson$ = new BehaviorSubject<string>(undefined);

  ngOnInit(): void {
    this.validateJson(JSON.stringify(this.bxFormControl.value), true);
    this.currentJson$.subscribe((json) => this.validateJson(json, false));
  }

  private static getFormattedJson(jsonToFormat: string): string {
    const parsedJson = JSON.parse(jsonToFormat);
    return JSON.stringify(parsedJson, null, 2);
  }

  onCheckFormat() {
    this.validateJson(this.currentJson$.value, true);
  }

  validateJson(currentJson: any, format: boolean) {
    try {
      if (currentJson) {
        this.error$.next(null);
        this.success$.next(null);
        const formattedJson = EditableJsonAreaComponent.getFormattedJson(currentJson);
        if (format) {
          this.currentJson$.next(formattedJson);
        }
        this.success$.next(true);
        this.bxFormControl.setErrors(null);
        this.bxFormControl.setValue(currentJson);
      }
    } catch (e) {
      this.bxFormControl.setValue(currentJson);
      this.bxFormControl.setErrors({ invalidJson: true });
      this.error$.next(`${e}`);
    }
  }

  onKeyPressed(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      const textarea = event.target as HTMLTextAreaElement;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      textarea.value = textarea.value.substring(0, start) + '  ' + textarea.value.substring(end);
      textarea.selectionStart = textarea.selectionEnd = start + 2;
    }
  }

  protected readonly checkMarkIcon = faCheck;
}
