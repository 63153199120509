import { BehaviorSubject, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {
  OrganizationDatabaseFolder,
  SharedWorkspaceFolder,
  TreeItem,
} from '../../core/folders/models/folder.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.store';
import { selectFolders } from '../../core/folders/store/folder.selectors';
import { NgClass, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, RouterLink, AsyncPipe],
})
export class BreadcrumbComponent implements OnChanges, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-block';

  @Input() folder: TreeItem;

  crumbs$ = new BehaviorSubject<Crumb[]>([]);

  private subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnChanges({ folder }: SimpleChanges) {
    if (folder && folder.currentValue) {
      this.subscription.unsubscribe();
      this.subscription = this.store.select(selectFolders).subscribe((folders) => {
        this.crumbs$.next(this.generateCrumbs(folder.currentValue, folders));
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.crumbs$.complete();
  }

  private generateCrumbs(baseFolder: TreeItem, folders: any): Crumb[] {
    const folderTree = this.getAllParents(baseFolder, [baseFolder], folders);
    return folderTree.reduce((crumbs, folder, index) => {
      if (folderTree.length > 3 && index === 1) {
        return crumbs.concat([{ label: '•••' }]);
      } else if (index === folderTree.length - 1) {
        return crumbs.concat([{ label: folder.name }]);
      } else if (
        folderTree.length <= 3 ||
        (folderTree.length > 3 && index === 0) ||
        index === folderTree.length - 2
      ) {
        return crumbs.concat([{ label: folder.name, link: [folder.route, folder.id] }]);
      } else {
        return crumbs;
      }
    }, [] as Crumb[]);
  }

  private getAllParents(folder: TreeItem, parents: TreeItem[], folders: any): TreeItem[] {
    if (folder && folder.parentID) {
      const parent = folders[folder.parentID];
      if (
        parent &&
        !(parent instanceof SharedWorkspaceFolder) &&
        !(parent instanceof OrganizationDatabaseFolder)
      ) {
        parents.push(parent);
        return this.getAllParents(parent, parents, folders);
      } else {
        return parents.reverse();
      }
    } else {
      return parents.reverse();
    }
  }
}

export interface Crumb {
  link?: string[];
  label: string;
}
