<div class="btn-group profiles-dropdowns">
  @if ({ profiles: profiles$ | async }; as state) {
    <div
      class="btn-group"
      [ngClass]="{ 'btn-group-sm': size === 'sm', 'btn-group-lg': size === 'lg' }"
      id="apply-profile"
      ngbDropdown
      container="body"
      ngbTooltip="Create a new profile first"
      [disableTooltip]="state.profiles.length > 0 || showResetToDefaultOption"
    >
      <button
        class="btn btn-outline-primary border-end-0"
        [ngClass]="{ 'btn-sm': size === 'sm', 'btn-lg': size === 'lg' }"
        data-spec="apply-profile-btn"
        ngbDropdownToggle
        [disabled]="state.profiles.length === 0 && !showResetToDefaultOption"
        type="button"
      >
        Apply Profile
      </button>
      <div ngbDropdownMenu aria-labelledby="Apply Profile">
        @if (showResetToDefaultOption) {
          <button
            class="d-flex align-items-center"
            ngbDropdownItem
            data-spec="apply-profile-option"
            type="button"
            (click)="applyDefaultProfile()"
            title="Apply Default Profile"
          >
            Default
          </button>
        }
        @if (showResetToDefaultOption && state.profiles.length) {
          <div class="dropdown-divider"></div>
        }
        @if (state.profiles.length) {
          @for (profile of state.profiles; track trackByID($index, profile)) {
            <button
              ngbDropdownItem
              class="apply-profile-button d-flex flex-nowrap row mx-0 px-2"
              type="button"
              data-spec="apply-profile-option"
              (click)="doApplyProfile(profile)"
            >
              @if (allowCreateSharedProfile) {
                <span class="col-2 d-inline-block text-center">
                  <fa-icon [icon]="profile.displayIcon"></fa-icon>
                </span>
              }
              <span
                class="col d-inline-block me-3 overflow-hidden text-truncate"
                title="Apply '{{ profile.name }}'"
              >
                {{ profile.name }}
              </span>
            </button>
          }
        }
      </div>
    </div>
  }

  <div class="btn-group" id="save-profile-as" ngbDropdown container="body">
    <button
      class="btn btn-outline-primary"
      ngbDropdownToggle
      [ngClass]="{ 'btn-sm': size === 'sm', 'btn-lg': size === 'lg' }"
      type="button"
    >
      Save As
    </button>
    <div ngbDropdownMenu aria-labelledby="Save As">
      <button
        ngbDropdownItem
        type="button"
        data-spec="save-profile-option"
        (click)="newProfile(false)"
        class="fw-bold"
      >
        New Profile
      </button>
      @if (allowCreateSharedProfile) {
        <button ngbDropdownItem type="button" (click)="newProfile(true)" class="fw-bold">
          New Shared Profile
        </button>
      }
      @if (editableProfiles$ | async; as editableProfiles) {
        @if (editableProfiles.length > 0) {
          <div class="dropdown-divider"></div>
        }
        @for (profile of editableProfiles; track trackByID($index, profile)) {
          <button
            ngbDropdownItem
            type="button"
            data-spec="save-profile-option"
            (click)="saveProfile(profile)"
            class="bx-hover-parent d-flex flex-nowrap row mx-0 px-2"
          >
            @if (allowCreateSharedProfile) {
              <span class="col-2 d-inline-block text-center">
                <fa-icon [icon]="profile.displayIcon"></fa-icon>
              </span>
            }
            <span
              class="col d-inline-block me-2 overflow-hidden text-truncate"
              title="Save As '{{ profile.name }}'"
              >{{ profile.name }}</span
            >
            <fa-icon
              title="Delete Profile"
              [icon]="icons.delete"
              role="button"
              class="bx-visible-on-parent-hover col-1 me-1"
              (click)="deleteProfile($event, profile)"
            ></fa-icon>
          </button>
        }
      }
    </div>
  </div>
</div>
