import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ApiKeysHttpService } from '../../../nucleus/v2/api-keys-http.v2.service';
import { ApiKey } from './api-key.model';
import { ApiKeyV2Model } from '../../../nucleus/v2/models/api-key.v2.model';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organisation/organization.service';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectOrganizationID } from '../auth/auth.selectors';

const DEFAULT_API_KEYS_DISABLED_MESSAGE =
  'API keys are currently disabled for your organization. To enable API keys, please <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">contact support</a>.';

@Injectable({
  providedIn: 'root',
})
export class ApiKeysService {
  constructor(
    private apiKeysHttpService: ApiKeysHttpService,
    private store: Store<AppState>,
    private usersService: UsersService,
    private organizationService: OrganizationService,
  ) {}

  getCurrentOrganizationApiKeys(): Observable<ApiKey[]> {
    return this.store.pipe(
      select(selectOrganizationID),
      first(),
      switchMap((orgID) =>
        this.apiKeysHttpService
          .getApiKeys(orgID)
          .pipe(map((keys) => [orgID, keys] as [string, ApiKeyV2Model[]])),
      ),
      switchMap(([orgID, apiKeys]) => this.associateApiKeysWithUsers(orgID, apiKeys)),
    );
  }

  getCurrentUserApiKeys(): Observable<ApiKey[]> {
    return this.apiKeysHttpService
      .getApiKeys()
      .pipe(switchMap((apiKeys) => this.associateApiKeysWithUser(apiKeys)));
  }

  getUserApiKeys(userID: string): Observable<ApiKey[]> {
    return this.store.pipe(
      select(selectOrganizationID),
      first(),
      switchMap((orgID) =>
        this.apiKeysHttpService.getApiKeys(orgID).pipe(
          map((keys) => keys.filter((key) => key.userID === userID)),
          map((keys) => [orgID, keys] as [string, ApiKeyV2Model[]]),
        ),
      ),
      switchMap(([orgID, apiKeys]) => this.associateApiKeysWithUsers(orgID, apiKeys)),
    );
  }

  revokeApiKey(apiKey: ApiKey): Observable<void> {
    return this.apiKeysHttpService.deleteApiKey(apiKey.id);
  }

  isApiKeysEnabled(): Observable<boolean> {
    return this.store.pipe(
      select(selectOrganizationID),
      first(),
      switchMap((orgID) => this.organizationService.get(orgID)),
      map((org) => org.apiKeysEnabled),
    );
  }

  createApiKey(apiKeyName: string): Observable<ApiKey> {
    return this.apiKeysHttpService.createApiKey(apiKeyName).pipe(
      switchMap((rawApiKey) => this.associateApiKeysWithUser([rawApiKey])),
      map((apiKeys) => apiKeys[0]),
    );
  }

  getDefaultApiKeysDisabledMessage(): string {
    return DEFAULT_API_KEYS_DISABLED_MESSAGE;
  }

  private associateApiKeysWithUser(apiKeys: ApiKeyV2Model[]): Observable<ApiKey[]> {
    if (apiKeys.length === 0) {
      return of([]);
    }
    return this.usersService.getUser(apiKeys[0].userID).pipe(
      map((user) =>
        apiKeys.map((apiKey) => ({
          ...apiKey,
          user,
        })),
      ),
    );
  }

  private associateApiKeysWithUsers(orgID: string, apiKeys: ApiKeyV2Model[]): Observable<ApiKey[]> {
    return this.usersService.getUsers(orgID).pipe(
      map((users) =>
        apiKeys.map((apiKey) => ({
          ...apiKey,
          user: users.find((user) => user.id === apiKey.userID),
        })),
      ),
    );
  }
}
