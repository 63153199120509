import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  IconDefinition,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { JobInsight, JobInsightLevel } from '../job-insights.model';

@Component({
  selector: 'bx-job-insights-alert',
  standalone: true,
  imports: [NgbAlertModule, FontAwesomeModule],
  templateUrl: './job-insights-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobInsightsAlertComponent {
  /** The insights to display in an alert */
  @Input() insights: JobInsight[];

  /**
   * The level determines the color of the alert. It is assumed that all
   * insights are to be displayed at this level ({@link JobInsight.level}
   * is not checked).
   */
  @Input() set level(value: JobInsightLevel) {
    this.alertType = value;
    if (value === 'warning' || value === 'error') {
      this.icon = faExclamationTriangle;
    } else {
      this.icon = faInfoCircle;
    }
  }

  @Input() truncated: boolean = false;

  icon: IconDefinition;
  alertType: JobInsightLevel;

  trackInsight(_index: number, obj: JobInsight): number {
    return obj.id;
  }
}
