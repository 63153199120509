import { AppState } from '../../../core.store';
import { createSelector } from '@ngrx/store';

const selectJobResultsState = (state: AppState) => {
  return state.jobResults;
};

export const selectJobResults = createSelector(selectJobResultsState, (state) => state.results);

export const selectJobResultsLoading = createSelector(
  selectJobResultsState,
  (state) => state.loading,
);

export const selectJobResultLoading = (jobID: string) =>
  createSelector(selectJobResultsLoading, (resultsLoading) => resultsLoading[jobID]);

export const selectJobResultsByJobID = (jobID: string) =>
  createSelector(selectJobResults, (entities) => entities[jobID]);
