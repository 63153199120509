import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FolderService } from '../folders/folder.service';
import { first, switchMap } from 'rxjs/operators';
import { FolderTreeItem, TreeItem } from '../folders/models/folder.model';
import { CleanUp } from '../../shared/cleanup';
import { isModifierKeyPressed } from '../../shared/utils/keyboard-events';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectReferenceDatabaseID, selectSharedWorkspaceID } from '../auth/auth.selectors';
import { combineLatest } from 'rxjs';
import { NgClass, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

class RenderItem {
  public folderID: string;
  public folderLabel: string;
  public iconLabel: string;
  public indent: number;
  public hasChildFolders: boolean;
  public childStartAndNrOfChildren: number[];

  public isOpen: boolean;
  public isSelected: boolean;
  public isLocked: boolean;

  constructor(
    folderID: string,
    folderLabel: string,
    iconLabel: string,
    hasChildFolders: boolean,
    indent = 1,
  ) {
    this.isOpen = false;
    this.isSelected = false;

    this.isLocked = iconLabel === 'folder-readonly';

    this.folderID = folderID;
    this.folderLabel = folderLabel;
    this.indent = indent;
    this.hasChildFolders = hasChildFolders;
    this.childStartAndNrOfChildren = [];
    this.iconLabel = iconLabel;
  }

  getContainerClass() {
    const containerClass = ['folderContainer'];
    if (this.isSelected) {
      if (this.isLocked) {
        containerClass.push('selectedLocked');
      } else {
        containerClass.push('selected');
      }
    }

    return containerClass;
  }

  getClass() {
    const classes = ['material-icons', 'expand-icon'];
    if (this.isOpen) {
      classes.push('open');
    }
    if (!this.hasChildFolders) {
      classes.push('no-children');
    }

    return classes;
  }

  setPadding() {
    return {
      'padding-left': (this.indent * 10).toString() + 'px',
    };
  }
}

@Component({
  selector: 'bx-folder-tree-selector',
  templateUrl: './folder-tree-selector.component.html',
  styleUrls: ['./folder-tree-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FolderTreeSelectorComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgClass, NgStyle, MatIconModule],
})
export class FolderTreeSelectorComponent
  extends CleanUp
  implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor
{
  @HostBinding('class') readonly hostClass = 'd-block overflow-auto';
  @ViewChild('selectedRow') selectedRow: ElementRef;

  @Input() singleSelectionMode = false;
  @Input() activeFolderID: string | undefined;
  @Input() moveSubmitButton: HTMLButtonElement | undefined;

  selectedRenderItems = new Map<string, RenderItem>();
  activeFolderList: RenderItem[];

  constructor(
    private elementRef: ElementRef,
    private store: Store<AppState>,
    private folderService: FolderService,
  ) {
    super();
  }

  writeValue(folderIDs: string[]): void {
    folderIDs.forEach((folderID) => this.doSelect(folderID, { ctrlKey: true } as PointerEvent));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Not yet implemented.
  }

  ngAfterViewInit() {
    if (this.selectedRow) {
      this.selectedRow.nativeElement.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  }

  resolveParentPath(folderID?: string): TreeItem[] {
    const resolvePathTreeItemList: TreeItem[] = [];
    const sharedWorkspaceID$ = this.store.pipe(select(selectSharedWorkspaceID));
    const referenceDatabaseID$ = this.store.pipe(select(selectReferenceDatabaseID));

    combineLatest([sharedWorkspaceID$, referenceDatabaseID$])
      .subscribe(([sharedWorkspaceID, referenceDatabaseID]) => {
        const rootFolderID = sharedWorkspaceID;
        const rootReferenceDatabaseFolderID = referenceDatabaseID;
        let currentFolder = folderID ?? rootFolderID;
        while (currentFolder !== rootFolderID && currentFolder !== rootReferenceDatabaseFolderID) {
          this.folderService
            .get(currentFolder)
            .pipe(first())
            .subscribe((item: TreeItem) => {
              currentFolder = item.parentID;
              resolvePathTreeItemList.push(item);
            });
        }
      })
      .unsubscribe();

    return resolvePathTreeItemList;
  }

  getIndexOfFolderID(folderID: string) {
    return this.activeFolderList.findIndex((folder) => folderID === folder.folderID);
  }

  closeFolder(activeRenderItem: RenderItem) {
    if (activeRenderItem.isOpen) {
      activeRenderItem.isOpen = false;

      const childStartAndNrOfChildren = activeRenderItem.childStartAndNrOfChildren;

      for (
        let index = childStartAndNrOfChildren[0];
        index < childStartAndNrOfChildren[0] + childStartAndNrOfChildren[1];
        index++
      ) {
        this.closeFolder(this.activeFolderList[index]);
      }

      this.activeFolderList.splice(childStartAndNrOfChildren[0], childStartAndNrOfChildren[1]);
    }
  }

  openFolder(activeRenderItem: RenderItem, activeFolderIndex: number) {
    activeRenderItem.isOpen = true;

    this.folderService
      .getChildren(activeRenderItem.folderID)
      .subscribe((itemList) => {
        (itemList as FolderTreeItem[]).forEach((item, index) => {
          if (item.permissions.indexOf('write') >= 0) {
            this.folderService
              .getChildren(item.id)
              .subscribe((childList) => {
                let iconLabel;
                if (item.shared) {
                  iconLabel = item.icon;
                } else {
                  iconLabel = item.iconPrivate;
                }

                const renderItem = new RenderItem(
                  item.id,
                  item.name,
                  iconLabel,
                  childList.length > 0,
                  activeRenderItem.indent + 1,
                );
                if (this.selectedRenderItems.has(item.id)) {
                  renderItem.isSelected = true;
                  this.selectedRenderItems.set(item.id, renderItem);
                }
                this.activeFolderList.splice(activeFolderIndex + 1 + index, 0, renderItem);
              })
              .unsubscribe();
          } else {
            const renderItem = new RenderItem(item.id, item.name, item.iconReadOnly, false, 0);
            if (this.selectedRenderItems.has(item.id)) {
              renderItem.isSelected = true;
              this.selectedRenderItems.set(item.id, renderItem);
            }
            this.activeFolderList.push(renderItem);
          }
        });

        activeRenderItem.childStartAndNrOfChildren = [activeFolderIndex + 1, itemList.length];
      })
      .unsubscribe();
  }

  ngOnInit() {
    this.activeFolderList = [];
    const resolvePath = this.resolveParentPath(this.activeFolderID);

    // GET DATA
    this.store
      .pipe(select(selectSharedWorkspaceID))
      .pipe(
        switchMap((sharedWorkspaceID) => {
          return this.folderService.getChildren(sharedWorkspaceID);
        }),
      )
      .subscribe((itemList) => {
        (itemList as FolderTreeItem[]).forEach((item) => {
          if (item.permissions.indexOf('write') >= 0) {
            this.folderService
              .getChildren(item.id)
              .subscribe((childList) => {
                let iconLabel;
                if (item.shared) {
                  iconLabel = item.icon;
                } else {
                  iconLabel = item.iconPrivate;
                }

                const renderItem = new RenderItem(
                  item.id,
                  item.name,
                  iconLabel,
                  childList.length > 0,
                  0,
                );
                this.activeFolderList.push(renderItem);
              })
              .unsubscribe();
          } else {
            const renderItem = new RenderItem(item.id, item.name, item.iconReadOnly, false, 0);
            this.activeFolderList.push(renderItem);
          }
        });

        while (resolvePath.length > 0) {
          const resolveTreeItem = resolvePath.pop();
          this.doToggleExpand(resolveTreeItem.id);
          if (resolvePath.length === 0) {
            this.doSelect(resolveTreeItem.id);
          }
        }
      })
      .unsubscribe();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  doToggleExpand(folderID: string) {
    const activeFolderIndex: number = this.getIndexOfFolderID(folderID);
    const activeRenderItem = this.activeFolderList[activeFolderIndex];

    if (!activeRenderItem) {
      return;
    }

    if (activeRenderItem.isOpen === false) {
      this.openFolder(activeRenderItem, activeFolderIndex);
    } else {
      this.closeFolder(activeRenderItem);
    }
  }

  toggleExpand($event: Event, folderID: string) {
    this.doToggleExpand(folderID);
    $event.stopPropagation();
  }

  doSelect(folderID: string, event?: MouseEvent | PointerEvent) {
    const activeIndex = this.getIndexOfFolderID(folderID);
    const activeRenderItem = this.activeFolderList[activeIndex];

    if (!activeRenderItem) {
      return;
    }

    const modifierKeyPressed =
      event && !this.singleSelectionMode ? isModifierKeyPressed(event) : false;

    if (!modifierKeyPressed) {
      // Unselected everything
      for (const selectedFolderID of this.selectedRenderItems.keys()) {
        this.activeFolderList[this.getIndexOfFolderID(selectedFolderID)].isSelected = false;
        this.selectedRenderItems.delete(selectedFolderID);
      }
      activeRenderItem.isSelected = true;
      this.selectedRenderItems.set(folderID, activeRenderItem);
    } else {
      activeRenderItem.isSelected = !activeRenderItem.isSelected;
      if (activeRenderItem.isSelected) {
        this.selectedRenderItems.set(folderID, activeRenderItem);
      } else {
        this.selectedRenderItems.delete(folderID);
      }
    }

    this.onChange(Array.from(this.selectedRenderItems.keys()));

    if (this.moveSubmitButton) {
      this.moveSubmitButton.disabled = activeRenderItem.isLocked;
    }
  }

  select($event: MouseEvent | PointerEvent, folderID: string) {
    this.doSelect(folderID, $event);
    $event.stopPropagation();
  }

  private onChange = (value: string[]) => {};
  private onTouched = () => {};
}
