import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Log, LogLevel } from '@geneious/nucleus-api-client';
import {
  MonoTypeOperatorFunction,
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  pipe,
  scan,
  share,
  startWith,
  takeUntil,
} from 'rxjs';
import { ActivityStreamService } from 'src/app/core/activity/activity-stream.service';
import { JobsService } from 'src/app/core/jobs/job.service';
import { JobActivityEventKind } from 'src/nucleus/v2/models/activity-events/activity-event-kind.model';
import { JobLogAddedActivityEvent } from 'src/nucleus/v2/models/activity-events/job-activity-event.model';
import { CleanUp } from '../cleanup';
import { compareStrings } from '../utils/object';
import { JobInsightsAlertComponent } from './job-insights-alert/job-insights-alert.component';
import { JobInsight, JobInsightLevel } from './job-insights.model';

type LogWithValidLevel = Omit<Log, 'level'> & {
  level: 'info' | 'warning';
};

@Component({
  selector: 'bx-job-insights',
  standalone: true,
  imports: [CommonModule, JobInsightsAlertComponent, SpinnerComponent],
  templateUrl: './job-insights.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobInsightsComponent extends CleanUp implements OnInit {
  @Input() jobID: string;
  /** The organization ID of the job submitter */
  @Input() organizationID: string;
  @Input() insightDisplayLimit: number | null = null;

  infoInsights$: Observable<JobInsight[]>;
  warningInsights$: Observable<JobInsight[]>;
  loading$: Observable<boolean>;
  insightsToDisplay$: Observable<{
    warning: JobInsight[];
    info: JobInsight[];
  }>;

  /** Log levels to display  */
  private readonly validLevels = [LogLevel.Info, LogLevel.Warning];

  constructor(
    private readonly jobsService: JobsService,
    private readonly activityStreamService: ActivityStreamService,
  ) {
    super();
  }

  ngOnInit(): void {
    const activityLogs$: Observable<LogWithValidLevel[]> = this.activityStreamService
      .listenToJobActivity()
      .pipe(
        filter(
          (e) =>
            e.event.kind === JobActivityEventKind.JOB_LOG_ADDED && e.event.jobID === this.jobID,
        ),
        map(({ event }) => (event as JobLogAddedActivityEvent).log),
        filter((log): log is LogWithValidLevel =>
          (this.validLevels as LogLevel[]).includes(log.level),
        ),
        scan((existing, log) => existing.concat(log), []),
        startWith([]),
        catchError(() => of([])),
        takeUntil(this.ngUnsubscribe),
      );
    const apiLogs$: Observable<LogWithValidLevel[]> = (
      this.jobsService.getOrganizationJobLogs(
        this.organizationID,
        this.jobID,
        this.validLevels,
      ) as Observable<LogWithValidLevel[]>
    ).pipe(
      catchError(() => of([])),
      takeUntil(this.ngUnsubscribe),
    );

    const allInsights$: Observable<JobInsight[]> = combineLatest([apiLogs$, activityLogs$]).pipe(
      map(([apiLogs, activityLogs]) => {
        // The activity stream could return logs that are already included in the API response
        const uniqueActivityLogs = activityLogs.filter(
          (activityLog) => !apiLogs.some((apiLog) => this.logsAreEqual(activityLog, apiLog)),
        );
        return apiLogs.concat(uniqueActivityLogs).sort(this.sortLogs);
      }),
      map((logs) => logs.map((log, index) => this.toJobInsight(log, index))),
      takeUntil(this.ngUnsubscribe),
      share(),
    );
    this.loading$ = allInsights$.pipe(
      map(() => false),
      startWith(true),
    );

    const insightsForLevel = (level: JobInsightLevel): MonoTypeOperatorFunction<JobInsight[]> =>
      pipe(
        map((insights) => insights.filter((i) => i.level === level)),
        distinctUntilChanged(compareStrings((insights) => insights.map((i) => i.id).join())),
      );
    this.infoInsights$ = allInsights$.pipe(insightsForLevel('info'));
    this.warningInsights$ = allInsights$.pipe(insightsForLevel('warning'));
    this.insightsToDisplay$ = combineLatest([this.infoInsights$, this.warningInsights$]).pipe(
      map(([info, warning]) => {
        if (this.insightDisplayLimit !== null) {
          const truncatedWarning = warning.slice(
            0,
            Math.min(this.insightDisplayLimit, warning.length),
          );
          const truncatedInfo = info.slice(
            0,
            Math.max(0, this.insightDisplayLimit - truncatedWarning.length),
          );
          return {
            info: truncatedInfo,
            warning: truncatedWarning,
          };
        }
        return { info, warning };
      }),
    );
  }

  private toJobInsight(log: LogWithValidLevel, index: number): JobInsight {
    const firstNewlineIndex = log.unredacted.indexOf('\n');
    let title: string;
    let message: string | undefined;
    if (firstNewlineIndex === -1) {
      title = log.unredacted.trim();
      message = undefined;
    } else {
      title = log.unredacted.slice(0, firstNewlineIndex).trim();
      message = log.unredacted.slice(firstNewlineIndex).trim();
    }
    return {
      title,
      message,
      id: index,
      level: log.level,
    };
  }

  private logsAreEqual(log1: Log, log2: Log): boolean {
    return (
      log1.loggedAt === log2.loggedAt &&
      log1.level === log2.level &&
      log1.redacted === log2.redacted &&
      log1.unredacted === log2.unredacted
    );
  }

  private sortLogs(log1: Log, log2: Log): number {
    return Date.parse(log1.loggedAt) - Date.parse(log2.loggedAt);
  }

  trackInsight(_index: number, obj: JobInsight): number {
    return obj.id;
  }
}
