import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UnencryptedMfaKey } from '@geneious/nucleus-api-client';
import { map, Observable, Subscription } from 'rxjs';
import { CreateMfaDialogForm } from '../create-mfa-dialog/create-mfa-dialog.component';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { tap } from 'rxjs/operators';
import { NgClass, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'bx-verify-mfa',
  templateUrl: './verify-mfa.component.html',
  styleUrls: ['./verify-mfa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, FormsModule, ReactiveFormsModule, FaIconComponent, NgbTooltip, AsyncPipe],
})
export class VerifyMfaComponent implements OnInit, OnDestroy {
  @Input() newMfa$: Observable<UnencryptedMfaKey>;
  @Input() form: CreateMfaDialogForm;
  qr$: Observable<string>;
  showTextKey: boolean = false;
  showQR: boolean = false;
  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
  private subscriptions = new Subscription();
  ngOnInit(): void {
    this.subscriptions.add(
      this.newMfa$
        .pipe(
          tap((mfa) => {
            this.form.controls.keyCode.disable();
            this.form.controls.keyCode.setValue(mfa.key);
          }),
        )
        .subscribe(),
    );
    this.qr$ = this.newMfa$.pipe(
      map((mfa) => {
        return `data:image/png;base64,${mfa.qrCodeImage}`;
      }),
    );
  }
  toggleShowTextKey() {
    this.showTextKey = !this.showTextKey;
  }
  toggleShowQR() {
    this.showQR = !this.showQR;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
