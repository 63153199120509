import { Injectable } from '@angular/core';
import { DialogService } from '../../shared/dialog/dialog.service';
import { PipelineDialogV2Component } from './pipeline-dialog-v2/pipeline-dialog-v2.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PipelineDialogData } from './index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PipelineDialogService {
  constructor(private dialogService: DialogService) {}

  showDialog(data: PipelineDialogData): NgbModalRef {
    return this.dialogService.showDialogV2({
      component: PipelineDialogV2Component,
      injectableData: {
        ...data,
      },
      options: {
        size: 'lg',
        backdrop: 'static',
        scrollable: true,
      },
    });
  }

  showDialog$<T>(data: PipelineDialogData<T>): Observable<unknown> {
    return this.dialogService.showDialogV2$({
      component: PipelineDialogV2Component,
      injectableData: {
        ...data,
      },
      options: {
        size: 'lg',
        backdrop: 'static',
        scrollable: true,
      },
    });
  }
}
