<div class="modal-header">
  <h4 class="modal-title">
    @if (isShared) {
      Create New Shared Profile
    } @else {
      Create New Profile
    }
  </h4>
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="ngbActiveModal.dismiss()"
  ></button>
</div>

<div class="modal-body scrollable container-fluid">
  <form id="newProfileForm" [formGroup]="form" (ngSubmit)="submit()">
    <div class="row">
      <label for="profileName" class="col-form-label-sm col-3" data-spec="new-profile-name-label">
        Name
      </label>
      <div class="col col-9">
        <div class="input-group-sm">
          <input
            type="text"
            id="profileName"
            name="profileName"
            formControlName="profileName"
            class="form-control form-control-sm"
            ngFormControlValidator
            [canMarkPristineInvalid]="false"
            placeholder="Enter a name for your new profile"
            autocomplete="off"
            ngbAutoFocus
            data-spec="new-profile-name-input"
          />
          <bx-form-errors
            [control]="form.controls.profileName"
            data-spec="new-profile-name-errors"
          ></bx-form-errors>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary"
    form="newProfileForm"
    type="submit"
    bx-spinner-button
    [disabled]="form.invalid || (submitting$ | async)"
    [spin]="submitting$ | async"
    data-spec="new-profile-submit-btn"
  >
    Create Profile
  </button>
  <button type="button" class="btn btn-secondary" (click)="ngbActiveModal.dismiss()">Cancel</button>
</div>
