import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidatorFn,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MemoizedSelector, Store, createSelector } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, takeUntil, timer } from 'rxjs';
import { AppState } from 'src/app/core/core.store';
import { selectProfilesForIdentifier } from 'src/app/core/user-settings/profiles/profiles.selectors';
import { ProfileService } from '../../../core/user-settings/profiles/profile.service';
import { ProfileFeature } from '../../../core/user-settings/profiles/profiles.model';
import { CleanUp } from '../../cleanup';
import { MODAL_DATA } from '../../dialog';
import { AsyncPipe } from '@angular/common';
import { NgFormControlValidatorDirective } from '../../form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../form-errors/form-errors.component';
import { SpinnerButtonComponent } from '../../spinner-button/spinner-button.component';

@Component({
  selector: 'bx-new-profile',
  templateUrl: './new-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    SpinnerButtonComponent,
    AsyncPipe,
  ],
})
export class NewProfileComponent extends CleanUp implements OnDestroy {
  readonly form: FormGroup<{ profileName: FormControl<string> }>;
  readonly submitting$ = new BehaviorSubject<boolean>(false);
  readonly isShared: boolean;
  /** Selects existing profile names for this feature & identifier */
  private readonly selectProfileNames: MemoizedSelector<AppState, string[]>;

  constructor(
    @Inject(MODAL_DATA)
    private readonly profileData: {
      identifier: string;
      feature: ProfileFeature;
      data: unknown;
      isShared: boolean;
    },
    private readonly profileService: ProfileService,
    private readonly store: Store<AppState>,
    readonly ngbActiveModal: NgbActiveModal,
  ) {
    super();
    this.isShared = profileData.isShared;
    this.selectProfileNames = createSelector(
      selectProfilesForIdentifier(profileData.identifier, profileData.feature),
      (profiles) => profiles.map((profile) => profile.name),
    );
    this.form = new FormGroup({
      profileName: new FormControl<string>(undefined, this.nameNotEmpty, this.uniqueName),
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.submitting$.complete();
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAsTouched();
      return;
    }
    this.submitting$.next(true);
    this.createProfile().subscribe({
      next: (profile) => {
        this.ngbActiveModal.close(profile);
      },
      complete: () => {
        this.submitting$.next(false);
      },
    });
  }

  private createProfile() {
    const newName = this.form.value.profileName.trim();
    return this.profileService.createProfile(
      newName,
      this.profileData.identifier,
      this.profileData.feature,
      this.profileData.data,
      this.profileData.isShared,
    );
  }

  private uniqueName: AsyncValidatorFn = async (control) => {
    const name = control.value?.trim();
    if (!name) {
      return null;
    }
    const names = await firstValueFrom(
      this.store.select(this.selectProfileNames).pipe(takeUntil(timer(1000))),
      { defaultValue: [] },
    );
    if (names.includes(name)) {
      return { unique: 'There is already a profile with that name' };
    }
    return null;
  };

  private nameNotEmpty: ValidatorFn = (control) => {
    const name = control.value;
    if (!name || name.trim().length === 0) {
      return { empty: 'Set a name for your new profile' };
    }
    return null;
  };
}
