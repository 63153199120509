import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import {
  DataManagementService,
  JobResultTypes,
  JobStatus,
  Log,
  LogLevel,
  OrganizationDetails,
} from '@geneious/nucleus-api-client';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  pluck,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { JobsService } from 'src/app/core/jobs/job.service';
import { selectNameSchemeByID } from 'src/app/core/organization-settings/organization-settings.selectors';
import { Job } from '../../../nucleus/services/models/job.model';
import {
  JobConfigurationWithSelection,
  SelectionOptionsV1,
} from '../../../nucleus/services/models/jobParameters.model';
import { JobActivityEventKind } from '../../../nucleus/v2/models/activity-events/activity-event-kind.model';
import { ActivityStreamService } from '../../core/activity/activity-stream.service';
import { AppState } from '../../core/core.store';
import { OrganizationService } from '../../core/organisation/organization.service';
import { fullName } from '../utils/user';
import {
  selectOrganizationID,
  selectUser,
  selectUserID,
  selectUserIsNucleusAdmin,
  selectUserIsOrgAdmin,
} from '../../core/auth/auth.selectors';
import { RunFromJsonComponent } from '../../core/pipeline-dialogs/run-from-json/run-from-json.component';
import { PipelineDialogService } from '../../core/pipeline-dialogs/pipeline-dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { DocumentHttpV2Service } from '../../../nucleus/v2/document-http.v2.service';
import { isNonStageJobEvent } from 'src/nucleus/v2/models/activity-events/job-activity-event.model';
import { NgClass, NgTemplateOutlet, AsyncPipe, DatePipe } from '@angular/common';
import { JobInsightsComponent } from '../job-insights/job-insights.component';
import { MonospaceBoxComponent } from '../monospace-box/monospace-box.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'bx-job-details',
  templateUrl: './job-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    RouterLink,
    JobInsightsComponent,
    MonospaceBoxComponent,
    SpinnerComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class JobDetailsComponent implements OnChanges, OnDestroy {
  @Input() job: Job;
  /** Use true for modals, false for full-page display */
  @Input() compact = true;
  @Input() isAdminView = false;

  /** Pretty-printed JSON of job configuration parameters */
  jobParams: string;
  /** Timestamp for when the job was queued */
  queueDate$: Observable<JobDetailData<Date>>;
  /** Timestamp for when the job started (after queueDate) */
  startDate$: Observable<JobDetailData<Date>>;
  /** Job statuses formatted for the template in reverse chronological order */
  statusUpdates$: Observable<JobDetailData<StatusUpdate[]>>;
  /** Job error logs (nucleus admin only) **/
  jobErrors$: Observable<JobDetailData<JobLog[]>>;
  /** Determines whether to show the redacted or un-redacted Job Logs **/
  showRedacted$ = new BehaviorSubject<boolean>(true);
  /** Details of the job submitter */
  submitter$: Observable<JobDetailData<NamedItem>>;
  /** Organization of job submitter (only shown in admin view) */
  organization$: Observable<OrganizationDetails>;
  /** Input folder/files */
  inputSelection$: Observable<JobSelection>;
  /** Output folder/files */
  outputResults$: Observable<JobSelection | undefined>;
  /** Name scheme used for job (if applicable) */
  nameScheme$: Observable<JobDetailData<NamedItem> | undefined>;
  /** Reference database used for job (if applicable) */
  referenceDatabases$: Observable<JobDetailData<NamedItem[]> | undefined>;
  /** Job Report */
  snapshot$: Observable<JobReportData | undefined>;
  /** run-from-json feature switch status */
  runFromJsonEnabled$: Observable<boolean>;
  /** jobInsights feature switch status */
  jobInsightsEnabled$: Observable<boolean>;
  /** Template-bound enum */
  readonly ItemState = ItemState;
  /** Maximum number of input files to show */
  readonly MAX_INPUT_FILE_TO_SHOW = 10;

  /**
   * Raw job statuses in reverse chronological order (and a boolean that can be
   * used to determine whether the job is complete).
   */
  private jobStatuses$: Observable<{ statuses: JobStatus[]; isComplete: boolean }>;

  private runFromJsonModalRef: NgbModalRef;

  constructor(
    private readonly jobsService: JobsService,
    private readonly activityService: ActivityStreamService,
    private readonly store: Store<AppState>,
    private readonly organizationService: OrganizationService,
    private readonly dataManagementService: DataManagementService,
    private readonly pipelineDialogService: PipelineDialogService,
    private readonly featureSwitchService: FeatureSwitchService,
    private documentHttpService: DocumentHttpV2Service,
  ) {
    this.jobInsightsEnabled$ = this.featureSwitchService.isEnabledOnce('jobInsights');
    this.runFromJsonEnabled$ = this.featureSwitchService
      .isEnabledOnce('runFromJson')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  ngOnDestroy(): void {
    if (this.runFromJsonModalRef) {
      this.runFromJsonModalRef.close();
    }
  }

  ngOnChanges({ job }: SimpleChanges) {
    if (!job.currentValue) {
      return;
    }

    const params = this.job.config.parameters as Partial<
      JobConfigurationWithSelection['parameters']
    >;
    this.jobParams = JSON.stringify(params, undefined, 2);
    this.snapshot$ = this.jobsService
      .getOrganizationJobReport(this.job.submitter.organizationID, this.job.id)
      .pipe(
        map((data) => data.report as JobReportData),
        catchError(() => of({})),
        shareReplay(1),
      );

    this.jobStatuses$ = this.getJobStatusUpdates(this.job);
    this.jobErrors$ = this.getJobLogs(this.job).pipe(
      map((logs) => this.resolvedItem(logs)),
      switchMap((item) =>
        this.showRedacted$.pipe(
          map((showRedacted) => ({
            ...item,
            data: item.data.map(({ loggedAt, level, unredacted, redacted }) => ({
              loggedAt,
              level,
              message: showRedacted ? redacted : unredacted,
            })),
          })),
        ),
      ),
      startWith(LOADING_ITEM),
      catchError(() => of(ERRORED_ITEM)),
    );
    this.statusUpdates$ = this.jobStatuses$.pipe(
      map(({ statuses }) =>
        this.resolvedItem(
          statuses.map((status) => ({
            kind: status.kind,
            time: new Date(status.dateTime),
            messages: status.messages.join('\n'),
          })),
        ),
      ),
      startWith(LOADING_ITEM),
      catchError(() => of(ERRORED_ITEM)),
    );
    /**
     * Returns an Observable of JobDetailData<Date>, representing the timestamp of the first status
     * with the given kind.
     */
    const dateOfFirstStatusWithKind = (kind: JobStatus['kind']) =>
      this.jobStatuses$.pipe(
        map(({ statuses }) => {
          // Job statuses are in reverse chronological order, so lastIndexOf gives us the first matching status
          const indexOfFirstStatusWithKind = statuses.map((s) => s.kind).lastIndexOf(kind);
          if (indexOfFirstStatusWithKind === -1) {
            return null;
          }
          return statuses[indexOfFirstStatusWithKind];
        }),
        first((status) => !!status?.dateTime),
        map((status) => this.resolvedItem(new Date(status.dateTime))),
        startWith(LOADING_ITEM),
        catchError(() => of(ERRORED_ITEM)),
      );
    this.startDate$ = dateOfFirstStatusWithKind('Running');
    this.queueDate$ = dateOfFirstStatusWithKind('Queued');

    if (this.isAdminView) {
      this.organization$ = this.organizationService.get(this.job.submitter.organizationID);
    }
    this.submitter$ = this.getUserDetails(this.job.submitter.userID);
    this.inputSelection$ = this.getInputSelection(
      params.selection as SelectionOptionsV1 | undefined,
      this.job.submitter.organizationID,
    );
    this.nameScheme$ = this.getNameSchemeDetails(this.job);
    this.referenceDatabases$ = this.getReferenceSequenceDetails(this.job);
    this.outputResults$ = this.getOutputResults(this.job);
  }

  /**
   * Fetches job activity status updates. If the job is still running and the current
   * user was the submitter, it also listens to activity updates and adds them to
   * the list.
   * @param job to get updates for
   * @returns Observable of JobStatuses
   */
  getJobStatusUpdates(job: Job): Observable<{ statuses: JobStatus[]; isComplete: boolean }> {
    return forkJoin([
      this.store.select(selectUserID).pipe(first()),
      this.store.select(selectUserIsOrgAdmin).pipe(first()),
    ]).pipe(
      switchMap(([userID, isAdmin]) => {
        if (userID === job.submitter.userID) {
          return this.getUserJobStatusUpdates(job.id);
        }
        if (isAdmin) {
          return this.jobsService.getOrganizationJobActivity(job.submitter.organizationID, job.id);
        }
        throw new Error('User is not permitted to access status updates');
      }),
      map((statuses) => ({ statuses, isComplete: this.jobIsComplete(statuses) })),
      catchError(() => {
        const statuses = [job.status];
        return of({ statuses, isComplete: this.jobIsComplete(statuses) });
      }),
      shareReplay(1),
      takeWhile(({ isComplete }) => !isComplete, true),
    );
  }

  /**
   * Fetches job logs. Only a Nucleus admin should be able to view the internal
   * error-level job logs in the Geneious Admin section.
   *
   * @param job to get logs for
   * @returns Observable of Log[]
   */
  getJobLogs(job: Job): Observable<Log[]> {
    return this.store.pipe(
      select(selectUserIsNucleusAdmin),
      first(),
      switchMap((isNucleusAdmin) => {
        if (isNucleusAdmin && this.isAdminView) {
          return this.jobsService.getOrganizationJobLogs(job.submitter.organizationID, job.id, [
            LogLevel.Error,
          ]);
        }
        throw new Error('User or View is not permitted to access internal job logs');
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  /**
   * Fetches details of the output folders and documents of the specified job.
   * @param job The job
   * @returns Observable containing {@link JobResults} for the job
   */
  getOutputResults(job: Job): Observable<JobSelection | undefined> {
    const jobID = job.id;
    return this.jobStatuses$.pipe(
      filter(({ isComplete }) => isComplete),
      take(1),
      switchMap(() => this.jobsService.getJobResultsByJobID(jobID, job.submitter.organizationID)),
      switchMap((results) => {
        const categorized = {
          folders: results.filter((r) => r.resultType === JobResultTypes.FolderEntity),
          documents: results.filter((r) => r.resultType === JobResultTypes.DocumentEntity),
        };
        if (categorized.folders.length > 0) {
          return of({ folderID: categorized.folders[0].resultID, ...categorized });
        }
        // if there are output files but no output folders, use parent from the first document
        if (categorized.documents.length > 0) {
          return this.documentHttpService.get(categorized.documents[0].resultID).pipe(
            take(1),
            map((doc) => {
              return { folderID: doc.parentID, ...categorized };
            }),
          );
        }
        // If no documents or folders, all results have the type NUCLEUS_EXPORTED_DOCUMENT_ENTITY
        // We do not support displaying those yet - see BX-7040
        throw new Error('No supported output documents found');
      }),
      switchMap((results) => {
        const outputFolders$ = this.getFolderDetails(results.folderID).pipe(
          catchError(() => {
            // outputFolderName is stored in params so we can't use fallbackToSnapshot
            const name = (job.config.parameters as any)?.output?.outputFolderName as string;
            const item: NamedItem = name ? { id: results.folderID, name } : undefined;
            return of(this.markAsSnapshot(item));
          }),
        );
        const documents$ = this.getDocumentDetails(
          {
            selectAll: false,
            ids: results.documents.map((doc) => doc.resultID),
            folderId: null,
          },
          job.submitter.organizationID,
        );
        return combineLatest([outputFolders$, documents$]);
      }),
      map(([folder, documents]) => ({ folder, documents })),
      startWith({ folder: LOADING_ITEM }),
      catchError(() => of({ folder: ERRORED_ITEM })),
    );
  }

  /**
   * Fetches status updates for a job that the user submitted. If the job is
   * still running, emits an updated list of status updates every time new
   * activity occurs.
   * @param jobID Job ID
   * @returns Observable containing a list of all JobStatuses thus far
   */
  getUserJobStatusUpdates(jobID: string): Observable<JobStatus[]> {
    const jobActivity$: Observable<JobStatus[]> = this.jobsService
      .getUserJobActivity(jobID)
      .pipe(catchError(() => of([])));
    const liveActivity$: Observable<JobStatus[]> = this.activityService.listenToJobActivity().pipe(
      filter(({ event }) => isNonStageJobEvent(event) && event.jobID === jobID),
      map((activity) => ({
        ...activity.event,
        kind: this.activityKindToJobStatusKind(activity.event.kind),
      })),
      // Filter out any events that aren't included in JobService getUserJobActivity API
      filter((status) => status.kind !== null),
      // Prepend new statuses to the output
      scan((existing, status) => [status, ...existing], []),
      startWith([]),
      catchError(() => of([])),
    );
    return combineLatest([jobActivity$, liveActivity$]).pipe(
      map(([jobActivity, liveActivity]) => {
        // Sometimes the activity stream returns statuses that are included in jobActivity
        const uniqueLiveActivity = liveActivity.filter(
          (liveStatus) =>
            !jobActivity.some((jobStatus) => this.isEqualStatus(liveStatus, jobStatus)),
        );
        // Put new events at the start so they are in order
        return uniqueLiveActivity.concat(jobActivity);
      }),
    );
  }

  /**
   * Fetches the details (i.e. name) of the specified user.
   * @param userID The user ID
   * @returns Observable of named user wrapped in {@link JobDetailData}
   */
  getUserDetails(userID: string): Observable<JobDetailData<NamedItem>> {
    // Avoid getUser API call if the job user is the current user
    return this.store.pipe(
      select(selectUser),
      switchMap((currentUser) => {
        if (currentUser.id === userID) {
          return of({ id: userID, name: fullName(currentUser) });
        }
        return this.organizationService.getPrincipalUsers(this.job.submitter.organizationID).pipe(
          map((principals) => {
            const userPrincipal = principals.find(
              (principal) => principal.principalId === userID,
            )?.principalData;
            if (!userPrincipal) {
              throw new Error('User does not exist'); // fall through to catchError
            }
            return { id: userID, name: fullName(userPrincipal) };
          }),
        );
      }),
      map((user) => this.resolvedItem(user)),
      first(),
      startWith(LOADING_ITEM),
      catchError(() => this.fallbackToSnapshot((snapshot) => snapshot.user)),
    );
  }

  /**
   * Fetches details (i.e. name) of the folder with the specified ID.
   * @param id Folder ID, or undefined
   * @returns Observable containing {@link JobDetailData} for the folder, or undefined
   *          if the ID is undefined
   */
  getFolderDetails(id?: string): Observable<JobDetailData<NamedItem> | undefined> {
    if (!id) {
      return of(undefined);
    }
    return this.dataManagementService.getFolder(id).pipe(
      map((response) => this.resolvedItem({ id, name: response.data.name })),
      startWith(LOADING_ITEM),
    );
  }

  /**
   * Fetches details of the documents in the specified selection.
   * Will fetch it from a different organization from the given jobOrganizationID if needed.
   *
   * @param selection Selection parameters for the job
   * @param jobOrganizationID Organization ID for the given job
   * @returns Observable containing {@link NamedDocument} wrapped in {@link JobDetailData},
   *          or undefined if there are no document IDs in the selection.
   */
  getDocumentDetails(
    selection: SelectionOptionsV1 | undefined,
    jobOrganizationID: string,
  ): Observable<JobDetailData<NamedDocument>[] | undefined> {
    if (!selection?.ids || selection.ids.length === 0 || selection.selectAll) {
      return of(undefined);
    }
    return this.dataManagementService
      .searchAllItemsInOrganization(jobOrganizationID, [], [], undefined, undefined, {
        idInList: selection.ids,
      })
      .pipe(
        // return empty list of documents on error (4xx code) so that everything fallback to snapshot
        catchError(() => of({ data: [] })),
        pluck('data'),
        switchMap((items) => {
          const documents = items.map((item) =>
            this.resolvedItem({ id: item.id, name: item.metadata.name, parentID: item.parentID }),
          );
          const fetchedDocumentIDs = items.map((item) => item.id);
          const snapshotDocumentIDs = selection.ids.filter(
            (id) => !fetchedDocumentIDs.includes(id),
          );
          return this.snapshot$.pipe(
            map((snapshot) => snapshot?.inputDocuments?.documents ?? []),
            map((snapshotDocuments) =>
              snapshotDocumentIDs.map((snapshotDocumentID) =>
                snapshotDocuments.find((document) => document.id === snapshotDocumentID),
              ),
            ),
            map((documents) => documents.map((document) => this.markAsSnapshot(document))),
            map((snapshotDocuments) => [...documents, ...snapshotDocuments]),
          );
        }),
        startWith(selection.ids.map(() => LOADING_ITEM)),
      );
  }

  /**
   * Fetches details of the folders and documents in the specified selection.
   * Will fetch documents from another organization with the given jobOrganizationID if needed
   *
   * @param selection Selection parameters for the job
   * @param jobOrganizationID Organization ID for the given job
   * @returns Observable of {@link JobDetailData} for the folder and documents
   *          in the selection, or undefined if there are none.
   */
  getInputSelection(
    selection: SelectionOptionsV1 | undefined,
    jobOrganizationID: string,
  ): Observable<JobSelection | undefined> {
    return combineLatest([
      this.getFolderDetails(selection?.folderId).pipe(
        catchError(() => this.fallbackToSnapshot((snapshot) => snapshot.inputFolder)),
      ),
      this.getDocumentDetails(
        selection
          ? {
              ...selection,
              ids: selection.ids.slice(0, this.MAX_INPUT_FILE_TO_SHOW),
            }
          : undefined,
        jobOrganizationID,
      ),
    ]).pipe(
      map(([folder, documents]) => {
        if (!folder && !documents) {
          return undefined;
        }
        return { folder, documents, totalDocumentCount: selection.ids.length };
      }),
    );
  }

  /**
   * Fetches the details (i.e. name) of the name scheme used in the job.
   * @param job The job
   * @returns Observable containing {@link JobDetailData} of the name scheme, or
   *          undefined if the job has no name scheme.
   */
  getNameSchemeDetails(job: Job): Observable<JobDetailData<NamedItem> | undefined> {
    const options = job.config.parameters?.options as any;
    const id: string =
      options?.optionValues?.fileNameSchemeID ??
      options?.antibodyAnnotatorOptions?.optionValues?.fileNameSchemeID;
    if (!id) {
      return of(undefined);
    }
    return this.store.select(selectNameSchemeByID(id)).pipe(
      switchMap((nameScheme) => {
        if (!nameScheme) {
          return this.fallbackToSnapshot((snapshot) => snapshot.nameScheme);
        }
        return of(this.resolvedItem({ id, name: nameScheme.name }));
      }),
      startWith(LOADING_ITEM),
      catchError(() => this.fallbackToSnapshot((snapshot) => snapshot.nameScheme)),
    );
  }

  /**
   * Fetches the details (i.e. name) of the Reference Sequences used in the job.
   * @param job The job
   * @returns Observable containing {@link JobDetailData} of the reference sequence,
   *          or undefined if none were used.
   */
  getReferenceSequenceDetails(job: Job): Observable<JobDetailData<NamedItem[]> | undefined> {
    const options = job.config.parameters?.options as any;
    const refDBsFromOptions =
      options?.optionValues?.database_customDatabase ??
      // optionValues.antibodyDatabase is only used by Single Cell Antibody Annotator
      options?.optionValues?.antibodyDatabase ??
      // antibodyAnnotatorOptions is only used by Extract & Recluster.
      options?.antibodyAnnotatorOptions?.optionValues?.database_customDatabase;
    const refDBs = Array.isArray(refDBsFromOptions)
      ? refDBsFromOptions
      : [refDBsFromOptions].filter((x) => !!x);
    const refDbDetailData = refDBs.map((refDB) => this.getFolderDetails(refDB));
    return forkJoin(refDbDetailData).pipe(
      tap((jobDetailDataList) =>
        jobDetailDataList.forEach((y) => {
          if (y.state !== ItemState.RESOLVED) {
            throw new Error('not resolved');
          }
        }),
      ),
      map((jobDetailDataList) => ({
        state: ItemState.RESOLVED,
        data: jobDetailDataList.map((jdd) => (jdd as JobDetailDataResolved<NamedItem>).data),
      })),
      catchError(() =>
        this.fallbackToSnapshot(
          (snapshot) => snapshot.referenceDatabases ?? [(snapshot as any).referenceDatabase],
        ),
      ),
    );
  }

  getPopOutParams(folderID: string, documentID: string): Params {
    return {
      selectAll: false,
      folderID: folderID,
      ids: [documentID],
      isAdminView: true,
    };
  }

  getExportResultDownloadLink(resultID: string) {
    return this.jobsService.buildJobResultDownloadLink(resultID, 'EXPORTED_FILE');
  }

  copyJobParams() {
    navigator.clipboard.writeText(JSON.stringify(this.job.config.parameters, null, 2));
  }

  openRunFromJson() {
    this.runFromJsonModalRef = this.pipelineDialogService.showDialog({
      component: RunFromJsonComponent,
      folderID: null,
      selected: null,
      otherVariables: {
        jobConfiguration: this.job.config,
      },
    });
  }
  /**
   * Convenience method to wrap an object in a JobDetailDataResolved object.
   * @param data object to wrap
   * @returns Resolved object
   */
  private resolvedItem<T>(data: T): JobDetailDataResolved<T> {
    return { data, state: ItemState.RESOLVED };
  }

  private fallbackToSnapshot<T>(
    pluckData: (snapshot: JobReportData) => T | undefined,
  ): Observable<JobDetailDataErrored | JobDetailDataSnapshot<T>> {
    return this.snapshot$.pipe(
      map((snapshot) => (snapshot ? pluckData(snapshot) : undefined)),
      map(this.markAsSnapshot),
    );
  }

  private markAsSnapshot<T>(data: T | undefined): JobDetailDataSnapshot<T> | JobDetailDataErrored {
    if (!data) {
      return ERRORED_ITEM;
    }
    return { data, state: ItemState.SNAPSHOT };
  }

  private jobIsComplete(statuses: JobStatus[]): boolean {
    if (statuses.length === 0) {
      return false;
    }
    const kind = statuses[0].kind;
    return kind === 'Completed' || kind === 'Cancelled' || kind === 'Failed';
  }

  /**
   * Converts a JobActivityEventKind enum into a JobStatusKind enum.
   * @param event JobActivityEventKind enum
   * @returns equivalent JobStatusKind value, or null if there is none
   */
  private activityKindToJobStatusKind(event: JobActivityEventKind): JobStatus['kind'] | null {
    switch (event) {
      case JobActivityEventKind.JOB_CANCELLED:
        return 'Cancelled';
      case JobActivityEventKind.JOB_COMPLETED:
        return 'Completed';
      case JobActivityEventKind.JOB_FAILED:
        return 'Failed';
      case JobActivityEventKind.JOB_STARTED:
      case JobActivityEventKind.JOB_PROGRESSED:
        return 'Running';
      case JobActivityEventKind.JOB_QUEUED:
        return 'Queued';
      default:
        return null;
    }
  }

  private isEqualStatus(s1: JobStatus, s2: JobStatus): boolean {
    return (
      s1.dateTime === s2.dateTime &&
      s1.kind === s2.kind &&
      s1.messages.join() === s2.messages.join()
    );
  }
}

/**
 * State of item that is being fetched from the server.
 */
enum ItemState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  RESOLVED = 'RESOLVED',
  SNAPSHOT = 'SNAPSHOT',
}

interface JobDetailDataLoading {
  state: ItemState.LOADING;
}
interface JobDetailDataErrored {
  state: ItemState.ERROR;
}
interface JobDetailDataResolved<T> {
  state: ItemState.RESOLVED;
  data: T;
}
interface JobDetailDataSnapshot<T> {
  state: ItemState.SNAPSHOT;
  data: T;
}

/**
 * JobDetailData normally consists of names and other data to display in the UI
 * that has to be fetched from the server. This type adds a `state` property to
 * the data so that the template knows whether to display a loading spinner,
 * error message, or the data. The union type ensures that the data is present
 * when the state is RESOLVED or SNAPSHOT.
 */
export type JobDetailData<T> =
  | JobDetailDataLoading
  | JobDetailDataErrored
  | JobDetailDataResolved<T>
  | JobDetailDataSnapshot<T>;

const LOADING_ITEM: JobDetailDataLoading = { state: ItemState.LOADING };
const ERRORED_ITEM: JobDetailDataErrored = { state: ItemState.ERROR };

interface NamedItem {
  id: string;
  name: string;
}

interface NamedDocument extends NamedItem {
  parentID?: string;
}

interface StatusUpdate {
  time: Date;
  messages: string;
  kind: string;
}

/** Combined object for input/results folder & documents */
interface JobSelection {
  folder?: JobDetailData<NamedItem>;
  documents?: JobDetailData<NamedDocument>[];
  totalDocumentCount?: number;
}

export interface JobReportInputDocuments {
  count: number;
  documents: NamedItem[];
  includesAll: boolean;
}

export interface JobReportData {
  user?: NamedItem;
  inputFolder?: NamedItem;
  inputDocuments?: JobReportInputDocuments;
  referenceDatabases?: NamedItem[];
  nameScheme?: NamedItem;
}

interface JobLog extends Pick<Log, 'loggedAt' | 'level'> {
  message: string;
}
