import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FolderKindsEnum } from '../folders/models/folderKinds';
import { Observable, of } from 'rxjs';
import { FolderService } from '../folders/folder.service';
import { DatabaseFolder, DatabaseRootFolder, FolderTreeItem } from '../folders/models/folder.model';
import { DatabaseTypeEnum } from './database-type';
import { DatabaseRootTypeEnum } from './database-root-type';
import { PermissionsService } from '../permissions/permissions.service';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import { selectReferenceDatabaseID } from '../auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class BlastService {
  constructor(
    private store: Store<AppState>,
    private folderService: FolderService,
    private permissionsService: PermissionsService,
  ) {}

  createBlast(
    folderID: string,
    name: string,
    databaseType: DatabaseTypeEnum,
  ): Observable<FolderTreeItem> {
    return this.folderService
      .create(folderID, name, FolderKindsEnum.DATABASE, { databaseType })
      .pipe(this.makeReadWriteIfMasterDatabaseType(databaseType, this.permissionsService));
  }

  updateDatabaseType(folder: DatabaseFolder, databaseType: DatabaseTypeEnum) {
    return this.folderService.updateMetadata(folder, { databaseType });
  }

  getAll(...databaseTypes: DatabaseTypeEnum[]): Observable<DatabaseFolder[]> {
    return this.store.pipe(
      select(selectReferenceDatabaseID),
      switchMap((referenceDatabaseID) =>
        this.folderService.getChildren<DatabaseRootFolder>(referenceDatabaseID),
      ),
      // Filtering empty database root folder list. This only happen when the folder store is empty. Since what we're
      // looking for is the Reference Database root, which get created when the org is created, we can safely assume
      // that when the database root folder list is empty, it's because the folder store is not yet populated.
      filter((references) => references.length > 0),
      switchMap((references) => {
        const found = references.find((db) =>
          db.databaseRootType
            ? db.databaseRootType === DatabaseRootTypeEnum.REFERENCE
            : db.kind === FolderKindsEnum.DATABASE_ROOT,
        );
        return this.folderService.getChildren<DatabaseFolder>(found.id);
      }),
      map((blastFolders: DatabaseFolder[]) =>
        blastFolders.filter(this.isDatabaseType(...databaseTypes)),
      ),
    );
  }

  /**
   * Determines if a Blast Tree Item based on the given database type.
   * DatabaseTypes that are UNCLASSIFIED or null/undefined will also return true regardless of the filterDatabaseType.
   * In the future once all customers have specified a database type for every database, then we can safely
   * modify this method to not return true for UNCLASSIFIED and null/undefined database types.
   */
  private isDatabaseType(
    ...databaseTypes: DatabaseTypeEnum[]
  ): (database: DatabaseFolder) => boolean {
    return function (database: DatabaseFolder): boolean {
      const databaseType = database.databaseType;
      return (
        databaseTypes.length === 0 ||
        databaseTypes.includes(databaseType) ||
        databaseType === DatabaseTypeEnum.UNCLASSIFIED ||
        databaseType == null
      );
    };
  }

  private makeReadWriteIfMasterDatabaseType(
    databaseType: DatabaseTypeEnum,
    permissionsService: PermissionsService,
  ) {
    return function (source: Observable<FolderTreeItem>): Observable<FolderTreeItem> {
      return source.pipe(
        switchMap((response) => {
          if (databaseType === DatabaseTypeEnum.MASTER) {
            return permissionsService
              .makeReadWriteForOrganisation(response.id)
              .pipe(mapTo(response));
          } else {
            return of(response);
          }
        }),
      );
    };
  }
}
