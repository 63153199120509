import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalOptions, NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { map, ReplaySubject, Subscription, switchMap } from 'rxjs';
import { CleanUp } from 'src/app/shared/cleanup';
import { StepperComponent } from 'src/app/shared/stepper/stepper.component';
import { NewMfaKeyKeyType, UnencryptedMfaKey } from '@geneious/nucleus-api-client';
import { UsersService } from '../../users/users.service';
import { catchError } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { StepperStepsComponent } from '../../../shared/stepper/stepper-steps/stepper-steps.component';
import { StepperComponent as StepperComponent_1 } from '../../../shared/stepper/stepper.component';
import { StepComponent } from '../../../shared/stepper/step/step.component';
import { VerifyMfaComponent } from '../verify-mfa/verify-mfa.component';
import { AsyncPipe } from '@angular/common';

export type CreateMfaDialogForm = CreateMfaDialogComponent['form'];
@Component({
  selector: 'bx-create-mfa-dialog',
  templateUrl: './create-mfa-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIconModule,
    StepperStepsComponent,
    StepperComponent_1,
    StepComponent,
    NgbAlert,
    VerifyMfaComponent,
    AsyncPipe,
  ],
})
export class CreateMfaDialogComponent extends CleanUp implements OnDestroy {
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent;
  newMfaKey$ = new ReplaySubject<UnencryptedMfaKey>(1);
  error$ = new ReplaySubject<string>(1);
  readonly form = new FormGroup({
    keyCode: new FormControl<string>(undefined),
    mfaCode: new FormControl<number>(undefined, Validators.required),
  });
  readonly stepControls = [this.form] as const;
  private subscriptions = new Subscription();

  public static readonly MODAL_OPTIONS: NgbModalOptions = { size: 'lg', backdrop: 'static' };
  constructor(
    private readonly modal: NgbActiveModal,
    private usersService: UsersService,
  ) {
    super();
  }

  verifyMfaKey() {
    this.subscriptions.add(
      this.newMfaKey$
        .pipe(
          map((mfa) => mfa.id),
          switchMap((keyID) => {
            return this.usersService.verifyMfa(keyID, this.form.controls.mfaCode.value);
          }),
          catchError((errorResponse) => {
            if (errorResponse.error.error.status === 400) {
              this.error$.next(errorResponse.error.error.message);
            } else {
              this.error$.next(
                `Contact support if this error continues <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">https://help.geneiousbiologics.com/hc/en-us/requests/new</a>`,
              );
            }
            return errorResponse;
          }),
        )
        .subscribe((response) => {
          this.close('completed');
        }),
    );
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
    this.newMfaKey$.complete();
    this.error$.complete();
  }

  close(reason: 'canceled' | 'completed') {
    if (reason === 'canceled') {
      this.modal.dismiss(reason);
    } else {
      this.modal.close();
    }
  }

  createMFA() {
    this.usersService
      .createMfaKey({ keyType: NewMfaKeyKeyType.SoftwareTotp })
      .pipe(map((newMfa) => newMfa.data))
      .subscribe((mfa) => {
        this.newMfaKey$.next(mfa);
        this.stepper.next();
      });
  }
}
