<div class="modal-header">
  <h4 class="modal-title d-flex align-items-center flex-nowrap text-nowrap">
    {{ componentRef?.instance?.title }}
    @if (componentRef?.instance?.knowledgeBaseArticle) {
      <a
        [href]="componentRef?.instance?.knowledgeBaseArticle"
        target="_blank"
        rel="noopener noreferrer"
        class="d-flex align-items-center ms-2 text-decoration-none"
      >
        <mat-icon>info</mat-icon>
      </a>
    }
    @if (componentRef?.instance?.earlyRelease) {
      <span class="badge bg-brand ms-2"> Early Release </span>
    }
  </h4>
  @if (isDevOrRunFromJsonEnabled$ | async) {
    <bx-pipeline-version-selector
      class="ms-3 align-self-center"
      title="Pipeline ID"
      [nucleusPipelineID]="componentRef.instance.nucleusPipelineID"
      [pipelineFormID]="componentRef.instance.pipelineFormID"
      [originalPipelineID]="originalPipelineID"
      (pipelineVersionChanged)="pipelineVersionSelection$.next($event)"
    ></bx-pipeline-version-selector>
  }
  @if (isDevOrRunFromJsonEnabled$ | async) {
    <button
      class="btn align-self-center"
      title="Copy Job Parameters"
      (click)="copyJobParameters()"
      data-spec="copy-job-params"
    >
      <fa-icon [icon]="faCopy"></fa-icon>
    </button>
  }
  <button
    type="button"
    class="btn-close pull-right"
    aria-label="Close"
    (click)="activeModal.dismiss()"
  ></button>
</div>

@if (stepper) {
  <bx-stepper-steps
    [steps]="steps$ | async"
    [selectedIndex]="stepper.selectedIndex"
    [maxNavigableIndex]="stepper.maxNavigableIndex | async"
    color="secondary"
    class="mt-3"
  >
  </bx-stepper-steps>
}

<div class="modal-body">
  <span #target></span>
</div>
@if ({ submitting: submitting$ | async, disabled: disabled$ | async }; as formState) {
  <div class="modal-footer flex-nowrap">
    @if (showProfileButtons) {
      <bx-profile-buttons
        [showResetToDefaultOption]="true"
        [identifier]="pipelineFormID"
        [feature]="ProfileFeature.PIPELINE_OPTIONS"
        [data]="formState$ | async"
        [allowCreateSharedProfile]="true"
        (resetToDefault)="resetToDefaults()"
        (profileApplied)="applyProfile($event)"
      ></bx-profile-buttons>
    }
    <div class="text-danger text-truncate flex-grow-1">
      @if (!!error) {
        <small>
          {{ error }}
          <a href="https://help.geneiousbiologics.com/hc/en-us/requests/new">Contact support</a>
        </small>
      }
    </div>
    <button class="btn btn-danger" *bxShowIf="'disableAllPipelines'" disabled="disabled">
      Readonly mode
    </button>
    @if (!stepper) {
      <button
        class="btn btn-lg btn-primary"
        [ngClass]="{ 'run-button': formState.submitting }"
        [disabled]="formState.disabled || (formValid$ | async) === false"
        (click)="runPipeline()"
        bxDisableIf="disableAllPipelines"
        data-testid="pipeline-dialog-run"
      >
        Run
      </button>
      <button
        class="btn btn-lg btn-secondary"
        [disabled]="formState.disabled"
        (click)="activeModal.dismiss()"
      >
        Close
      </button>
    } @else {
      @if ((stepper.isFirstStep | async) === false) {
        <button
          type="button"
          class="btn btn-lg btn-outline-primary"
          (click)="previousStep(stepper, stepper.selectedIndex)"
          bxDisableIf="disableAllPipelines"
        >
          Previous
        </button>
      }
      @if ((stepper.isLastStep | async) === false) {
        <button
          type="button"
          class="btn btn-lg btn-primary"
          (click)="nextStep(stepper, stepper.selectedIndex)"
          [disabled]="formState.disabled || (stepperValid$ | async) === false"
          bxDisableIf="disableAllPipelines"
        >
          Next
        </button>
      }
      <!--
      The Stepper's Run button doesn't need to be disabled if the form is invalid as the form has to be valid
      in order for the user to step through each step to get to the submission step anyway.
      NOTE: The form is invalid when steps are optional. This is annoying and not ideal but at least the stepper
      component respects the fact that the step is optional and thus can ignore the form status of that step.
      -->
      @if ((stepper.isLastStep | async) === true) {
        <button
          class="btn btn-lg btn-primary"
          [ngClass]="{ 'run-button': formState.submitting }"
          [disabled]="formState.disabled || (stepperValid$ | async) === false"
          (click)="runPipeline()"
        >
          Run
        </button>
      }
    }
  </div>
}
