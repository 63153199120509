import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'bx-toolstrip',
  templateUrl: './toolstrip.component.html',
  standalone: true,
})
export class ToolstripComponent {
  @HostBinding('class') readonly hostClass = [
    'd-flex',
    'flex-wrap',
    'align-items-center',
    'w-100',
    'p-1',
    'border-bottom',
  ];
}
