import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorFormatter {
  constructor() {}

  toUserReadableError(error: HttpErrorResponse, onServerError: string): string {
    switch (error.status) {
      case 500:
        return onServerError;
      default:
        return error.error.error.message;
    }
  }
}
