import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CleanUp } from '../../shared/cleanup';
import { PipelineFormControlValidatorsService } from '../pipeline-dialogs/pipeline-form-control-validators.service';
import { NucleusPipelineID, PipelineFormID } from '../pipeline/pipeline-constants';

export abstract class JobDialogContent extends CleanUp {
  /** Used as the job dialog title */
  abstract title: string;
  /** The form to bind to the template */
  abstract form: UntypedFormGroup;
  /** If this is an alpha feature so customers know it's not ready. */
  abstract earlyRelease?: boolean;
  /** A URL for a relevant knowledge base article */
  abstract knowledgeBaseArticle?: string;
  /** Used to determine whether job option profile buttons should be shown. */
  readonly showProfileButtons: boolean = true;

  protected constructor(
    /** The pipeline ID recognized by Nucleus */
    readonly nucleusPipelineID: NucleusPipelineID,
    /** Used for a key in the formState slice in the store, and also for validation purposes */
    readonly pipelineFormID: PipelineFormID,
  ) {
    super();
  }

  /**
   * Provided FormControl for resultName control that most Pipelines should have.
   * The resultName FormControl provides a means for the user to optionally specify a custom file
   * name for their resulting document.
   *
   * Result rules:
   * - Can only be a maximum of 255 characters.
   * - Must not be a string that is only '..'
   * - Must not be a string that is only '.'
   * - Must not contain any characters of '/' or '\'
   */
  static getResultNameControl(): FormControl<string> {
    return new FormControl<string>(undefined, [
      Validators.maxLength(255),
      PipelineFormControlValidatorsService.notPattern(/^\.\.$/),
      PipelineFormControlValidatorsService.notPattern(/^\.$/),
      PipelineFormControlValidatorsService.notPattern(/[\/\\]+/),
    ]);
  }

  /**
   * Implement to return the initial form state. This will be used to reset the form to
   * its initial state. Most cases, using form.getRawValue() will suffice.
   * @returns {any}
   */
  abstract getFormDefaults(): any;
}
