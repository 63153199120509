import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NucleusDataResponse } from '../nucleus.model';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '../../app/app.config';

@Injectable({
  providedIn: 'root',
})
export class GroupsHttpV2Service {
  private readonly endpoint: string;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
  ) {
    this.endpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/groups`;
  }

  getGroups(userID?: string): Observable<UserGroup[]> {
    const endpoint = userID ? `${this.endpoint}?userID=${userID}` : this.endpoint;
    return this.http
      .get<NucleusDataResponse<UserGroup[]>>(endpoint)
      .pipe(map((response) => response.data));
  }

  getGroup(id: string): Observable<UserGroup> {
    return this.http
      .get<NucleusDataResponse<UserGroup>>(`${this.endpoint}/${id}`)
      .pipe(map((response) => response.data));
  }

  createGroup(group: NewUserGroup): Observable<UserGroup> {
    return this.http
      .post<NucleusDataResponse<UserGroup>>(`${this.endpoint}`, group)
      .pipe(map((response) => response.data));
  }

  updateGroup(group: UpdateUserGroup): Observable<UserGroup> {
    return this.http
      .put<NucleusDataResponse<UserGroup>>(`${this.endpoint}/${group.id}`, group)
      .pipe(map((response) => response.data));
  }

  deleteGroup(id: string): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/${id}`);
  }
}

export interface NewUserGroup {
  organizationID: string;
  name: string;
  description?: string;
  members?: string[];
}

export interface UpdateUserGroup {
  id: string;
  name: string;
  description?: string;
  members?: string[];
}

export interface UserGroup {
  id: string;
  name: string;
  description?: string;
  members?: UserGroupMember[];
}

export interface UserGroupMember {
  id: string;
  email: string;
  givenName: string;
  familyName?: string;
}
