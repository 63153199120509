import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../shared/util.service';

export type LinkParamsGetter = (cell: ICellRendererParams) => { text: string; href?: string };

@Component({
  selector: 'bx-link-renderer',
  templateUrl: 'link-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class LinkRendererComponent implements ICellRendererAngularComp {
  href?: string;
  text?: string;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  /**
   * Enables us to:
   *  - provide conventional links so users can right click and open in new window
   *  - capture links and redirect them through angular to prevent a full page reload.
   */
  goToLink(e: Event) {
    // zone.run required to execute javascript outside the angular change detection system
    // Otherwise the current route and the new route are displayed onscreen at the same time
    // which is a bad user experience.
    this.zone.run(() => {
      e.preventDefault();
      e.stopPropagation();
      this.navigateViaURL(this.href);
    });
  }

  agInit(params: ICellRendererParams & { paramsGetter: LinkParamsGetter }): void {
    const data = params.paramsGetter(params);

    if (data) {
      this.text = data.text;
      this.href = data.href;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  private navigateViaURL(url: string) {
    const baseRoute = url.split('?')[0];
    const queryParams = UtilService.getQueryParams(url);
    this.router.navigate([baseRoute], {
      queryParams,
      replaceUrl: false,
      relativeTo: this.route,
    });
  }
}
