// @ts-ignore
import { GeneticCodes } from '@geneious/shared-constants';
import { TranslationTable } from '@geneious/shared-constants/types';

export interface GeneticCode {
  id: string;
  name: string;
}

export const geneticCodes: GeneticCode[] = Object.entries(GeneticCodes).map(([id, table]) => {
  const name = (table as TranslationTable).name;
  return {
    // TODO Use an *immutable* identifier. This affects several pipelines.
    id: name,
    name: `${name} (${id})`,
  };
});

export const geneticCodesMapToGeneiousPrimeName: GeneticCode[] = Object.entries(GeneticCodes).map(
  ([id, table]) => {
    const name = (table as TranslationTable).name;
    const geneiousPrimeID = (table as TranslationTable as any).geneiousPrimeID;
    return {
      // TODO Use an *immutable* identifier. This affects several pipelines.
      id: geneiousPrimeID,
      name: `${name} (${id})`,
    };
  },
);
