@if (!loading) {
  <div class="monospace-box overflow-auto bg-light p-3">
    <pre
      class="m-0 p-0"
      data-spec="monospace-box-content"
    ><code><ng-content></ng-content></code></pre>
  </div>
} @else {
  <div
    class="w-100 h-100 d-flex align-items-center justify-content-center"
    data-spec="monospace-box-loading"
  >
    <bx-spinner></bx-spinner>
  </div>
}
