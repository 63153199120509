/**
 * Returns the full name of a User-like object with a givenName and an optional
 * familyName property.
 *
 * @param user User object
 * @returns The user's full name
 */
export function fullName(user: { givenName: string; familyName?: string }): string {
  if (user.familyName) {
    return `${user.givenName} ${user.familyName}`;
  }
  return user.givenName;
}
