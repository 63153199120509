import { filter, map, mergeMap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FolderService } from '../folders/folder.service';
import { PrimerSetsFolder, PrimersRootFolder } from '../folders/models/folder.model';
import { FolderKindsEnum } from '../folders/models/folderKinds';
import { FolderHttpV2Service } from '../../../nucleus/v2/folder-http.v2.service';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { createSelector, Store } from '@ngrx/store';
import { AppState } from '../core.store';
import { selectFolders } from '../folders/store/folder.selectors';

@Injectable({
  providedIn: 'root',
})
export class PrimersService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private folderService: FolderService,
    private folderV2Service: FolderHttpV2Service,
    private store: Store<AppState>,
  ) {}

  createSet(parentID: string, name: string, primerIDs: string[]): Observable<any> {
    return this.folderService.create(parentID, name, FolderKindsEnum.PRIMER_SET).pipe(
      mergeMap((folderResp: any) => {
        return this.addPrimersToSet(folderResp.id, primerIDs);
      }),
    );
  }

  deletePrimers(parentID: string, primerIDs: string[], selectAllState: boolean): Observable<any> {
    return this.folderV2Service.deleteChildren(parentID, selectAllState, primerIDs);
  }

  removePrimersFromSet(setID: string, selectAll: boolean, primerIDs: string[]): Observable<any> {
    const endpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/folders/${setID}/children`;
    const params: any = {
      ids: primerIDs,
      selectAll: selectAll,
    };

    return this.http.request('DELETE', endpoint, { body: params });
  }

  getPrimersRoot(): Observable<PrimersRootFolder> {
    return this.store.select(selectPrimersRoot).pipe(
      filter((filtered: PrimersRootFolder[]) => !!filtered.length),
      map((filtered) => filtered[0]),
    );
  }

  getPrimerSets(): Observable<PrimerSetsFolder[]> {
    return this.getPrimersRoot().pipe(
      mergeMap((root) => this.folderService.getChildren<PrimerSetsFolder>(root.id)),
    );
  }

  private addPrimersToSet(folderID: string, primerIDs: string[]): Observable<any> {
    const endpoint = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/folders/${folderID}/children`;
    return this.http.patch(endpoint, { documentIDs: primerIDs }, { responseType: 'text' });
  }
}

export const selectPrimersRoot = createSelector(selectFolders, (folders) =>
  Object.keys(folders)
    .filter((id) => folders[id].kind === FolderKindsEnum.PRIMERS)
    .map((key) => folders[key]),
);
