@if (validating$ | async) {
  <div class="select-overlay bg-white opacity-50">
    <bx-spinner [small]="true"></bx-spinner>
  </div>
}
@if (loading$ | async) {
  <div class="select-overlay text-muted">
    <bx-spinner [small]="true" class="me-1"></bx-spinner><small>Loading...</small>
  </div>
}
@if (showEmptyMessage$ | async) {
  <div class="select-overlay">
    @if (emptyMessage) {
      <small class="text-muted">{{ emptyMessage }}</small>
    }
    <div class="custom-empty-message">
      <ng-content select="[bxCustomEmptyMessage]"></ng-content>
    </div>
  </div>
}

<select
  class="form-select overflow-auto"
  [formControl]="control"
  [multiple]="showMultiple"
  [ngClass]="{
    'form-control-sm': smallSize,
    'default-h-100': resizable && showMultiple,
    'h-100': !resizable && showMultiple,
  }"
  [attr.size]="showMultiple ? size : null"
  ngFormControlValidator
  [enableIsValidClass]="showIsValidOutline"
  [canMarkPristineInvalid]="canMarkPristineInvalid"
>
  @for (group of selectGroupOptions; track group; let i = $index) {
    <ng-container *ngTemplateOutlet="options; context: { $implicit: group }"></ng-container>
    @if (i < selectOptions.length - 1) {
      <option disabled>--------------------------------------</option>
    }
  }

  <ng-container
    *ngTemplateOutlet="options; context: { $implicit: selectFlatOptions }"
  ></ng-container>

  @for (group of selectGroups; track group.label) {
    @if (group.label) {
      <optgroup [label]="group.label">
        <ng-container
          *ngTemplateOutlet="options; context: { $implicit: group.options }"
        ></ng-container>
      </optgroup>
    }
    @if (!group.label) {
      <ng-container
        *ngTemplateOutlet="options; context: { $implicit: group.options }"
      ></ng-container>
    }
  }

  <ng-template #options let-options>
    @for (option of options; track option) {
      <option [value]="option.value">
        {{ option.displayName }}
      </option>
    }
  </ng-template>
</select>
@if (showFormErrors) {
  <bx-form-errors [control]="control" data-spec="select-errors"></bx-form-errors>
}
<ng-content select="[bxCustomFormErrors]"></ng-content>

@if (showSelectionSummary) {
  @if (selectedCount$ | async; as selectedCount) {
    <span class="d-block text-muted mt-1 small" data-spec="selected-counter">
      {{ selectedCount }} {{ entityName ? entityName : 'item'
      }}{{ selectedCount > 1 ? 's' : '' }} selected
    </span>
  }
}
