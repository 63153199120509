import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IChildrenOptions } from '../../../nucleus/v2/folder-http.v2.service';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FolderService } from '../folders/folder.service';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { TreeBuilderAlgorithms } from '../../../nucleus/services/models/alignmentOptions.model';
import { PipelineService } from '../pipeline/pipeline.service';
import { DatabaseTypeEnum } from '../blast/database-type';
import { flatten } from '../../../bx-common-extensions/array';

function isEmpty(value: string | null | undefined): boolean {
  return value == null || value.length === 0;
}

@Injectable({
  providedIn: 'root',
})
export class PipelineFormControlValidatorsService {
  constructor(
    private folderService: FolderService,
    private pipelineService: PipelineService,
  ) {}

  databaseValidator(
    allowNoDatabaseSelected = false,
  ): (
    control: AbstractControl,
  ) => Observable<
    null | { noSequences: string } | { incompatibleTypes: string } | { noDatabaseSelected: string }
  > {
    const service = this.folderService;
    const pipelineService = this.pipelineService;
    return function validator(control: AbstractControl) {
      const valueList = !Array.isArray(control.value) ? [control.value] : control.value;

      const selectedDatabases = valueList.filter((folderID) => !!folderID);

      // Note: Empty list of selected databases can either mean no database was selected or
      // the option of "no reference database" (supported in some annotator dialogs) is selected.
      if (selectedDatabases.length === 0) {
        if (allowNoDatabaseSelected) {
          return of(null);
        }

        return of({
          noDatabaseSelected: 'no database selected',
        });
      }

      const paramsList: IChildrenOptions[] = valueList.map((folderID: string) => ({
        folderID,
        pageLimit: 1,
        pageOffset: 0,
        maxDepth: 1,
      }));
      if (paramsList)
        return combineLatest([
          pipelineService.getDatabases(DatabaseTypeEnum.GERMLINE).pipe(
            map(flatten),
            map((dbs) => dbs.map((db) => db.value)),
          ),
          pipelineService.getDatabases(DatabaseTypeEnum.TEMPLATE).pipe(
            map(flatten),
            map((dbs) => dbs.map((db) => db.value)),
          ),
          of(paramsList),
          forkJoin(
            paramsList.map((params) =>
              service.getFilteredChildrenEntities(params).pipe(
                map(({ data }) => {
                  if (data.length === 0) {
                    return { noSequences: 'Some selected databases do not contain any sequences' };
                  }
                  return null;
                }),
                catchError(() => of(null)),
              ),
            ),
          ),
        ]).pipe(
          map(([germ, temp, itemList, results]) => {
            let hasGerm = false;
            let hasTemp = false;
            for (let { folderID } of itemList) {
              if (germ.includes(folderID) && !temp.includes(folderID)) {
                hasGerm = true;
              }
              if (temp.includes(folderID) && !germ.includes(folderID)) {
                hasTemp = true;
              }
              if (hasGerm && hasTemp) {
                return [
                  {
                    incompatibleTypes:
                      'Selected list includes both germline and template databases – only one type must be selected',
                  },
                ];
              }
            }
            return results;
          }),
          map((results: any[]) => results.find((result) => result !== null) ?? null),
        );
    };
  }

  /**
   * A a negation of the Validators.pattern validator.
   * @param pattern - a string or regex pattern for the control value string not to match to be
   *     valid.
   */
  static notPattern(pattern: string | RegExp): ValidatorFn {
    return function validator(control: AbstractControl) {
      const result = Validators.pattern(pattern)(control);
      const noMatch = result != null;
      return isEmpty(control.value) || noMatch
        ? null
        : { pattern: { notRequiredPattern: pattern.toString(), actualValue: control.value } };
    };
  }

  /**
   * Given a formGroup, validate that at least one of the controls inside has a non-empty value.
   */
  static someRequired(): ValidatorFn {
    return function validator(group: AbstractControl) {
      if (!(group instanceof FormGroup)) {
        throw new Error('Validator function only supported on Form Groups');
      }

      const valid = Object.values(group.controls).some((control) => {
        const controlValue = control.value;
        return (
          controlValue !== null &&
          controlValue.toString().trim() !== '' &&
          !(Array.isArray(controlValue) && controlValue.length === 0)
        );
      });

      // Return null is valid, anything else is not valid.
      return valid ? null : { novalue: 'allempty' };
    };
  }

  static treeAlgorithmValid(numberOfSequences: number): ValidatorFn {
    return function validator(control: AbstractControl) {
      if (control.value === TreeBuilderAlgorithms.RAXML && numberOfSequences === 3) {
        return { raxmlInvalid: 'The RAxML algorithm cannot operate on 3 sequences.' };
      }

      // Return null is valid, anything else is not valid.
      return null;
    };
  }
}
