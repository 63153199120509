import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreadcrumbComponent, Crumb } from './breadcrumb.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'bx-settings-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  standalone: true,
  imports: [NgIf, NgFor, NgClass, RouterLink, AsyncPipe],
})
export class SettingsBreadcrumbComponent extends BreadcrumbComponent implements OnChanges {
  @Input() crumbs: Crumb[];

  ngOnChanges({ crumbs }: SimpleChanges) {
    this.crumbs$.next(crumbs.currentValue);
  }
}
