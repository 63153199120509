@if (
  {
    loading: loading$ | async,
    warningInsights: warningInsights$ | async,
    infoInsights: infoInsights$ | async,
    insightsToDisplay: insightsToDisplay$ | async,
  };
  as state
) {
  @if (state.loading === false) {
    <div>
      @if (state.insightsToDisplay.warning.length > 0) {
        <bx-job-insights-alert
          [insights]="state.insightsToDisplay.warning"
          [truncated]="!!insightDisplayLimit"
          level="warning"
        ></bx-job-insights-alert>
      }
      @if (state.insightsToDisplay.info.length > 0) {
        <bx-job-insights-alert
          [insights]="state.insightsToDisplay.info"
          [truncated]="!!insightDisplayLimit"
          level="info"
        ></bx-job-insights-alert>
      }
      @if (state.insightsToDisplay.warning.length + state.insightsToDisplay.info.length === 0) {
        <div data-spec="no-insights">
          <span class="text-muted">No insights reported</span>
        </div>
      }
    </div>
  } @else {
    <bx-spinner data-spec="insights-loading"></bx-spinner>
  }
}

<ng-template #loading>
  <bx-spinner data-spec="insights-loading"></bx-spinner>
</ng-template>
