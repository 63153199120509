import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessCondition } from '../../access-check-condition.model';
import { AccessCheckService } from '../../access-check.service';

/**
 * A structural directive which shows the component it is attached to if the
 * logged-in user meets the supplied AccessCondition.
 *
 * IMPORTANT: Unlike *ngIf, the AccessCondition is only evaluated once in the
 * lifetime of the component. We assume that feature access and organization
 * profiles will only change between user sessions.
 */
@Directive({
  selector: '[bxShowIf]',
  standalone: true,
})
export class ShowIfDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accessCheckService: AccessCheckService,
  ) {}

  @Input() set bxShowIf(condition: AccessCondition) {
    this.accessCheckService.hasAccessOnce(condition).subscribe((hasAccess) => {
      if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
