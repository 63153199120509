import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToTitleCase',
  standalone: true,
})
export class CamelCaseToTitleCasePipe implements PipeTransform {
  transform(rawValue: any): string {
    // Make sure the value is always a string
    const value = typeof rawValue === 'string' ? rawValue : rawValue + '';
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
