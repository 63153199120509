import { FormState } from './form-state.model';
import { createAction, props } from '@ngrx/store';
import { PipelineFormID } from '../../pipeline/pipeline-constants';

export const fetchAllFormState = createAction('[Form State] Fetch All Form State');

export const setFormStates = createAction(
  '[Form State] Set Form States',
  props<{
    formStates: Partial<Record<PipelineFormID, FormState>>;
  }>(),
);

export const updateFormState = createAction(
  '[Form State] Update',
  props<{
    name: PipelineFormID;
    formState: FormState;
  }>(),
);
