import { Component, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import * as UserEditor from './user-editor.reducer';
import { CleanUp } from '../../shared/cleanup';
import { UsersService } from '../users/users.service';
import { NgForm, ValidationErrors, FormsModule } from '@angular/forms';
import { first, map, pluck, share, shareReplay, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { UserGroupsFacade } from '../user-groups/user-groups.facade';
import { UserGroup } from '../../../nucleus/v2/groups-http.v2.service';
import { BehaviorSubject, Observable, of, take } from 'rxjs';
import { Crumb } from '../../shared/breadcrumb/breadcrumb.component';
import { ApiKeysService } from '../api-keys/api-keys.service';
import { UserDetailsStatus } from '@geneious/nucleus-api-client/model/user-details';
import { AuthService, NewUser, OrganizationManagementService } from '@geneious/nucleus-api-client';
import User from './user.model';
import { select, Store } from '@ngrx/store';
import { selectOrganizationID } from '../auth/auth.selectors';
import { AppState } from '../core.store';
import { SettingsBreadcrumbComponent } from '../../shared/breadcrumb/settings-breadcrumb.component';
import { NgFormControlValidatorDirective } from '../../shared/form-helpers/ng-form-control-validator.directive';
import { AsyncPipe } from '@angular/common';
import { SpinnerButtonComponent } from '../../shared/spinner-button/spinner-button.component';
import { ApiKeysTableComponent } from '../api-keys/api-keys-table/api-keys-table.component';
import { MfaKeysTableComponent } from '../mfa/mfa-keys-table/mfa-keys-table.component';
import { CamelCaseToTitleCasePipe } from '../../shared/camelCaseToTitleCase.pipe';
import { toSignal } from '@angular/core/rxjs-interop';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { isBXOrg } from '../utils/organization-utils';

@Component({
  selector: 'bx-user-editor',
  templateUrl: './user-editor.component.html',
  standalone: true,
  imports: [
    SettingsBreadcrumbComponent,
    FormsModule,
    NgFormControlValidatorDirective,
    SpinnerButtonComponent,
    ApiKeysTableComponent,
    MfaKeysTableComponent,
    AsyncPipe,
    CamelCaseToTitleCasePipe,
    RouterLink,
  ],
})
export class UserEditorComponent extends CleanUp implements OnInit, OnDestroy {
  crumbs: Crumb[];
  user: User;
  groups$: Observable<UserGroup[]>;
  processingRequest = false;

  userID: string;
  organizationID: string;

  userAccountStatus$: Observable<UserDetailsStatus>;

  errors: string[] = [];
  isCreating = true;
  isApiKeysEnabled$: Observable<boolean>;

  sendingActivationEmail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activationEmailSent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  currentUser$: Observable<User>;
  readonly isEmailChangingEnabled = toSignal(
    this.featureSwitchService.isEnabledOnce('changeEmail'),
  );
  readonly isBXOrg = signal(false);

  @ViewChild(NgForm, { static: true }) usersForm: NgForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private store: Store<AppState>,
    private apiKeysService: ApiKeysService,
    private authService: AuthService,
    private userGroupsFacade: UserGroupsFacade,
    private featureSwitchService: FeatureSwitchService,
    private organizationManagementService: OrganizationManagementService,
  ) {
    super();
  }

  ngOnDestroy() {
    this.activationEmailSent$.complete();
    this.sendingActivationEmail$.complete();
  }

  ngOnInit() {
    let params = this.route.snapshot.params;
    this.organizationID = params.orgID;
    this.userID = params.userID;
    this.isCreating = !this.userID || this.userID === 'new';

    this.groups$ = this.isCreating ? of([]) : this.userGroupsFacade.getGroups(this.userID);

    this.store.pipe(select(selectOrganizationID), first()).subscribe((organizationID) => {
      // If no org ID was specified in the URL parameters, use the current logged in org.
      if (!this.organizationID) {
        this.organizationID = organizationID;
      }

      this.organizationManagementService
        .getOrganization(this.organizationID)
        .pipe(pluck('data'), take(1))
        .subscribe((organization) => {
          this.isBXOrg.set(isBXOrg(organization));
        });

      this.setCrumbs('', !!params.orgID);
      // This if/else if block is here so that the button displayed on the page is correct
      if (this.isCreating) {
        this.user = <User>{ organizationId: this.organizationID };
        this.usersService.updateUser(this.user); // wipe any previous user state if creating a new one
      } else {
        this.currentUser$ = this.usersService
          .getUser(this.userID)
          .pipe(shareReplay({ bufferSize: 1, refCount: true }));

        this.userAccountStatus$ = this.currentUser$.pipe(
          map((user) => user.status),
          share(),
        );
        this.currentUser$.subscribe((user: User) => {
          this.user = user;
          this.usersService.updateUser(this.user); // save the fetched user in the store so the fields can be filled out
        });
      }

      // I'm using the service so that all the store logic is contained in there and this component doesn't know what form
      // of data store is being used
      this.usersService
        .getDataStore()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((state: UserEditor.State) => {
          this.user = new User();
          this.user.id = state.id;
          this.user.organizationId = this.organizationID;
          this.user.email = state.email;
          this.user.givenName = state.givenName;
          this.user.familyName = state.familyName;
          this.user.isAdmin = state.isAdmin;
        });

      this.usersForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
        const finalLabel = this.isCreating ? 'New' : `${user.givenName} ${user.familyName ?? ''}`;
        const isGeneiousAdmin = !!params.orgID; // Only the geneious admin page passes the orgID via the parameters.
        this.setCrumbs(finalLabel, isGeneiousAdmin);
      });
    });

    this.isApiKeysEnabled$ = this.apiKeysService.isApiKeysEnabled();
  }

  private setCrumbs(lastLinkLabel: string, isGeneiousAdmin: boolean) {
    this.crumbs = isGeneiousAdmin
      ? [
          { link: ['', 'geneious-admin'], label: 'Geneious Admin' },
          {
            link: ['', 'geneious-admin', 'organizations'],
            label: 'Organizations',
          },
          {
            link: ['', 'geneious-admin', 'organizations', this.organizationID, 'users'],
            label: `${this.organizationID}/users`,
          },
          {
            link: [
              '',
              'geneious-admin',
              'organizations',
              this.organizationID,
              'users',
              this.userID,
            ],
            label: lastLinkLabel,
          },
        ]
      : [
          { link: ['', 'users'], label: 'Users' },
          { link: ['', 'users', this.userID], label: lastLinkLabel },
        ];
  }

  submit(usersForm: NgForm) {
    // todo clear the form errors somewhere
    const formErrors: ValidationErrors = usersForm.errors ? usersForm.errors : [];
    this.errors = Object.keys(formErrors).map((key) => formErrors[key]);
    if (usersForm.valid) {
      if (this.isCreating) {
        this.createUser();
      } else {
        this.saveChanges();
      }
    }
  }

  createUser() {
    this.processingRequest = true;
    this.errors = [];
    const newUser: NewUser = {
      organizationID: this.user.organizationId,
      familyName: this.user.familyName,
      givenName: this.user.givenName,
      email: this.user.email,
      roles: this.user.toServerModel().roles,
    };
    this.usersService.createUser(newUser).subscribe({
      next: () => {
        this.router.navigate(['../'], { relativeTo: this.route });
        this.usersService.resetUser();
        this.processingRequest = false;
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.errors.push('That email address has already been taken.');
        } else if (error.error.error.code === 'OrganizationUsersLimitExceeded') {
          this.errors.push('Limit of number of users exceeded.');
        } else {
          this.errors.push('User creation failed');
        }
        this.processingRequest = false;
      },
    });
  }

  saveChanges() {
    this.processingRequest = true;
    this.errors = [];
    this.usersService.editUser(this.user).subscribe(
      () => {
        this.router.navigate(['../'], { relativeTo: this.route });
        this.usersService.resetUser();
        this.processingRequest = false;
      },
      () => {
        this.errors.push('Failed to save changes to user');
        this.processingRequest = false;
      },
    );
  }

  onCancel() {
    this.processingRequest = false;
    this.usersService.cancelCreation();
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  sendActivationEmail() {
    this.sendingActivationEmail$.next(true);
    this.authService
      .createEmailToken({ userID: this.userID, newEmailAddress: this.user.email })
      .subscribe(() => {
        this.sendingActivationEmail$.next(false);
        this.activationEmailSent$.next(true);
      });
  }
}
