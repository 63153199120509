import { JobResult } from '@geneious/nucleus-api-client';
import { createAction, props, union } from '@ngrx/store';

export const fetchJobResults = createAction(
  '[Jobs] Fetch Job Results',
  props<{ payload: { id: string; organizationID: string } }>(),
);

export const fetchJobResultsSuccess = createAction(
  '[Jobs] Fetch Job Results Success',
  props<{ payload: { id: string; jobResults: JobResult[] } }>(),
);

export const fetchJobResultsFailure = createAction(
  '[Jobs] Fetch Job Results Failure',
  props<{ payload: { id: string } }>(),
);

const actions = union({
  fetchJobResults,
  fetchJobResultsSuccess,
  fetchJobResultsFailure,
});

export type ActionsUnion = typeof actions;
