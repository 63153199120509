import { Action } from '@ngrx/store';
import { EditorState } from './editor-state.enum';
import User from './user.model';

export const ACTION_PREFIX = '[user-editor]';
export const SET_EDITOR_STATE = ACTION_PREFIX + ' SET EDITOR STATE';
export const DISABLE_USER = ACTION_PREFIX + ' DISABLE USER';
export const UPDATE_USER = ACTION_PREFIX + ' UPDATE USER';
export const FORGET_USER = ACTION_PREFIX + ' CLEAR USER DETAILS';
export const USER_DISABLED = ACTION_PREFIX + ' USER DISABLED';
export const CANCEL = ACTION_PREFIX + ' CANCEL USER CREATION';
export const HANDLE_ERROR = ACTION_PREFIX + 'HANDLE ERROR';

export class SetEditorState implements Action {
  readonly type = SET_EDITOR_STATE;
  // payload is the state it which the editor is in
  // e.g.,
  //  - if creating a user then it would be CREATING
  //  - if editing a user then it would be EDITING
  // etc.
  constructor(public payload: EditorState) {}
}

export class DisableUser implements Action {
  readonly type = DISABLE_USER;
  // payload is the ID of the user to disable
  constructor(public payload: string) {}
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  // payload is the user which has been edited
  constructor(public payload: User) {}
}

export class ForgetUser implements Action {
  readonly type = FORGET_USER;
  payload: object;

  constructor() {}
}

export class UserDisabled implements Action {
  readonly type = USER_DISABLED;

  constructor(public payload: any) {}
}

export class CancelCreation implements Action {
  readonly type = CANCEL;
  // payload is ignored but is required to be in the constructor. Set it to {}
  constructor(public payload: any) {}
}

export class HandleError implements Action {
  readonly type = HANDLE_ERROR;

  constructor(public payload: { code: number; details: any }) {}
}

export type All =
  | SetEditorState
  | UpdateUser
  | DisableUser
  | ForgetUser
  | UserDisabled
  | CancelCreation
  | HandleError;
