import { Injectable, SecurityContext } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  LinkRendererComponent,
  LinkParamsGetter,
} from '../features/grid/link-renderer/link-renderer.component';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {}

  sanitizeCell(content: string | null): string {
    // Protect against XSS - Ag-grid cell renderers do not sanitize cell input.
    const value = this.sanitizer.sanitize(SecurityContext.HTML, content);
    const formatted = value ? value : '';
    return `${formatted}`;
  }

  linkRenderer(paramsGetter: LinkParamsGetter) {
    return {
      cellRenderer: LinkRendererComponent,
      cellRendererParams: {
        paramsGetter,
      },
    };
  }

  openNewLink(content: string | null, link: string) {
    const value = this.sanitizer.sanitize(SecurityContext.HTML, content);
    return `<a href="${link}" target="_blank">${value}</a>`;
  }

  static getQueryParams(url: string): Params {
    const parts = url.split('?');
    if (parts.length < 2) {
      return {};
    } else {
      // Get the query parameters but discard hash parameters
      const querySection = parts[1].split('#')[0];
      const paramSections = querySection.split('&');
      const params: Params = {};
      paramSections.forEach((param) => {
        const keyAndValue = param.split('=');
        params[keyAndValue[0]] = keyAndValue[1];
      });
      return params;
    }
  }
}
