import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { NgClass } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * StepperStepsComponent is used for displaying an interactive horizontal list of steps for the StepperComponent.
 */
@Component({
  selector: 'bx-stepper-steps',
  templateUrl: './stepper-steps.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbTooltip, NgClass],
})
export class StepperStepsComponent {
  @HostBinding('class') readonly hostClass = 'd-flex';
  @Input() steps: Iterable<CdkStep & { disabled?: boolean; disabledTooltip?: string }>;
  @Input() selectedIndex: number;
  @Input() maxNavigableIndex: number;
  @Input() color: 'primary' | 'secondary' = 'primary';
}
