import { InjectionToken } from '@angular/core';
import { SelectionState } from '../../../features/grid/grid.component';

export interface PipelineDialogData<T = unknown> {
  folderID: string;
  selected: SelectionState;
  otherVariables?: T;
}

export const PIPELINE_DIALOG_DATA = new InjectionToken<PipelineDialogData>('PIPELINE_DIALOG_DATA');
