<pre>
  <textarea
    class="json-box form-control w-100 h-100 p-3 overflow-auto text-body"
    id="jsonBox"
    name="jsonBox"
    [ngModel]="currentJson$ | async"
    (ngModelChange)="currentJson$.next($event)"
    (keydown)="onKeyPressed($event)"
  ></textarea>
</pre>
<span>
  <button class="btn btn-light" (click)="onCheckFormat()">Format</button>
  @if (error$ | async; as error) {
    <span class="message text-danger text-truncate ms-2">
      {{ error }}
    </span>
  }
  @if (success$ | async) {
    <span class="message text-success text-truncate ms-2"
      ><fa-icon [icon]="checkMarkIcon"></fa-icon>
    </span>
  }
</span>
