import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[bxValidateEqual]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ValidateEqualDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class ValidateEqualDirective implements Validator {
  @Input() public bxValidateEqual: string;

  constructor() {}

  validate(a: AbstractControl): { [key: string]: any } {
    const b = a.root.get(this.bxValidateEqual);
    let validationError = null;
    if (a.value != null && b.value != null && a.value !== b.value) {
      validationError = {
        validateEqual: true,
      };
    }
    if (b?.errors) {
      delete b.errors['validateEqual'];
      if (!Object.keys(b.errors).length) {
        b.setErrors(null);
      }
    }

    return validationError;
  }
}
