import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TrackByFunction,
} from '@angular/core';
import { faTrash, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';
import { selectProfilesForIdentifier } from 'src/app/core/user-settings/profiles/profiles.selectors';
import { selectUserID } from '../../core/auth/auth.selectors';
import { AppState } from '../../core/core.store';
import { ProfileService } from '../../core/user-settings/profiles/profile.service';
import { Profile, ProfileFeature } from '../../core/user-settings/profiles/profiles.model';
import { IConfirmationDialogData } from '../dialog/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { NewProfileComponent } from './new-profile/new-profile.component';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NgClass, AsyncPipe } from '@angular/common';
import {
  NgbDropdown,
  NgbTooltip,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

export type ProfileInfo = Profile & { editable: boolean };
type ProfileOption = ProfileInfo & { displayIcon: IconProp };

@Component({
  selector: 'bx-profile-buttons',
  templateUrl: './profile-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgbDropdown,
    NgbTooltip,
    NgClass,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    FaIconComponent,
    AsyncPipe,
  ],
})
export class ProfileButtonsComponent implements OnInit {
  @Input() showResetToDefaultOption = false;
  @Input() identifier: string;
  @Input() feature: ProfileFeature;
  @Input() data: any;
  @Input() allowCreateSharedProfile = false;
  @Input() size: 'lg' | 'sm' | null = null;
  @Output() profileApplied = new EventEmitter<ProfileInfo>();
  @Output() profileSaved = new EventEmitter<ProfileInfo>();
  @Output() resetToDefault: EventEmitter<void> = new EventEmitter();

  profiles$: Observable<ProfileOption[]>;
  editableProfiles$: Observable<ProfileOption[]>;

  readonly icons = {
    private: faUser,
    shared: faUsers,
    delete: faTrash,
  } as const;

  constructor(
    private readonly dialogService: DialogService,
    private readonly profileService: ProfileService,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.profiles$ = combineLatest([
      this.store.select(selectProfilesForIdentifier(this.identifier, this.feature)),
      this.store.select(selectUserID),
    ]).pipe(
      map(([profiles, currentUserID]) =>
        profiles
          .map((profile) => ({
            ...profile,
            editable: profile.userID === currentUserID,
            displayIcon:
              (profile.icon ?? profile.isShared) ? this.icons.shared : this.icons.private,
          }))
          .sort((a, b) => a.name.localeCompare(b.name)),
      ),
    );
    this.editableProfiles$ = this.profiles$.pipe(
      map((profiles) => profiles.filter((profile) => profile.editable)),
    );
  }

  doApplyProfile(profileWithIcon: ProfileOption) {
    const { displayIcon, ...profile } = profileWithIcon;
    this.profileApplied.emit(profile);
  }

  deleteProfile(event: MouseEvent, profile: Profile) {
    event.preventDefault();
    event.stopPropagation();

    const confirmData: IConfirmationDialogData = {
      title: `Delete Profile '${profile.name}'?`,
      confirmationButtonText: 'Delete',
      confirmationButtonColor: 'danger',
    };

    this.dialogService
      .showConfirmationDialog(confirmData)
      .result.then((confirmed) => {
        if (confirmed) {
          this.profileService.deleteProfile(profile.id);
        }
        // Avoid ZoneJS complaining about an un-handled Promise rejection.
      })
      .catch(() => {});
  }

  saveProfile(profileOption: ProfileOption) {
    if (!profileOption?.editable) {
      throw new Error('Cannot update a profile that is owned by another user');
    }
    const { editable, displayIcon, ...profile } = profileOption;
    this.profileService.upsertProfile({
      ...profile,
      data: this.data,
    });
    this.profileSaved.next({ ...profile, editable: true });
  }

  newProfile(isSharedProfile: boolean) {
    this.dialogService
      .showDialogV2({
        component: NewProfileComponent,
        injectableData: {
          identifier: this.identifier,
          feature: this.feature,
          data: this.data,
          isShared: isSharedProfile,
        },
      })
      .result.then((profile) => {
        this.profileSaved.next(profile);
      })
      .catch(() => {
        // Avoid ZoneJS complaining about an un-handled Promise rejection.
      });
  }

  applyDefaultProfile() {
    this.resetToDefault.next();
  }

  trackByID: TrackByFunction<Profile> = (_index, profile) => profile.id;
}
