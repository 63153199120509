import {
  Component,
  ComponentRef,
  HostBinding,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject, tap } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { CleanUp } from '../../../shared/cleanup';
import { BxFormGroup } from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { AbstractControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobStepperDialog } from '../../dialogV2/job-stepper-dialog';
import { BXStep } from '../../../shared/stepper';
import { StepperComponent } from '../../../shared/stepper/stepper.component';
import { MODAL_DATA } from '../../../shared/dialog';
import { PipelineDialogData } from '../index';
import { PIPELINE_DIALOG_DATA } from './pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { JobsService } from '../../jobs/job.service';
import { CustomJobDialog } from '../../dialogV2/custom-job-dialog';
import { APP_CONFIG, AppConfig } from '../../../app.config';
import { NucleusPipelineID, PipelineFormID } from '../../pipeline/pipeline-constants';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { Profile, ProfileFeature } from '../../user-settings/profiles/profiles.model';
import { FormState } from '../../user-settings/form-state/form-state.model';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { select, Store } from '@ngrx/store';
import { selectFormStateOptions } from '../../user-settings/form-state/form-state.selectors';
import { AppState } from '../../core.store';
import { updateFormState } from '../../user-settings/form-state/form-state.actions';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { PipelineVersionSelectorComponent } from './pipeline-version-selector/pipeline-version-selector.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { StepperStepsComponent } from '../../../shared/stepper/stepper-steps/stepper-steps.component';
import { ProfileButtonsComponent } from '../../../shared/profile-buttons/profile-buttons.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { DisableIfDirective } from '../../../shared/access-check/directives/disable/disable-if.directive';

@Component({
  selector: 'bx-pipeline-dialog-v2',
  templateUrl: './pipeline-dialog-v2.component.html',
  styleUrls: ['./pipeline-dialog-v2.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    PipelineVersionSelectorComponent,
    FaIconComponent,
    StepperStepsComponent,
    ProfileButtonsComponent,
    ShowIfDirective,
    DisableIfDirective,
    NgClass,
    AsyncPipe,
  ],
})
export class PipelineDialogV2Component extends CleanUp implements OnInit, OnDestroy {
  @HostBinding('class.stepper-form') isStepperForm = false;
  @ViewChild('target', { read: ViewContainerRef, static: true }) target: ViewContainerRef;
  // Set by the pipeline-chooser; this guy contains all the business logic for the particular pipeline we want to run.
  component: Type<JobDialogContent & (RunnableJobDialog | CustomJobDialog)>;
  componentRef: ComponentRef<JobDialogContent & (RunnableJobDialog | CustomJobDialog)>;

  formValid$ = new ReplaySubject<boolean>(1);
  /* Important that submitting$ is a Behaviour subject so that it disables the form initially before enabling it again.
   * This is behaviour is needed as it when it re-enables the form it automatically updates the valueChanges of every
   * form control without needing a `startWith` operator.
   * e.g.
   * someFormControl.pipe(delay(0)).subscribe(value => value ? someFormControl.enable() someFormControl.disable())
   * vs
   * someFormControl.pipe(startWith(someFormControl.value)).subscribe(...)
   * TODO This should instead be a ReplaySubject so that it doesn't disable/re-enable the form initially and all
   *      instances that Pipeline Dialog's that rely on the BehaviourSubject should be updated to handle it.
   *      So far it's known that only the checkbox/radio form controls that handle disabling/enabling another form would be affected.
   *      Especially if using delay(0).
   */
  submitting$ = new BehaviorSubject<boolean>(false);
  disabled$: Observable<boolean>;
  stepperValid$: Observable<boolean>;
  error?: string;
  stepper: StepperComponent;
  steps$: Observable<BXStep[]>;
  pipelineVersionSelection$ = new ReplaySubject<NucleusPipelineID>(1);
  isNucleusDevEnvironment = false;
  isDevOrRunFromJsonEnabled$: Observable<boolean>;
  originalPipelineID?: NucleusPipelineID = null;
  pipelineFormID: PipelineFormID;
  /** Set to the component instance's value for {@link JobDialogContent.showProfileButtons}. */
  showProfileButtons: boolean = true;
  formState$: Observable<FormState>;
  readonly faCopy = faCopy;

  constructor(
    @Inject(MODAL_DATA) private modalData: PipelineDialogData,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    public activeModal: NgbActiveModal,
    private jobsService: JobsService,
    private store: Store<AppState>,
    private featureSwitchService: FeatureSwitchService,
  ) {
    super();

    this.component = modalData.component;
    this.disabled$ = this.submitting$;
    this.isNucleusDevEnvironment = this.appConfig.NUCLEUS_ENVIRONMENT === 'dev';
  }

  ngOnInit() {
    const injector = Injector.create({
      providers: [
        {
          provide: PIPELINE_DIALOG_DATA,
          useValue: {
            folderID: this.modalData.folderID,
            selected: this.modalData.selected,
            otherVariables: this.modalData.otherVariables,
          },
        },
      ],
    });
    this.componentRef = this.target.createComponent(this.component, { injector });

    // Set default Pipeline Version in case the Pipeline Version selector isn't rendered
    // (it only renders for the dev environment)
    this.originalPipelineID = (
      this.modalData?.otherVariables as any
    )?.jobConfiguration?.pipeline.name;
    this.isDevOrRunFromJsonEnabled$ = this.featureSwitchService.isEnabledOnce('runFromJson').pipe(
      map(
        (enabled) =>
          this.isNucleusDevEnvironment ||
          this.appConfig.NUCLEUS_ENVIRONMENT === 'local' ||
          (enabled && this.componentRef?.instance?.nucleusPipelineID === 'run-from-json'),
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.pipelineVersionSelection$.next(
      this.originalPipelineID ?? this.componentRef.instance.nucleusPipelineID,
    );

    if (this.componentRef.instance instanceof JobStepperDialog) {
      this.isStepperForm = this.componentRef.instance.stepper != null;
      this.stepper = this.componentRef.instance.stepper;
      this.steps$ = this.stepper.stepsChanges.asObservable();
    }

    this.pipelineFormID = this.componentRef.instance.pipelineFormID;
    this.showProfileButtons = this.componentRef.instance.showProfileButtons;
    // If component wants to use formState, apply the latest stored formState to the components form.
    if (this.pipelineFormID) {
      this.setFormState();
    }

    // Set first value.
    this.formValid$.next(this.componentRef.instance.form.status === 'VALID');
    // Watch for updates.
    this.componentRef.instance.form.statusChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((status) => status === 'VALID'),
        distinctUntilChanged(),
      )
      .subscribe((valid) => {
        this.formValid$.next(valid);
      });

    // Disable form while it is submitting.
    this.submitting$.subscribe((submitting) => this.enableDisable(submitting));

    if (this.componentRef.instance instanceof JobStepperDialog) {
      this.stepperValid$ = this.componentRef.instance.stepper.canProceed.pipe(
        takeUntil(this.ngUnsubscribe),
        shareReplay(1),
      );
    }

    this.formState$ = this.componentRef.instance.form.valueChanges.pipe(
      withLatestFrom(this.pipelineVersionSelection$),
      map(([_, pipelineVersion]) => {
        const form = this.getForm();
        if (form instanceof BxFormGroup) {
          return {
            pipelineVersion,
            options: form.getFormState(),
          };
        }
        return {
          pipelineVersion,
          options: {},
        };
      }),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.submitting$.complete();
    this.formValid$.complete();
  }

  copyJobParameters() {
    const componentInstance = this.componentRef.instance;
    if ('run' in componentInstance) {
      const runReturn = componentInstance.run();
      const jobParameters$ = runReturn instanceof Observable ? runReturn : of(runReturn);
      firstValueFrom(jobParameters$).then(({ parameters }) =>
        navigator.clipboard.writeText(JSON.stringify(parameters, null, 2)),
      );
    }
  }

  resetToDefaults() {
    const form = this.getForm();
    const formDefaults = this.getFormDefaults();
    if (form && formDefaults) {
      if (this.stepper != null) {
        const steps = this.stepper.steps.toArray().slice(this.stepper.selectedIndex);
        steps
          .filter((step) => step.stepControl)
          .forEach((step) => {
            const parentFormGroup = step.stepControl.parent.controls;
            const formControlName = Object.keys(parentFormGroup).find(
              (controlName) => step.stepControl === parentFormGroup[controlName],
            );
            if (formControlName) {
              this.resetFormState(
                step.stepControl as unknown as AbstractControl,
                formDefaults[formControlName],
              );
            }
          });
      } else {
        this.resetFormState(form, formDefaults);
      }
    }
  }

  runPipeline() {
    this.error = '';
    this.storeFormState(); // Store FormState in the store.

    const componentInstance = this.componentRef.instance;
    let submit$: Observable<unknown>;
    if ('run' in componentInstance) {
      const returnedJobParameters = componentInstance.run();

      const jobParameters$ =
        returnedJobParameters instanceof Observable
          ? returnedJobParameters
          : of(returnedJobParameters);

      // Call the run() method but store the returned Observable for use after.
      submit$ = jobParameters$.pipe(
        withLatestFrom(this.pipelineVersionSelection$),
        map(([jobParameters, version]) => ({
          ...jobParameters,
          pipeline: {
            ...jobParameters.pipeline,
            name: version,
          },
        })),
        switchMap((options) =>
          this.jobsService.createV2(options).pipe(
            tap((response) => {
              if ('afterJobRun' in componentInstance) {
                componentInstance.afterJobRun(response);
              }
            }),
          ),
        ),
      );
    } else {
      submit$ = componentInstance.runCustomJob();
    }

    // Call submitting before subscribing to the submit$ Observable to disable the form and show a spinner.
    this.submitting$.next(true);
    return submit$
      .pipe(
        first(),
        finalize(() => this.submitting$.next(false)),
      )
      .subscribe(
        () => {
          this.activeModal.close();
        },
        (error) => {
          this.showClientsideError(error.error.error);
        },
      );
  }

  showClientsideError(error: NucleusJobServicesError) {
    if (error && error.code) {
      switch (error.code) {
        case 'DeserializationException':
          this.error =
            'This analysis is not configured correctly. Please contact Geneious support staff.';
          return;
        case 'BadRequest':
          this.error = `We can't run any more jobs at this time. Please wait for other jobs to complete before continuing. ${error.message}`;
          return;
        case 'BatchAssemblyNameSchemeNull':
          this.error =
            'Please select a name scheme from the dropdown for assembling by name scheme.';
          return;
        default:
          this.error = `Something unexpected went wrong. Please reload the page and try again. If that doesn't work contact Geneious support staff.`;
          return;
      }
    }
  }

  // Handles skipping disabled steps
  previousStep(stepper: StepperComponent, currentSelectedIndex = 0) {
    const previousIndex = Math.max(currentSelectedIndex - 1, 0);
    const previousStep: BXStep = stepper.steps.toArray()[previousIndex] as unknown as BXStep;
    if (previousStep.disabled) {
      this.previousStep(stepper, previousIndex);
    } else {
      stepper.selectedIndex = previousIndex;
    }
  }

  // Handles skipping disabled steps
  nextStep(stepper: StepperComponent, currentSelectedIndex = 0) {
    const nextIndex = Math.min(currentSelectedIndex + 1, stepper.steps.length - 1);
    const nextStep: BXStep = stepper.steps.toArray()[nextIndex] as unknown as BXStep;
    if (nextStep.disabled && nextIndex !== currentSelectedIndex) {
      this.nextStep(stepper, nextIndex);
    } else {
      stepper.selectedIndex = nextIndex;
    }
  }

  applyProfile(profile: Profile) {
    const form = this.getForm();
    this.resetFormState(form, (profile.data as FormState).options);
    form.updateValueAndValidity();
  }

  private getForm(): FormGroup | BxFormGroup | undefined {
    return this.componentRef ? this.componentRef.instance.form : undefined;
  }

  private getFormDefaults(): any {
    return this.componentRef ? this.componentRef.instance.getFormDefaults() : undefined;
  }

  private resetFormState(form: AbstractControl, formDefaults: any) {
    form.reset(formDefaults);
    form.markAsPristine();
  }

  /**
   * Patch the child components form with the saved formState from the Store.
   */
  private setFormState(): void {
    const formId = this.componentRef.instance.pipelineFormID;
    if (formId) {
      this.store
        .pipe(
          select(selectFormStateOptions(formId)),
          filter((options) => !!options),
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((options) => {
          const form = this.componentRef.instance.form;
          form.patchValue(options, { emitEvent: false });
          form.markAsPristine();
        });
    }
  }

  /**
   * Update the formState in the Store with the current state from the child components form.
   */
  private storeFormState(): void {
    const formId = this.componentRef.instance.pipelineFormID;
    const form = this.getForm();
    if (formId && form instanceof BxFormGroup) {
      this.pipelineVersionSelection$.pipe(take(1)).subscribe((pipelineVersion) => {
        const formState: FormState = {
          options: form.getFormState(),
        };

        // Pipeline Version will only be stored if this.isNucleusDevEnvironment is true.
        if (this.isNucleusDevEnvironment) {
          formState.pipelineVersion = pipelineVersion;
        }

        this.store.dispatch(
          updateFormState({
            name: formId,
            formState,
          }),
        );
      });
    }
  }

  /**
   * If submitting, disable form otherwise enable it (only if the form exists).
   *
   * @param {boolean} submitting
   */
  private enableDisable(submitting: boolean) {
    const componentForm = this.componentRef ? this.componentRef.instance.form : undefined;
    if (componentForm) {
      submitting ? componentForm.disable() : componentForm.enable();
    }
  }

  protected readonly ProfileFeature = ProfileFeature;
}

export interface NucleusJobServicesError {
  code: string;
  message: string;
}
