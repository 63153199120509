import { AbstractControl, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';

export interface ValidationWarnings {
  [type: string]: any;
}

export class BxFormGroup<
  T extends { [K in keyof T]: AbstractControl<any, any> } = any,
> extends FormGroup<T> {
  warnings: ValidationWarnings;

  /** Taken from Angular AbstractControl Internal code and modified.
   *
   * Iterates through all child controls (FormControls, FormGroups), checks whether they of instance
   * BxFormGroup/BxFormControl and then includes it in the reduced value result.
   * If a control shouldn't be included in formState, then use FormGroup/FormControl instead of BxFormGroup/BxFormControl.
   *
   * @returns {any}
   */
  getFormState(): any {
    return this.reduceChildren(
      {},
      (acc: { [key: string]: AbstractControl }, control: AbstractControl, name: string) => {
        if (control instanceof BxFormControl) {
          acc[name] = control.value;
        } else if (control instanceof BxFormGroup) {
          acc[name] = control.getFormState();
        }
        return acc;
      },
    );
  }

  /**
   * Taken from Angular AbstractControl Internal code and modified.
   */
  private reduceChildren(initValue: any, fn: Function) {
    let res = initValue;
    Object.keys(this.controls).forEach(
      (key) => (res = fn(res, (this as UntypedFormGroup).controls[key], key)),
    );
    return res;
  }
}

export class BxFormControl<T = unknown> extends FormControl<T> {
  warnings: ValidationWarnings;
}
