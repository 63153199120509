import { Inject, Injectable } from '@angular/core';
import {
  JobConfiguration,
  JobReport,
  JobResult,
  JobResultSummary,
  JobResultTypes,
  JobService,
  JobStatus,
  Log,
  LogLevel,
  NewJobResponse,
} from '@geneious/nucleus-api-client';
import { Observable, of, switchMap } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { Job, JobSearchResultPaginatedResponse } from 'src/nucleus/services/models/job.model';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { SortModel } from 'src/app/features/grid/grid.interfaces';
import { AppState } from '../core.store';
import { select, Store } from '@ngrx/store';
import {
  selectJobResultLoading,
  selectJobResultsByJobID,
} from './store/job-results/job-results.selectors';
import { fetchJobResults } from './store/job-results/job-results.actions';
import { selectOrganizationID } from '../auth/auth.selectors';
import { ExtendedJobSearchQuery } from '@geneious/nucleus-api-client/model/extended-job-search-query';
import { JobSearchResult } from '@geneious/nucleus-api-client/model/job-search-result';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private readonly store: Store<AppState>,
    private readonly jobService: JobService,
  ) {}

  /** @deprecated in favour of a future implementation of retrieving the jobs from the store, just like the jobResults.**/
  getJobFromServer<T extends JobConfiguration>(jobID: string): Observable<Job<T>> {
    return this.jobService.getUserJob(jobID).pipe(map((response) => response.data as Job<T>));
  }

  getJobsForOrgs(
    orgIDs: string[],
    jobStatusesToInclude: string[] = undefined,
  ): Observable<JobSearchResult[]> {
    const offset = 0;
    const limit = 100;
    const jobFilter: ExtendedJobSearchQuery = {
      organizationIDs: orgIDs,
      jobStatuses: jobStatusesToInclude,
    };
    return this.jobService
      .searchAllJobs(jobFilter, undefined, offset, limit, false)
      .pipe(map((response) => response.data));
  }

  getActiveJobsForOrgs(orgIDs: string[]): Observable<JobSearchResult[]> {
    return this.getJobsForOrgs(orgIDs, ['Running', 'Queued']);
  }

  /**
   * Get Job Results for a particular Job. Job Results will only be available for a Job if it has
   * produced job results at completion.
   *
   * Job Results are cached in the store after retrieving from the server. It is handled
   * automatically in this method.
   *
   * @param jobID - fetch corresponding job results for.
   * @param organizationID - organization id for the given job.
   */
  getJobResultsByJobID(jobID: string, organizationID: string): Observable<JobResult[]> {
    const jobsResults$ = this.store.pipe(select(selectJobResultsByJobID(jobID)));

    return this.store.pipe(
      select(selectJobResultLoading(jobID)),
      tap((loading) => {
        if (loading == null) {
          this.store.dispatch(fetchJobResults({ payload: { id: jobID, organizationID } }));
        }
      }),
      filter((loading) => loading === false),
      switchMap(() => jobsResults$),
    );
  }

  /**
   * Finds the downloadable job result for the specified job and returns a download link for it.
   * @param jobID Job ID
   * @param blobName name of blob to download
   * @param resultSummary optional result summary returned from Jobs API. This will be used to
   *    build the download link if present.
   * @returns download link or null if no result found
   */
  getJobResultDownloadLink(
    jobID: string,
    blobName: 'EXPORTED_FILE' | 'FILE',
    resultSummary?: JobResultSummary,
  ): Observable<string | null> {
    if (resultSummary?.singleResult?.resultID) {
      // Nucleus only returns singleResult when there is only one document result - no need to fetch all results
      return of(this.buildJobResultDownloadLink(resultSummary.singleResult.resultID, blobName));
    }

    return this.store.pipe(
      select(selectOrganizationID),
      switchMap((orgID) => this.getJobResultsByJobID(jobID, orgID)),
      map((results) => {
        let result: JobResult;
        if (blobName === 'FILE') {
          result = results.find((r) => r.resultType === JobResultTypes.DocumentEntity);
        } else {
          // Export jobs can have multiple job results. The downloadable result has the type ExportedDocumentEntity.
          result = results.find((r) => r.resultType === JobResultTypes.ExportedDocumentEntity);
        }
        if (result) {
          return this.buildJobResultDownloadLink(result.resultID, blobName);
        }
        return null;
      }),
      take(1),
    );
  }

  /**
   * Formats a link to download the specified job result.
   * @param resultID Downloadable job result ID
   * @param blobName Name of blob to download
   * @returns A link to download the job result
   */
  buildJobResultDownloadLink(resultID: string, blobName: 'EXPORTED_FILE' | 'FILE') {
    return `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/documents/${resultID}/parts/${blobName}/data`;
  }

  createV2(config: JobConfiguration): Observable<NewJobResponse> {
    return this.jobService.submitUserJob({ config, messages: ['Job queued'] });
  }

  getUserJobActivity(jobId: string): Observable<JobStatus[]> {
    return this.jobService
      .getUserJobActivity(jobId)
      .pipe(map((response) => response.data.activity));
  }

  getAdminJob(jobId: string): Observable<Job> {
    return this.jobService.getAdminJob(jobId).pipe(map((response) => response.data));
  }

  cancel(jobId: string): Observable<void> {
    return this.jobService.updateUserJob(jobId, { kind: 'Cancel', reason: 'Cancelled by user.' });
  }

  /**
   * TODO Get from the store and keep that up to date instead.
   */
  getUserJobs<T extends JobConfiguration>(
    startRow = 0,
    endRow = 100,
    sortModel: SortModel[] = [],
    includeTotal = false,
  ): Observable<JobSearchResultPaginatedResponse<T>> {
    const offset = startRow;
    const limit = endRow - startRow;
    const sort = sortModel.map((item) => (item.sort === 'asc' ? '' : '-') + item.colId);
    return this.jobService.getUserJobs(sort, offset, limit, includeTotal) as Observable<
      JobSearchResultPaginatedResponse<T>
    >;
  }

  getOrganizationJobLogs(
    organizationID: string,
    jobID: string,
    includeLevels: LogLevel[] = [LogLevel.Debug, LogLevel.Info, LogLevel.Warning, LogLevel.Error],
  ): Observable<Log[]> {
    return this.jobService
      .getOrganizationJobLogs(organizationID, jobID, includeLevels)
      .pipe(map((response) => response.data));
  }

  getOrganizationJobReport(organizationID: string, jobID: string): Observable<JobReport> {
    return this.jobService
      .getOrganizationJobReport(organizationID, jobID, 'JOB_SUMMARY')
      .pipe(map((response) => response.data));
  }

  getOrganizationJobActivity(organizationID: string, jobID: string): Observable<JobStatus[]> {
    return this.jobService
      .getOrganizationJobActivity(organizationID, jobID)
      .pipe(map((response) => response.data.activity));
  }
}
