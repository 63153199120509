import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'bx-grid-no-api-keys-overlay',
  templateUrl: './no-api-keys-overlay.component.html',
  styleUrls: ['./no-api-keys-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink],
})
export class GridNoApiKeysOverlayComponent implements INoRowsOverlayAngularComp {
  @HostBinding('class') readonly hostClass = [
    'd-flex',
    'justify-content-center',
    'align-items-center',
    'text-muted',
  ];
  params: NoApiKeyOverlayParams;

  constructor(private router: Router) {}

  agInit(params: INoRowsOverlayParams): void {
    this.params = params as NoApiKeyOverlayParams;
  }

  handleClick(e: MouseEvent) {
    const element = e.target as HTMLElement;
    if (element.nodeName === 'A') {
      e.preventDefault();
      const link = element.getAttribute('href');
      this.router.navigate([link]);
    }
  }
}

interface NoApiKeyOverlayParams extends INoRowsOverlayParams {
  noApiKeysMessage: () => string;
}
