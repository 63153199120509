import { Observable, of as observableOf, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SelectOption } from '../models/ui/select-option.model';
import { DatabaseFolder } from '../folders/models/folder.model';
import { geneticCodes, geneticCodesMapToGeneiousPrimeName } from '../geneticCodes.constant';
import { BlastService } from '../blast/blast.service';
import { PrimersService } from '../primers/primers.service';
import { AppState } from '../core.store';
import {
  selectLabels,
  selectNameSchemesByClassifications,
} from '../organization-settings/organization-settings.selectors';
import { Store } from '@ngrx/store';
import { naturalSortOnStringProperty } from '../../shared/sort.util';
import { AbstractControl } from '@angular/forms';
import { SelectionState } from '../../features/grid/grid.component';
import { DatabaseTypeEnum } from '../blast/database-type';
import { flatten } from '../../../bx-common-extensions/array';

@Injectable({
  providedIn: 'root',
})
export class PipelineService {
  constructor(
    private store: Store<AppState>,
    private primersService: PrimersService,
    private blastService: BlastService,
  ) {}

  /**
   * Services which are exposed to the options and can be set there (in the Biologics program
   * step JSON) by the keys below.
   *
   * The object key, ie "nameSchemes" correlates directly to a value of
   * OptionType.dynamicValueSource .
   *
   * The parameter "valueSource" is used to populate the options in the select dropdown.
   * The parameters nucleusType, isSetting, and nucleusFilter are used to formulate the
   * JobRequest correctly.
   */
  readonly externalServices = {
    nameSchemes: {
      valueSource: (classifications?: string[]) =>
        this.store.select(selectNameSchemesByClassifications(classifications)).pipe(
          map((nameSchemes) =>
            nameSchemes.map((nameScheme) => new SelectOption(nameScheme.name, nameScheme.id)),
          ),
          map((nameSchemes: SelectOption[]) =>
            nameSchemes.sort(naturalSortOnStringProperty('displayName')),
          ),
        ),
      nucleusType: 'Nucleus.Setting',
      isSetting: true,
    },
    geneticCodes: {
      valueSource: () =>
        observableOf(geneticCodes.map((code) => new SelectOption(code.name, code.id))),
      nucleusType: 'string', // This may not need the type to be explicitly set
    },
    geneticCodesMapToGeneiousPrimeName: {
      valueSource: () =>
        observableOf(
          geneticCodesMapToGeneiousPrimeName.map((code) => new SelectOption(code.name, code.id)),
        ),
      nucleusType: 'string', // This may not need the type to be explicitly set
    },
    scaffoldDatabases: {
      valueSource: () =>
        this.blastService
          .getAll(DatabaseTypeEnum.SCAFFOLD)
          .pipe(
            map((blasts) =>
              blasts.map((blast: DatabaseFolder) => new SelectOption(blast.name, blast.id)),
            ),
          ),
      nucleusType: 'Nucleus.Folder',
      nucleusFilter: ['SEQUENCE', 'FILE'], // TODO Add sequence lists here when they are supported.
    },
    primerSets: {
      valueSource: () =>
        this.primersService
          .getPrimerSets()
          .pipe(
            map((sets) => sets.map((primerSet) => new SelectOption(primerSet.name, primerSet.id))),
          ),
      nucleusType: 'Nucleus.Folder',
      nucleusFilter: ['SEQUENCE'], // Only grab children from this folder if they are sequences.
    },
    labels: {
      valueSource: () =>
        this.store
          .select(selectLabels)
          .pipe(map((labels) => labels.map((label) => new SelectOption(label.name, label.id)))),
      nucleusType: 'Nucleus.Setting',
      isSetting: true,
    },
  };

  /**
   * Gets reference databases (both germline and template) as SelectOptions in
   * two groups. The first group contains provided databases, and the rest are
   * in the second group.
   */
  getReferenceDatabases(): Observable<SelectOption[][]> {
    return this.getDatabases(DatabaseTypeEnum.GERMLINE, DatabaseTypeEnum.TEMPLATE);
  }

  areReferenceDatabasesAvailable(referenceDatabaseIds: string[]): Observable<boolean> {
    if (referenceDatabaseIds?.length > 0) {
      // Check if dbs matches any of the available databases.
      return this.getDatabases(
        DatabaseTypeEnum.GERMLINE,
        DatabaseTypeEnum.TEMPLATE,
        DatabaseTypeEnum.GENERAL_TEMPLATE,
      ).pipe(
        map((options) =>
          referenceDatabaseIds.every((referenceDatabaseId) =>
            options.some((optionGroup) =>
              optionGroup.some((db) => db.value === referenceDatabaseId),
            ),
          ),
        ),
      );
    }
    return of(false);
  }

  /**
   * Gets Databases as SelectOptions in 2 groups. The first group is the Provided Geneious
   * Databases and the rest are in the second group.
   */
  getDatabases(...types: DatabaseTypeEnum[]): Observable<SelectOption[][]> {
    return this.getDatabaseFolders(...types).pipe(
      map((databases) =>
        databases.map((arr) => arr.map((database) => new SelectOption(database.name, database.id))),
      ),
    );
  }

  /**
   * Gets Database Folders in 2 groups. The first group is the Provided Geneious Databases and the
   * rest are in the second group.
   */
  getDatabaseFolders(...types: DatabaseTypeEnum[]): Observable<DatabaseFolder[][]> {
    return this.blastService.getAll(...types).pipe(
      map((databases) => {
        const providedDatabases = databases.filter((database) => database.provided);
        const nonProvidedDatabases = databases.filter((database) => !database.provided);
        return [providedDatabases, nonProvidedDatabases];
      }),
    );
  }

  nameSchemesSetOnDocuments(selected: SelectionState): Set<string> {
    const savedNameSchemes: Set<string> = new Set();

    selected.selectedRows.forEach((doc) => {
      if (doc.metadata.fileNameSchemeID) {
        savedNameSchemes.add(doc.metadata.fileNameSchemeID);
      }
    });

    return savedNameSchemes;
  }
}
