<bx-settings-breadcrumb [crumbs]="crumbs"></bx-settings-breadcrumb>
<div class="bx-page-container bx-page-container-sm">
  <section>
    <h3 class="mb-3">Profile</h3>
    <form #usersForm="ngForm" class="centered" (ngSubmit)="submit(usersForm)">
      <div class="mb-3">
        <label class="form-label" for="email">Email Address</label>
        <input
          id="email"
          type="email"
          name="email"
          class="form-control"
          placeholder="Email"
          #emailInput="ngModel"
          [(ngModel)]="user.email"
          [disabled]="!isCreating || processingRequest"
          ngFormControlValidator
          [validateOnBlur]="true"
          autocomplete="off"
          required
          email
        />
        @if (emailInput.hasError('required')) {
          <div class="invalid-feedback">Email is required</div>
        }
        @if (emailInput.hasError('email') && !emailInput.hasError('required')) {
          <div class="invalid-feedback">Must be a valid email address</div>
        }
      </div>

      <div class="mb-3">
        <label class="form-label" for="givenName">Given name</label>
        <input
          id="givenName"
          type="text"
          name="givenName"
          class="form-control"
          placeholder="Given Name"
          #givenNameInput="ngModel"
          [(ngModel)]="user.givenName"
          [disabled]="processingRequest"
          ngFormControlValidator
          [validateOnBlur]="true"
          required
        />
        @if (givenNameInput.hasError('required')) {
          <div class="invalid-feedback">Given name is required</div>
        }
      </div>

      <div class="mb-3">
        <label class="form-label" for="familyName">Family name</label>
        <input
          id="familyName"
          type="text"
          name="familyName"
          class="form-control"
          placeholder="Family Name"
          [(ngModel)]="user.familyName"
          [disabled]="processingRequest"
        />
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="admin"
          name="isAdmin"
          [(ngModel)]="user.isAdmin"
          [disabled]="processingRequest"
        />
        <label class="form-check-label" for="admin"> Admin </label>
      </div>

      @if (isCreating) {
        <div class="my-3">
          <i>The user will be emailed a link to set up their account</i>
        </div>
      }

      @if (groups$ | async; as groups) {
        @if (groups.length > 0) {
          <div class="mb-3">
            <h5 class="list-title mt-3 mb-2">Groups</h5>
            <div class="list-group">
              @for (group of groups; track group) {
                <div class="list-group-item">
                  <p class="mb-0 list-group-item-title">{{ group.name }}</p>
                  <p class="mb-0 text-muted">{{ group.description }}</p>
                </div>
              }
            </div>
          </div>
        }
      }

      @for (error of errors; track error) {
        <span class="text-danger"> {{ error }}<br /></span>
      }

      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary"
          bx-spinner-button
          [disabled]="processingRequest || usersForm.invalid"
          [spin]="processingRequest"
        >
          {{ isCreating ? 'Create User' : 'Save Changes' }}
        </button>
        @if (isCreating) {
          <button class="btn btn-secondary ms-2" (click)="onCancel()">Cancel</button>
        }
      </div>
    </form>
  </section>

  @if (!isCreating) {
    <section class="mb-5">
      <h3 class="mb-3">Account</h3>
      <p>Account Status: {{ userAccountStatus$ | async | camelCaseToTitleCase }}</p>
      @if ((userAccountStatus$ | async) === 'pending') {
        @if (activationEmailSent$ | async) {
          <div class="alert alert-success" role="alert">
            An account activation email has been sent to {{ user.email }}.
          </div>
        }
        <button
          class="btn btn-outline-primary"
          bx-spinner-button
          (click)="sendActivationEmail()"
          [disabled]="activationEmailSent$ | async"
          [spin]="sendingActivationEmail$ | async"
        >
          Resend Invite
        </button>
      }
      @if (isEmailChangingEnabled() && isBXOrg()) {
        <div class="mb-3">
          <a routerLink="change-email">Change email</a>
        </div>
      }
    </section>
  }

  @if (!isCreating && (isApiKeysEnabled$ | async)) {
    <section class="mb-5">
      <h3>API Keys</h3>
      <div class="api-key-table-wrapper">
        <bx-api-keys-table
          [showRefreshButton]="false"
          [showRevokeButton]="true"
          [apiKeysSource]="this.userID"
          [noApiKeysMessage]="'No API keys found for this user.'"
          class="h-static-2 overflow-y-auto"
        ></bx-api-keys-table>
      </div>
    </section>
  }

  @if (!isCreating) {
    <section class="mb-5">
      <h3>MFA Keys</h3>
      @if (currentUser$ | async; as currentUser) {
        <div class="api-key-table-wrapper">
          <bx-mfa-keys-table
            [showRevokeButton]="true"
            [noMfaKeysMessage]="'No MFA keys found for this user.'"
            [user]="currentUser"
            class="h-static-2 overflow-y-auto"
          ></bx-mfa-keys-table>
        </div>
      }
    </section>
  }
</div>
