import { Directive, ElementRef, Input } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AccessCondition } from '../../access-check-condition.model';
import { AccessCheckService } from '../../access-check.service';

/**
 * This feature disables html elements (eg input elements) when the specified
 * AccessCondition evaluates to true.
 *
 * IMPORTANT: Unlike *ngIf, the AccessCondition is only evaluated once in the
 * lifetime of the component. We assume that feature access and organization
 * profiles will only change between user sessions.
 *
 * Note: This directive will not work for components such as bx-spinner-button etc.
 * This only works directly on native elements, such as <button> <input> etc.
 */
@Directive({
  selector: '[bxDisableIf]',
  standalone: true,
})
export class DisableIfDirective {
  hasAccess = false;

  constructor(
    private accessCheckService: AccessCheckService,
    private element: ElementRef,
  ) {}

  /*
   * Listen to any [disabled] inputs on the host element so that this directive always overrides
   * it and sets the host to disabled. When the AccessCondition evaluates to false, allow normal disabled behaviour on the element.
   */
  @Input() set disabled(disabled: boolean) {
    this.element.nativeElement.disabled = this.hasAccess || disabled;
  }

  @Input()
  set bxDisableIf(condition: AccessCondition) {
    this.accessCheckService
      .hasAccessOnce(condition)
      .pipe(filter((hasAccess) => hasAccess))
      .subscribe(() => {
        this.hasAccess = true;
        this.element.nativeElement.disabled = true;
      });
  }
}
