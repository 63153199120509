<bx-toolstrip>
  @if (showRevokeButton) {
    <bx-toolstrip-item>
      <button
        class="btn btn-light"
        [disabled]="(revokeEnabled$ | async) === false"
        (click)="revokeApiKey()"
      >
        Revoke
      </button>
    </bx-toolstrip-item>
  }
  @if (showCreateButton) {
    <bx-toolstrip-item>
      <button class="btn btn-light" (click)="newMFAKey()">Create</button>
    </bx-toolstrip-item>
  }
</bx-toolstrip>
<bx-client-grid
  class="flex-grow-1"
  [columnDefs]="columnDefs"
  [gridOptions]="gridOptions"
  (selectionChanged)="selection$.next($event)"
></bx-client-grid>
