// Return all form states.
import { createSelector } from '@ngrx/store';
import { FormState, FormStates } from './form-state.model';
import { selectUserSettingsState } from '../user-settings.selectors';
import { PipelineFormID } from '../../pipeline/pipeline-constants';

export const selectFormStates = createSelector(selectUserSettingsState, (state) => state.formState);
// Return a single form state by name.
export const selectFormState = (name: PipelineFormID) =>
  createSelector(selectFormStates, (formStates: FormStates) => formStates[name]);
// Return a single form state's options.
export const selectFormStateOptions = (name: PipelineFormID) =>
  createSelector(selectFormState(name), (formState: FormState) => formState?.options);
// Return a single form state's pipeline version.
export const selectFormStatePipelineVersion = (name: PipelineFormID) =>
  createSelector(selectFormState(name), (formState: FormState) => formState?.pipelineVersion);
