import {
  JobConfiguration,
  JobReportTypes,
  JobResultTypes,
  JobStageConfiguration,
  JobStageScheduling,
  JobStageStatistic,
  Log,
  UserIdentifier,
} from '@geneious/nucleus-api-client';
import { JobActivityEventKind } from './activity-event-kind.model';
import { Activity } from './activity.model';
import { ActivityKind } from './activity-kind.model';

export interface JobActivityEvent extends Activity {
  kind: ActivityKind.JOB;
  event: JobEventType;
}

export type NonStageJobEvent =
  | JobQueuedActivityEvent
  | JobStartedActivityEvent
  | JobProgressedActivityEvent
  | JobCancellationInitiatedActivityEvent
  | JobCancelledActivityEvent
  | JobFailureInitiatedActivityEvent
  | JobCompletedActivityEvent
  | JobFailedActivityEvent
  | JobResultAddedActivityEvent
  | JobResultDeletedActivityEvent
  | JobReportAddedActivityEvent
  | JobLogAddedActivityEvent;

export type JobStageEvent =
  | JobStageInitializedActivityEvent
  | JobStageScheduledActivityEvent
  | JobStageStartedActivityEvent
  | JobStageProgressedActivityEvent
  | JobStageCompletedActivityEvent
  | JobStageFailedActivityEvent
  | JobStageCancelledActivityEvent
  | JobStageStatisticsAddedActivityEvent;

export type JobEventType = NonStageJobEvent | JobStageEvent;

interface BaseJobEvent {
  jobID: string;
  submitter: UserIdentifier;
  dateTime: string;
}

interface WithMessages {
  /** A non-empty list of messages */
  messages: string[];
}

interface WithProgress {
  /** An integer between 0 and 100 */
  percentage: number;
}

export function isNonStageJobEvent(event: JobEventType): event is NonStageJobEvent {
  return 'jobID' in event && event.jobID != null;
}

export function isJobStageEvent(event: JobEventType): event is JobStageEvent {
  return 'jobStage' in event && event.jobStage?.jobID != null;
}

export function getJobID(event: JobEventType) {
  if (isNonStageJobEvent(event)) {
    return event.jobID;
  }
  if (isJobStageEvent(event)) {
    return event.jobStage.jobID;
  }
  throw new Error('Job event is missing job ID: ' + JSON.stringify(event));
}

export interface JobQueuedActivityEvent extends BaseJobEvent, WithMessages {
  kind: typeof JobActivityEventKind.JOB_QUEUED;
  jobConfig: JobConfiguration;
}

export interface JobStartedActivityEvent extends BaseJobEvent, WithMessages {
  kind: typeof JobActivityEventKind.JOB_STARTED;
}

export interface JobProgressedActivityEvent extends BaseJobEvent {
  kind: typeof JobActivityEventKind.JOB_PROGRESSED;
  messages: string[];
  progress: number;
}
export interface JobCompletedActivityEvent extends BaseJobEvent {
  kind: typeof JobActivityEventKind.JOB_COMPLETED;
  messages: string[];
}

export interface JobFailureInitiatedActivityEvent extends BaseJobEvent, WithMessages, WithProgress {
  kind: typeof JobActivityEventKind.JOB_FAILURE_INITIATED;
}

export interface JobFailedActivityEvent extends BaseJobEvent, WithMessages, WithProgress {
  kind: typeof JobActivityEventKind.JOB_FAILED;
}

export interface JobCancellationInitiatedActivityEvent extends BaseJobEvent, WithProgress {
  kind: typeof JobActivityEventKind.JOB_CANCELLATION_INITIATED;
  reason: String;
}

export interface JobCancelledActivityEvent extends BaseJobEvent, WithProgress {
  kind: typeof JobActivityEventKind.JOB_CANCELLED;
  reason: String;
}

export interface JobResultAddedActivityEvent extends BaseJobEvent, WithMessages {
  kind: typeof JobActivityEventKind.JOB_RESULT_ADDED;
  resultType: JobResultTypes;
  resultID: string;
}

export interface JobReportAddedActivityEvent extends BaseJobEvent {
  kind: typeof JobActivityEventKind.JOB_REPORT_ADDED;
  jobReportType: JobReportTypes;
}

export interface JobResultDeletedActivityEvent extends BaseJobEvent {
  kind: typeof JobActivityEventKind.JOB_RESULT_DELETED;
  resultID: string;
}

export interface JobLogAddedActivityEvent extends BaseJobEvent {
  kind: typeof JobActivityEventKind.JOB_LOG_ADDED;
  log: Log;
}

/** Job Stage Events. **/
interface BaseJobStageEvent {
  jobStage: {
    jobID: string;
    stageID: string;
  };
  submitter: UserIdentifier;
  dateTime: string;
}

export interface JobStageInitializedActivityEvent extends BaseJobStageEvent {
  kind: typeof JobActivityEventKind.JOB_STAGE_INITIALIZED;
  jobStageConfig: JobStageConfiguration;
}

export interface JobStageScheduledActivityEvent extends BaseJobStageEvent {
  kind: typeof JobActivityEventKind.JOB_STAGE_SCHEDULED;
  scheduling: JobStageScheduling;
}

export interface JobStageStartedActivityEvent extends BaseJobStageEvent {
  kind: typeof JobActivityEventKind.JOB_STAGE_STARTED;
}

export interface JobStageProgressedActivityEvent extends BaseJobStageEvent, WithProgress {
  kind: typeof JobActivityEventKind.JOB_STAGE_PROGRESSED;
}

export interface JobStageCompletedActivityEvent extends BaseJobStageEvent {
  kind: typeof JobActivityEventKind.JOB_STAGE_COMPLETED;
  outputParameters: Record<string, unknown>;
}

export interface JobStageFailedActivityEvent extends BaseJobStageEvent, WithProgress {
  kind: typeof JobActivityEventKind.JOB_STAGE_FAILED;
}

export interface JobStageCancelledActivityEvent extends BaseJobStageEvent, WithProgress {
  kind: typeof JobActivityEventKind.JOB_STAGE_CANCELLED;
  reason: string;
}

export interface JobStageStatisticsAddedActivityEvent extends BaseJobStageEvent {
  kind: typeof JobActivityEventKind.JOB_STAGE_STATISTICS_ADDED;
  statistics: Record<string, JobStageStatistic>;
}
