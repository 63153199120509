@if (job) {
  <table class="table" [ngClass]="{ 'table-sm table-borderless': compact }">
    <tbody>
      @if (queueDate$ | async; as queueDate) {
        @if (queueDate.state !== ItemState.ERROR) {
          <tr>
            <th scope="row"><span>Queued At</span></th>
            <td data-spec="jd-queued-at">
              @switch (queueDate.state) {
                @case (ItemState.RESOLVED) {
                  <span>
                    {{ queueDate.data | date: 'medium' }}
                  </span>
                }
                @case (ItemState.LOADING) {
                  <ng-template [ngTemplateOutlet]="loading"></ng-template>
                }
              }
            </td>
          </tr>
        }
      }
      @if (startDate$ | async; as startDate) {
        @if (startDate.state !== ItemState.ERROR) {
          <tr>
            <th scope="row"><span>Started At</span></th>
            <td data-spec="jd-started-at">
              @switch (startDate.state) {
                @case (ItemState.RESOLVED) {
                  <span>
                    {{ startDate.data | date: 'medium' }}
                  </span>
                }
                @case (ItemState.LOADING) {
                  <ng-template [ngTemplateOutlet]="loading"></ng-template>
                }
                @case (ItemState.ERROR) {
                  <ng-template [ngTemplateOutlet]="notFound"></ng-template>
                }
              }
            </td>
          </tr>
        }
      }
      @if (isAdminView) {
        <tr>
          <th scope="row"><span>Organization</span></th>
          <td data-spec="jd-organization">
            @if (organization$ | async; as org) {
              <span>
                {{ org.name }}
              </span>
            } @else {
              <span class="text-muted">Error fetching data</span>
            }
          </td>
        </tr>
      }
      @if (submitter$ | async; as user) {
        <tr>
          <th scope="row"><span>Started By</span></th>
          <td data-spec="jd-started-by">
            @switch (user.state) {
              @case (ItemState.RESOLVED) {
                <span>
                  {{ user.data.name }}
                </span>
              }
              @case (ItemState.SNAPSHOT) {
                <span> {{ user.data.name }} <span class="text-muted">(Deleted)</span> </span>
              }
              @case (ItemState.LOADING) {
                <ng-template [ngTemplateOutlet]="loading"></ng-template>
              }
              @case (ItemState.ERROR) {
                <ng-template [ngTemplateOutlet]="notFound"></ng-template>
              }
            }
          </td>
        </tr>
      }
      @if (inputSelection$ | async; as inputSelection) {
        <tr>
          <th scope="row"><span>Input Files</span></th>
          @if (inputSelection.folder; as folder) {
            <td>
              <span data-spec="jd-input-folder">
                @switch (folder.state) {
                  @case (ItemState.RESOLVED) {
                    <a [routerLink]="'/folders/' + folder.data.id + '/files'">{{
                      folder.data.name
                    }}</a>
                  }
                  @case (ItemState.SNAPSHOT) {
                    <span>
                      {{ folder.data.name }} <span class="text-muted">(Folder Not Found)</span>
                    </span>
                  }
                  @case (ItemState.LOADING) {
                    <ng-template [ngTemplateOutlet]="loading"></ng-template>
                  }
                  @case (ItemState.ERROR) {
                    <ng-template [ngTemplateOutlet]="notFound"></ng-template>
                  }
                  @default {
                    <span class="text-muted"> &nbsp;(Original Folder)</span>
                  }
                }
              </span>
              @if (folder.state !== ItemState.LOADING && inputSelection.documents) {
                <ul class="mb-0" data-spec="jd-input-docs-list">
                  @for (document of inputSelection.documents; track document) {
                    <li>
                      @switch (document.state) {
                        @case (ItemState.RESOLVED) {
                          <div>
                            @if (isAdminView) {
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                routerLink="/view"
                                [queryParams]="
                                  getPopOutParams(document.data.parentID, document.data.id)
                                "
                              >
                                {{ document.data.name }}
                              </a>
                            } @else {
                              <a
                                [routerLink]="'/folders/' + document.data.parentID + '/files'"
                                [queryParams]="{ selectRowID: document.data.id }"
                                >{{ document.data.name }}</a
                              >
                            }
                          </div>
                        }
                        @case (ItemState.SNAPSHOT) {
                          <span>
                            {{ document.data.name }} <span class="text-muted">(Not Found)</span>
                          </span>
                        }
                        @case (ItemState.LOADING) {
                          <ng-template [ngTemplateOutlet]="loading"> </ng-template>
                        }
                        @case (ItemState.ERROR) {
                          <ng-template [ngTemplateOutlet]="notFound"> </ng-template>
                        }
                      }
                    </li>
                  }
                  @if (inputSelection.totalDocumentCount > MAX_INPUT_FILE_TO_SHOW) {
                    <li>
                      and {{ inputSelection.totalDocumentCount - MAX_INPUT_FILE_TO_SHOW }} more
                      files
                    </li>
                  }
                </ul>
              }
            </td>
          }
        </tr>
      }
      @if (nameScheme$ | async; as nameScheme) {
        <tr>
          <th scope="row"><span>Name Scheme</span></th>
          <td data-spec="jd-name-scheme">
            @switch (nameScheme.state) {
              @case (ItemState.RESOLVED) {
                <a routerLink="/name-schemes">{{ nameScheme.data.name }}</a>
              }
              @case (ItemState.SNAPSHOT) {
                <span> {{ nameScheme.data.name }} <span class="text-muted">(Deleted)</span> </span>
              }
              @case (ItemState.LOADING) {
                <ng-template [ngTemplateOutlet]="loading"></ng-template>
              }
              @case (ItemState.ERROR) {
                <ng-template [ngTemplateOutlet]="notFound"></ng-template>
              }
            }
          </td>
        </tr>
      }
      @if (referenceDatabases$ | async; as referenceDatabases) {
        <tr>
          <th scope="row"><span>Reference Sequences</span></th>
          <td data-spec="jd-reference-database">
            @if (referenceDatabases.state !== ItemState.LOADING) {
              <ul class="mb-0" data-spec="jd-input-docs-list">
                @for (refDb of referenceDatabases.data; track refDb) {
                  <li>
                    @switch (referenceDatabases.state) {
                      @case (ItemState.RESOLVED) {
                        <a [routerLink]="'/database/' + refDb?.id">{{ refDb?.name }}</a>
                      }
                      @case (ItemState.SNAPSHOT) {
                        <span> {{ refDb?.name }} <span class="text-muted">(Not Found)</span> </span>
                      }
                      @case (ItemState.LOADING) {
                        <ng-template [ngTemplateOutlet]="loading"></ng-template>
                      }
                      @case (ItemState.ERROR) {
                        <ng-template [ngTemplateOutlet]="notFound"></ng-template>
                      }
                    }
                  </li>
                }
              </ul>
            }
          </td>
        </tr>
      }
      @if (outputResults$ | async; as outputResults) {
        <tr>
          <th scope="row"><span>Output Files</span></th>
          @if (outputResults.folder; as folder) {
            <td>
              <span data-spec="jd-output-folder">
                @switch (folder.state) {
                  @case (ItemState.RESOLVED) {
                    <a
                      [routerLink]="folder.data.id ? '/folders/' + folder.data.id + '/files' : null"
                      >{{ folder.data.name }}</a
                    >
                  }
                  @case (ItemState.SNAPSHOT) {
                    <span>
                      {{ folder.data.name }} <span class="text-muted">(Folder Not Found)</span>
                    </span>
                  }
                  @case (ItemState.LOADING) {
                    <ng-template [ngTemplateOutlet]="loading"></ng-template>
                  }
                  @case (ItemState.ERROR) {
                    <ng-template [ngTemplateOutlet]="notFound"></ng-template>
                  }
                  @case (ItemState.RESOLVED) {
                    <span class="text-muted"> &nbsp;(Original Folder) </span>
                  }
                }
              </span>
              @if (folder.state !== ItemState.LOADING && outputResults.documents) {
                <ul class="mb-0" data-spec="jd-output-docs-list">
                  @for (document of outputResults.documents; track document) {
                    <li>
                      @switch (document.state) {
                        @case (ItemState.RESOLVED) {
                          <div>
                            @if (isAdminView) {
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                routerLink="/view"
                                bxTrackButtonClick="Pop Out"
                                [queryParams]="
                                  getPopOutParams(document.data.parentID, document.data.id)
                                "
                              >
                                {{ document.data.name }}
                              </a>
                            } @else {
                              <a
                                [routerLink]="'/folders/' + outputResults.folder.data.id + '/files'"
                                [queryParams]="{ selectRowID: document.data.id }"
                                >{{ document.data.name }}</a
                              >
                            }
                          </div>
                        }
                        @case (ItemState.SNAPSHOT) {
                          <span>
                            {{ document.data.name }} <span class="text-muted">(Not Found)</span>
                          </span>
                        }
                        @case (ItemState.LOADING) {
                          <ng-template [ngTemplateOutlet]="loading"> </ng-template>
                        }
                        @case (ItemState.ERROR) {
                          <ng-template [ngTemplateOutlet]="notFound"> </ng-template>
                        }
                      }
                    </li>
                  }
                </ul>
              }
            </td>
          }
        </tr>
      }
    </tbody>
  </table>
} @else {
  <div class="d-flex align-items-center justify-content-center h-static-1 overflow-y-auto">
    <bx-spinner></bx-spinner>
  </div>
}

@if ((jobInsightsEnabled$ | async) && job?.id) {
  <h5>Job Insights</h5>
  <bx-job-insights
    [jobID]="job.id"
    [organizationID]="job.submitter.organizationID"
    class="d-block mb-3"
  ></bx-job-insights>
}

<h5>Job Parameters</h5>
<bx-monospace-box [loading]="!jobParams" data-spec="jd-job-params-box">{{
  jobParams
}}</bx-monospace-box>
<div class="d-flex justify-content-end align-items-center py-2">
  <button class="btn btn-secondary me-2" title="Copy job parameters" (click)="copyJobParams()">
    Copy job parameters
  </button>
  @if (runFromJsonEnabled$ | async) {
    <button
      class="btn btn-secondary"
      title="Run a job with the specified job parameters"
      (click)="openRunFromJson()"
    >
      Re-run job with edited parameters
    </button>
  }
</div>

@if (statusUpdates$ | async; as statusUpdates) {
  @if (statusUpdates.state !== ItemState.ERROR) {
    <h5 class="mt-3">Status Updates</h5>
    <bx-monospace-box
      [loading]="statusUpdates.state === ItemState.LOADING"
      data-spec="jd-status-updates-box"
    >
      @for (status of statusUpdates.data; track status) {
        <span
          [ngClass]="{ 'text-danger strong': status.kind === 'Failed' }"
          class="m-0"
          [title]="status.time | date: 'long'"
        >
          {{ status.time | date: 'mediumTime' }} [{{ status.kind }}] {{ status.messages }}
          <br />
        </span>
      }
    </bx-monospace-box>
  }
}

@if (jobErrors$ | async; as jobErrors) {
  @if (jobErrors.state !== ItemState.ERROR) {
    <div class="mb-3">
      <h5 class="mt-3">
        Job Errors
        <button class="btn btn-link" (click)="showRedacted$.next(!showRedacted$.getValue())">
          {{ (showRedacted$ | async) ? 'Unredact' : 'Redact' }}
        </button>
      </h5>
      <bx-monospace-box [loading]="jobErrors.state === ItemState.LOADING" data-spec="jd-logs-box">
        @for (log of jobErrors.data; track log) {
          <span [ngClass]="{ 'text-danger strong': log.level === 'error' }" class="m-0">
            {{ $any(log).loggedAt | date: 'mediumTime' }} [{{ log.level }}] {{ log.message }}
            <br />
          </span>
        }
      </bx-monospace-box>
    </div>
  }
}

<ng-template #loading>
  <bx-spinner></bx-spinner>
</ng-template>

<ng-template #notFound>
  <span class="text-muted">Not Found</span>
</ng-template>
