<div class="folder-selector" #scrollPanel>
  @for (renderItem of activeFolderList; track renderItem) {
    <div class="folder-selector-item">
      @if (renderItem.isSelected) {
        <div
          [ngClass]="renderItem.getContainerClass()"
          [ngStyle]="renderItem.setPadding()"
          (click)="select($event, renderItem.folderID)"
          #selectedRow
        >
          <i [ngClass]="renderItem.getClass()" (click)="toggleExpand($event, renderItem.folderID)"
            >chevron_right</i
          >
          <mat-icon svgIcon="{{ renderItem.iconLabel }}"></mat-icon>
          <div class="folder-label" title="{{ renderItem.folderLabel }}">
            {{ renderItem.folderLabel }}
          </div>
          @if (renderItem.isLocked) {
            <span class="help-text">Cannot move documents to a read-only folder</span>
          }
        </div>
      } @else {
        <div
          [ngClass]="renderItem.getContainerClass()"
          [ngStyle]="renderItem.setPadding()"
          (click)="select($event, renderItem.folderID)"
        >
          <i [ngClass]="renderItem.getClass()" (click)="toggleExpand($event, renderItem.folderID)"
            >chevron_right</i
          >
          <mat-icon svgIcon="{{ renderItem.iconLabel }}"></mat-icon>
          <div class="folder-label" title="{{ renderItem.folderLabel }}">
            {{ renderItem.folderLabel }}
          </div>
        </div>
      }
    </div>
  }
</div>

@if (!singleSelectionMode) {
  @if (selectedRenderItems.size > 0) {
    <p class="selected-counter">
      {{ selectedRenderItems.size }} folder{{ selectedRenderItems.size > 1 ? 's' : '' }} selected
    </p>
  }
}
