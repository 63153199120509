import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'bx-toolstrip-item',
  templateUrl: './toolstrip-item.component.html',
  standalone: true,
})
export class ToolstripItemComponent {
  @HostBinding('class') readonly hostClass = 'm-1';
}
