@if (crumbs$ | async; as crumbs) {
  <ol class="breadcrumb pb-0">
    @for (crumb of crumbs; track crumb; let i = $index) {
      <li
        class="breadcrumb-item"
        [ngClass]="{ 'active fw-bold': i === crumbs.length - 1 }"
        data-spec="crumb"
      >
        @if (crumb.link) {
          <a [routerLink]="crumb.link" [ngClass]="{ 'text-dark': i === crumbs.length - 1 }">
            {{ crumb.label }}
          </a>
        } @else {
          <span>{{ crumb.label }}</span>
        }
      </li>
    }
  </ol>
}
