import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from 'src/app/app.config';
import { FeatureSwitchService } from 'src/app/features/feature-switch/feature-switch.service';
import { CleanUp } from 'src/app/shared/cleanup';
import { naturalSortCompare } from '../../../../shared/sort.util';
import { SelectOption } from '../../../models/ui/select-option.model';
import { PipelineAssociationsService } from '../../../pipeline/pipeline-associations/pipeline-associations.service';
import { NucleusPipelineID, PipelineFormID } from '../../../pipeline/pipeline-constants';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core.store';
import { selectFormStatePipelineVersion } from '../../../user-settings/form-state/form-state.selectors';
import { SelectComponent } from '../../../../shared/select/select.component';

@Component({
  selector: 'bx-pipeline-version-selector',
  templateUrl: './pipeline-version-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SelectComponent],
})
export class PipelineVersionSelectorComponent
  extends CleanUp
  implements OnInit, OnChanges, OnDestroy
{
  @Input() nucleusPipelineID: NucleusPipelineID;
  @Input() pipelineFormID: PipelineFormID | null;
  @Input() originalPipelineID: NucleusPipelineID = null;
  @Output() pipelineVersionChanged = new EventEmitter<NucleusPipelineID>();

  pipelineVersions$: Observable<SelectOption<NucleusPipelineID>[]>;
  pipelineVersionControl = new FormControl<NucleusPipelineID>(undefined);
  showDropdown$: Observable<boolean>;

  private readonly nucleusPipelineID$ = new ReplaySubject<NucleusPipelineID>(1);
  private readonly pipelineFormID$ = new ReplaySubject<PipelineFormID>(1);

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    private readonly pipelineAssociationsService: PipelineAssociationsService,
    private readonly featureSwitchService: FeatureSwitchService,
    private readonly store: Store<AppState>,
  ) {
    super();
  }

  ngOnChanges({ nucleusPipelineID, pipelineFormID }: SimpleChanges): void {
    if (
      nucleusPipelineID &&
      (nucleusPipelineID.isFirstChange() ||
        nucleusPipelineID.currentValue !== nucleusPipelineID.previousValue)
    ) {
      this.nucleusPipelineID$.next(nucleusPipelineID.currentValue);
    }
    if (
      pipelineFormID &&
      (pipelineFormID.isFirstChange() ||
        pipelineFormID.currentValue !== pipelineFormID.previousValue)
    ) {
      this.pipelineFormID$.next(pipelineFormID.currentValue);
    }
  }

  ngOnInit(): void {
    this.showDropdown$ = of(
      this.appConfig.NUCLEUS_ENVIRONMENT === 'dev' ||
        this.appConfig.NUCLEUS_ENVIRONMENT === 'local',
    ).pipe(
      switchMap((isDevelopment) => {
        if (isDevelopment) {
          return of(true);
        }
        return combineLatest([
          this.nucleusPipelineID$,
          this.featureSwitchService.isEnabled('runFromJson'),
        ]).pipe(
          map(
            ([pipelineID, runFromJsonEnabled]) =>
              runFromJsonEnabled && pipelineID === 'run-from-json',
          ),
        );
      }),
      distinctUntilChanged(),
      // Emit the input pipeline ID if the dropdown is disabled
      tap((showDropdown) => {
        if (!showDropdown) {
          this.pipelineVersionChanged.emit(this.nucleusPipelineID);
        }
      }),
    );

    this.pipelineVersions$ = combineLatest([
      this.nucleusPipelineID$,
      this.pipelineAssociationsService.getProfilePipelineAssociations(),
    ]).pipe(
      map(([nucleusPipelineID, associations]) =>
        associations.filter((association) => {
          if (
            (nucleusPipelineID && association === nucleusPipelineID) ||
            association.startsWith(nucleusPipelineID + ':')
          ) {
            return true;
          }
          if (nucleusPipelineID === 'run-from-json') {
            return (
              !this.originalPipelineID ||
              association === this.originalPipelineID ||
              association.startsWith(this.originalPipelineID.split(':')[0] + ':')
            );
          }
          return false;
        }),
      ),
      map((associations) => associations.sort(naturalSortCompare)),
      map((associations) =>
        associations.map((association) => ({ displayName: association, value: association })),
      ),
    );

    const savedFormStatePipelineVersion$ = this.pipelineFormID$.pipe(
      switchMap((pipelineFormID) =>
        this.store.select(selectFormStatePipelineVersion(pipelineFormID)).pipe(take(1)),
      ),
      takeUntil(this.ngUnsubscribe),
    );

    this.pipelineVersionControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => this.pipelineVersionChanged.emit(value));

    combineLatest([savedFormStatePipelineVersion$, this.pipelineVersions$])
      .pipe(
        map(
          ([savedVersion, versions]) =>
            versions.find((version) => version.value === savedVersion)?.value,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((pipelineVersion) =>
        this.pipelineVersionControl.setValue(
          this.originalPipelineID ?? pipelineVersion ?? this.nucleusPipelineID,
        ),
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.nucleusPipelineID$.complete();
    this.pipelineFormID$.complete();
  }
}
