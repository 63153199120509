<div class="row mx-2 mx-lg-3">
  @if ({ qr: qr$ | async }; as state) {
    <div class="col-5 px-0 d-flex align-items-center justify-content-center">
      @if (state.qr) {
        <div class="flex-grow-1 flex-shrink-1 d-flex align-items-center justify-content-center">
          <img
            [src]="state.qr"
            [ngClass]="{ 'blurred-qr': !showQR }"
            class="position-relative w-100"
            (click)="toggleShowQR()"
          />
          @if (!showQR) {
            <div
              class="badge bg-secondary position-absolute"
              role="button"
              (click)="toggleShowQR()"
            >
              Click here to view
            </div>
          }
        </div>
      }
    </div>
  }
  <div class="col-7 d-flex flex-column justify-content-center">
    <p>
      Scan the QR code on the left using a multi-factor authentication app on your phone, and enter
      the 6-digit verification code from your app below. If you cannot scan the QR code, copy/paste
      the text key into your authentication app.
    </p>
    <form [formGroup]="form">
      <div class="mb-3">
        <label class="form-label" for="textKey">Text key:</label>
        <div class="input-group">
          <input
            [type]="showTextKey ? 'text' : 'password'"
            class="form-control"
            [formControl]="form.controls.keyCode"
            id="textKey"
          />
          <button class="btn btn-outline-secondary">
            <fa-icon
              (click)="toggleShowTextKey()"
              [icon]="showTextKey ? faEyeSlash : faEye"
              [ngbTooltip]="showTextKey ? 'Click to hide' : 'Click to show'"
            ></fa-icon>
          </button>
        </div>
      </div>

      <div>
        <label class="form-label" for="verificationCode">Verification code:</label>
        <input
          type="number"
          [formControl]="form.controls.mfaCode"
          class="form-control"
          id="verificationCode"
        />
      </div>
    </form>
  </div>
</div>
