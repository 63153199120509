import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './select.component';
import { distinctUntilChanged, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgFormControlValidatorDirective } from '../form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../form-errors/form-errors.component';

/**
 * This component wraps native `select[multiple="true"]` and dynamically generates the `option`
 * elements based on the `selectOptions` input. Because it is a wrapper component, using
 * `ngFormControlValidator` and `bx-form-errors` will not work, as Bootstrap won't display form
 * feedback unless it is a sibling of the `select` element. However, the component will handle this
 * for you if you set `showFormErrors` to true.
 */
@Component({
  selector: 'bx-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    SpinnerComponent,
    FormsModule,
    NgFormControlValidatorDirective,
    ReactiveFormsModule,
    NgClass,
    NgTemplateOutlet,
    FormErrorsComponent,
    AsyncPipe,
  ],
})
export class MultiSelectComponent extends SelectComponent implements OnInit {
  @Input() showSelectionSummary = true;
  // Used to name what is shown in the selected counter.
  // e.g. an entityName of `database` would show as `1 database selected`.
  @Input() entityName: string;
  // Message to display if there no items to select
  @Input() emptyMessage: string;
  // If false, shows a dropdown instead, e.g. if the multi-select functionality needs to be feature-switched away
  @Input() showMultiple: boolean = true;
  /** The number of select rows to show initially */
  @Input() size: number;
  /** Whether the multi-select should be vertically resizable */
  @Input() resizable = true;
  /** Same as bx-select but defaults to false */
  @Input() canMarkPristineInvalid = false;

  selectedCount$: Observable<number>;
  showEmptyMessage$: Observable<boolean>;

  ngOnInit() {
    super.ngOnInit();
    this.selectedCount$ = this.control.valueChanges.pipe(
      startWith(this.control.value),
      map((selected) => {
        // If we've selected an array of items, we want to filter out any which are null as in some
        // cases a select might have a 'null' item to represent "No database"
        if (Array.isArray(selected)) {
          return selected.filter((s: any) => s != null);
        }
        return selected;
      }),
      map((selected) => (selected?.length > 0 ? selected.length : 0)),
      takeUntil(this.ngUnsubscribe),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.showEmptyMessage$ = combineLatest([
      this.selectOptions$,
      this.loading$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([selectOptions, loading]) => !loading && selectOptions.length === 0),
      takeUntil(this.ngUnsubscribe),
    );
    this.showSelectionSummary = this.showSelectionSummary && this.showMultiple;
  }
}
