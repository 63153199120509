import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ColDef, GridOptions } from '@ag-grid-community/core';
import { UtilService } from '../../../shared/util.service';
import { ApiKeysService } from '../api-keys.service';
import { filter, map, startWith } from 'rxjs/operators';
import { ApiKey } from '../api-key.model';
import {
  ClientGridComponent,
  ClientGridSelection,
} from '../../../features/grid/client-grid/client-grid.component';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { GridNoApiKeysOverlayComponent } from '../../../features/grid/overlays/no-api-keys-overlay.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { ToolstripComponent } from '../../../shared/toolstrip/toolstrip.component';
import { AsyncPipe } from '@angular/common';
import { ToolstripItemComponent } from '../../../shared/toolstrip/toolstrip-item/toolstrip-item.component';
import { RouterLink } from '@angular/router';

// API Keys can come from:
// - current user
// - current organization
// - a specific user
// - a specific org
type UserID = string;
type APIKeysSource = 'currentUser' | 'currentOrg' | UserID;

@Component({
  selector: 'bx-api-keys-table',
  templateUrl: './api-keys-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToolstripComponent, ToolstripItemComponent, RouterLink, ClientGridComponent, AsyncPipe],
})
export class ApiKeysTableComponent implements OnInit, OnDestroy {
  @HostBinding('class') readonly hostClass = 'd-flex flex-column';

  @Input() apiKeysSource: APIKeysSource = 'currentUser';
  @Input() showRevokeButton = false;
  @Input() showRefreshButton = false;
  @Input() noApiKeysMessage?: string;
  @Input() showCreateButton = false;
  @ViewChild(ClientGridComponent, { static: true }) clientGridComponent: ClientGridComponent;
  revokeEnabled$: Observable<boolean>;
  selectedApiKey$: Observable<ApiKey>;

  columnDefs: ColDef[];
  gridOptions: GridOptions;
  selection$ = new ReplaySubject<ClientGridSelection>(1);
  dataSubscription: Subscription = new Subscription();

  confirmationDialogRef: NgbModalRef;

  constructor(
    private utilService: UtilService,
    private apiKeysService: ApiKeysService,
    private dialogService: DialogService,
  ) {
    this.gridOptions = {
      rowSelection: 'single',
      noRowsOverlayComponent: 'noApiKeysOverlay',
      components: {
        noApiKeysOverlay: GridNoApiKeysOverlayComponent,
      },
      noRowsOverlayComponentParams: {
        noApiKeysMessage: () => this.noApiKeysMessage,
      },
    };
  }

  ngOnInit(): void {
    this.initializeColumns();
    this.reloadData();

    this.revokeEnabled$ = this.selection$.pipe(
      map((selection) => selection.rows.length === 1),
      startWith(false),
    );

    this.selectedApiKey$ = this.selection$.pipe(
      filter((selection) => selection.rows.length === 1),
      map((selection) => selection.rows[0]),
    );
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
    this.selection$.complete();
  }

  reloadData() {
    this.clientGridComponent.gridOptions?.api.showLoadingOverlay();
    this.dataSubscription = this.getApiKeysRows().subscribe((rows) => {
      this.clientGridComponent.setRowData(rows);
      this.clientGridComponent.gridOptions?.api.hideOverlay();
      if (rows.length === 0) {
        this.clientGridComponent.gridOptions?.api.showNoRowsOverlay();
      }
    });
  }

  revokeApiKey() {
    const revokeSubscription = this.selectedApiKey$.subscribe((selectedApiKey) => {
      this.confirmationDialogRef = this.dialogService.showConfirmationDialog({
        title: `Revoke the API key: ${selectedApiKey.name}?`,
        content: 'This action is irreversible. The API key will be deleted forever.',
        confirmationButtonText: 'Revoke',
        confirmationButtonColor: 'danger',
      });

      this.confirmationDialogRef.result
        .then((confirmed) => {
          if (confirmed) {
            if (!selectedApiKey) {
              console.warn('No API Key to revoke. This should never happen.');
              return;
            }

            this.apiKeysService.revokeApiKey(selectedApiKey).subscribe(() => this.reloadData());
          }
        })
        // Avoid ZoneJS complaining about an un-handled Promise rejection.
        .catch(() => {})
        .finally(() => revokeSubscription.unsubscribe());
    });
  }

  private getApiKeysRows(): Observable<unknown[]> {
    const rows = (() => {
      switch (this.apiKeysSource) {
        case 'currentUser':
          return this.apiKeysService.getCurrentUserApiKeys();
        case 'currentOrg':
          return this.apiKeysService.getCurrentOrganizationApiKeys();
        default:
          return this.apiKeysService.getUserApiKeys(this.apiKeysSource);
      }
    })();
    return rows.pipe(map((apiKeys) => apiKeys.map(this.apiKeyToTableRow)));
  }

  private apiKeyToTableRow(apiKey: ApiKey): unknown {
    return {
      name: apiKey.name,
      createdAt: new Date(apiKey.createdAt),
      lastUsedAt: apiKey.lastUsedAt ? new Date(apiKey.lastUsedAt) : '',
      user: apiKey.user,
      id: apiKey.id,
    };
  }

  private initializeColumns() {
    const commonColumnDefs: ColDef[] = [
      {
        field: 'id',
        headerName: '',
        width: 34,
        resizable: false,
        suppressMovable: true,
        checkboxSelection: true,
        headerCheckboxSelection: false,
        valueFormatter: () => '',
      },
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Created at',
        field: 'createdAt',
      },
      {
        headerName: 'Last used at',
        field: 'lastUsedAt',
        sort: 'asc',
      },
    ];

    const showOrgApiKeysColumnDefs: ColDef[] = [
      {
        headerName: 'Created By',
        field: 'user',
        ...this.utilService.linkRenderer((cell) => ({
          text: cell.value.fullName,
          href: `/users/${cell.value.id}`,
        })),
      },
    ];

    const specificColumnDefs = this.apiKeysSource === 'currentOrg' ? showOrgApiKeysColumnDefs : [];

    this.columnDefs = [...commonColumnDefs, ...specificColumnDefs];
  }
}
