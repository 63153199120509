import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { JobConfiguration } from '@geneious/nucleus-api-client';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditableJsonAreaComponent } from '../../editable-json-area/editable-json-area.component';

@Component({
  selector: 'bx-run-from-json',
  templateUrl: './run-from-json.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, EditableJsonAreaComponent],
})
export class RunFromJsonComponent extends JobDialogContent implements OnInit, RunnableJobDialog {
  title: string = 'Run job from parameters';
  earlyRelease?: boolean;
  knowledgeBaseArticle?: string;

  defaultParams = { output: {}, options: {} };

  form = new BxFormGroup({
    jobParams: new BxFormControl<any>(undefined),
  });

  constructor(
    @Inject(PIPELINE_DIALOG_DATA)
    public dialogData: PipelineDialogData<{ jobConfiguration: JobConfiguration }>,
  ) {
    super('run-from-json', PipelineFormID.RUN_FROM_JSON);
  }

  ngOnInit(): void {
    this.form.setValue({ jobParams: this.defaultParams });
    const jobParams = this.dialogData.otherVariables.jobConfiguration?.parameters;
    if (jobParams != null) {
      this.form.setValue({ jobParams: jobParams });
    }
  }

  run() {
    const formValue = this.form.getRawValue();
    const job: JobConfiguration['parameters'] = JSON.parse(formValue.jobParams);
    return { parameters: job, pipeline: { version: 1 } as any };
  }

  getFormDefaults() {
    return this.defaultParams;
  }
}
