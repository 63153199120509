import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { UsersStoreService } from './users-store.service';
import User from '../user-editor/user.model';
import { State } from '../user-editor/user-editor.reducer';
import { map, pluck } from 'rxjs/operators';
import {
  JobService,
  MfaKeySummaryListResponse,
  NewMfaKey,
  NewUser,
  UserDetailsStatus,
  UserManagementService,
  UserUpdatesNewStatus,
} from '@geneious/nucleus-api-client';
import { JobExecutionStatistics } from '@geneious/nucleus-api-client/model/job-execution-statistics';
import { UserSearchFilters } from '@geneious/nucleus-api-client/model/user-search-filters';
import { UserSearchResult } from '@geneious/nucleus-api-client/model/user-search-result';

export type FolderTransferStrategy = 'none' | 'transferAll' | 'transferShared';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(
    private userManagementService: UserManagementService,
    private jobsService: JobService,
    private storeService: UsersStoreService,
  ) {}

  changePassword(userID: string, currentPassword: string, newPassword: string) {
    return this.userManagementService.updateUser(userID, {
      oldPassword: currentPassword,
      newPassword,
    });
  }

  changeEmail(userID: string, newEmail: string) {
    return this.userManagementService.updateUser(userID, {
      email: newEmail,
    });
  }

  getDataStore(): Observable<State> {
    return this.storeService.getStore();
  }

  createUser(user: NewUser): Observable<any> {
    return this.userManagementService.createUser(user);
  }

  updateUser(user: User) {
    this.storeService.updateUser(user);
  }

  disableUser(
    userID: string,
    folderTransferStrategy: FolderTransferStrategy = 'none',
    folderTransferUser?: string,
  ): Observable<any> {
    return this.userManagementService.deleteUser(
      userID,
      folderTransferStrategy,
      true,
      folderTransferUser,
    );
  }

  listMfaKeys(userID: string): Observable<MfaKeySummaryListResponse> {
    return this.userManagementService.listMfaKeys(userID);
  }

  deleteMfaKey(keyId: string) {
    return this.userManagementService.deleteMfaKey(keyId);
  }

  createMfaKey(newMfaKey: NewMfaKey) {
    return this.userManagementService.createMfaKey(newMfaKey);
  }
  verifyMfa(keyId: string, code: number) {
    return this.userManagementService.verifyMfaKey(keyId, { code: code });
  }

  activateUser(user: User): Observable<User> {
    return this.userManagementService
      .updateUser(user.id, {
        newStatus: UserUpdatesNewStatus.Active,
      })
      .pipe(map((user) => User.fromJson(user.data)));
  }

  getUsers(organizationID: string, includeDeactivatedUsers = false): Observable<User[]> {
    return this.userManagementService.listUsers(organizationID).pipe(
      map((users) =>
        users.data.filter((user) =>
          includeDeactivatedUsers ? true : user.status === UserDetailsStatus.Active,
        ),
      ),
      map((users) => users.map((user) => User.fromJson(user))),
    );
  }

  searchUsers(userSearchFilters: UserSearchFilters): Observable<UserSearchResult[]> {
    return this.userManagementService.searchUsers(userSearchFilters).pipe(pluck('data'));
  }

  /**
   * Returns an observable of a list of users combined with their job statistics.
   *
   * @param organizationID
   */
  getUsersWithJobStatistics(organizationID: string): Observable<UserWithJobStatistics[]> {
    return forkJoin([
      this.getUsers(organizationID, true),
      this.jobsService.getJobExecutionStatistics(organizationID),
    ]).pipe(
      map(([users, jobStats]) => {
        const jobStatsMap = new Map(jobStats.data.map((jobStat) => [jobStat.userID, jobStat]));
        return users.map((user) => this.mergeUserAndStats(user, jobStatsMap.get(user.id)));
      }),
    );
  }

  private mergeUserAndStats(user: User, jobStats: JobExecutionStatistics): UserWithJobStatistics {
    // The API returns nothing for users with no stats, so if we find nothing, use this empty job stats object.
    const emptyJobStats: JobExecutionStatistics = {
      userID: user.id,
      successfulJobsInTotal: 0,
      successfulJobsLast30Days: 0,
    };

    const statsForUser = jobStats ?? emptyJobStats;
    return Object.assign(user, statsForUser);
  }

  getUser(userID: string): Observable<User> {
    return this.userManagementService.getUser(userID).pipe(map((user) => User.fromJson(user.data)));
  }

  editUser(user: User): Observable<any> {
    return this.userManagementService.updateUser(user.id, {
      familyName: user.familyName,
      givenName: user.givenName,
      email: user.email,
      roles: user.toServerModel().roles,
    });
  }

  resetUser() {
    this.storeService.resetUser();
  }

  cancelCreation() {
    this.storeService.cancelCreation();
  }
}

export type UserWithJobStatistics = JobExecutionStatistics & User;
